import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import Img from "gatsby-image";
import styles from "./styles";

import { CoreButtonBlock } from "../CoreButtonBlock";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbBannerMessageBlock = (props) => {
  // console.log(props);
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();

  let image = null;

  // Use the gatsby image if available
  if (props.attributes.localFile) {
    if (props.attributes.localFile.childImageSharp) {
      image = (
        <Img
          className="desktop-image"
          fluid={props.attributes.localFile.childImageSharp.fluid}
          alt=""
        />
      );
    } else {
      // Gifs do not have a childImageSharp
      image = (
        <img
          className="desktop-image"
          src={props.attributes.localFile.publicURL}
          alt=""
        />
      );
    }
  } else {
    // This is a preview image
    image = (
      <div className="gatsby-image-wrapper desktop-image">
        <div style={{ paddingBottom: "55%" }} />
        <img
          src={process.env.GATSBY_ADMIN_URL + props.attributes.mediaUrl}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </div>
    );
  }
  let mobileImage = null;

  // Use the gatsby image if available
  if (props.attributes.localFileMobile) {
    if (props.attributes.localFileMobile.childImageSharp) {
      mobileImage = (
        <Img
          className="mobile-image"
          fluid={props.attributes.localFileMobile.childImageSharp.fluid}
          alt=""
        />
      );
    } else {
      // Gifs do not have a childImageSharp
      mobileImage = (
        <img
          className="mobile-image"
          src={props.attributes.localFileMobile.publicURL}
          alt=""
        />
      );
    }
  } else {
    // This is a preview image
    mobileImage = (
      <div className="gatsby-image-wrapper mobile-image">
        <div style={{ paddingBottom: "55%" }} />
        <img
          src={process.env.GATSBY_ADMIN_URL + props.attributes.mediaUrlMobile}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </div>
    );
  }

  return (
    <div
      className={`banner-message ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.bannerMessage} ${classes.bannerMessageTheme}`}
    >
      {props.attributes.bannerCtaLink !== "" && (
        <a href={props.attributes.bannerCtaLink}>
          {image}
          {mobileImage}
          <div className="con-wrap">
            {blocks.map((block) => {
              return block;
            })}
            {props.attributes.bannerCtaText !== "" && (
              <CoreButtonBlock
                attributes={{
                  align: "",
                  backgroundColor: "secondary",
                  borderRadius: 0,
                  className: "view-all",
                  gradient: "",
                  linkTarget: "",
                  placeholder: "",
                  rel: "",
                  text: props.attributes.bannerCtaText,
                  textColor: "foreground_secondary",
                  title: "",
                  url: props.attributes.bannerCtaLink,
                  __typename: "WpCoreButtonBlockAttributes",
                }}
              />
            )}
          </div>
        </a>
      )}
      {props.attributes.bannerCtaLink === "" && (
        <>
          {image}
          {mobileImage}
          <div className="con-wrap">
            {blocks.map((block) => {
              return block;
            })}
          </div>
        </>
      )}
    </div>
  );
};

CgbBannerMessageBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CgbBannerMessageBlockFragment = graphql`
  fragment CgbBannerMessageBlock on WpBlock {
    ... on WpCgbBannerMessageBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpCgbBannerMessageBlockAttributes {
          className
          bannerCtaText
          bannerCtaLink
          mediaUrl
          mediaUrlMobile
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          localFileMobile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbBannerMessageBlockPreview = `
    ... on CgbBannerMessageBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbBannerMessageBlockAttributes {
                className
                bannerCtaText
                bannerCtaLink
                mediaUrl
                mediaUrlMobile
            }
        }
    }
`;
