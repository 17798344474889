import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
// import { makeStyles } from '@material-ui/styles';

// import { buildBlocks } from "../../../services/builder";
import Form from "./Form";
// import styles from './styles';

// const useStyles = makeStyles(theme => styles(theme));

export const CgbSignupFormBlock = (props) => {
  // const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
  // const classes = useStyles();

  return (
    <div>
      <Form />
    </div>
  );
};

CgbSignupFormBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CgbSignupFormBlockFragment = graphql`
  fragment CgbSignupFormBlock on WpBlock {
    ... on WpCgbSignupFormBlock {
      name
      attributes {
        className
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbSignupFormBlockPreview = `
    ... on CgbSignupFormBlock {
        name
        attributes {
          className
        }
    }
`;
