export default theme => ({
    bannerMessage: {
        position: 'relative',
        marginBottom: 36,
        marginTop: 36,
        '& .desktop-image': {
            display: 'none !important',
            [theme.breakpoints.up('md')]: {
                display: 'block !important',
            },
        },
        '& .mobile-image': {
            display: 'block !important',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            '& .mobile-image': {
                display: 'none !important',
            }
        },
        '& > .gatsby-image-wrapper': {
            zIndex: '1',
            display: 'block',
            width: '100% !important',
            '& > div': {
                height: '450px !important',
                overflow: 'hidden',

                [theme.breakpoints.up('lg')]: {
                    height: 'auto !important',
                }
            },
            '& img': {
                objectPosition: 'bottom center !important',
            },
            '&.mobile-image': {
                '& > div': {
                    padding: '0 !important',
                }
            }
        },
        '& .con-wrap': {
            position: 'absolute',
            top: 40,
            left: 50,
            right: 50,
            zIndex: 2,
            [theme.breakpoints.up('md')]: {
                top: 'calc(50% - 50px)',
                width: 'calc(100% - 48px)',
                maxWidth: 1080,
                left: '50%',
                transform: 'translate(-50%, -50%)',
            },
            '& [class*=" MuiTypography-h"]': {
                fontSize: 33,
                lineHeight: 1.1,
                color: theme.palette.primary.main,
                letterSpacing: '-0.016em',
                fontFamily: theme.headerFont,
                fontWeight: 'normal',
                maxWidth: 175,
                [theme.breakpoints.up('md')]: {
                    fontSize: 96,
                    maxWidth: 350,
                    lineHeight: .9,
                },
                '& em': {
                    fontFamily: "Overpass",
                    color: theme.palette.secondary.main,
                    fontSize: 17,
                    letterSpacing: 0,
                    fontStyle: 'normal',
                    fontWeight: '900',
                    textTransform: 'uppercase',
                    lineHeight: 1.2,
                    display: 'block',
                    [theme.breakpoints.up('md')]: {
                        fontSize: 32,
                        letterSpacing: '0.008em',
                        fontWeight: 'normal',
                        fontFamily: theme.bodyFont,
                    },
                }
            },
        },
        '&.left-top .gatsby-image-wrapper img': {
            objectPosition: 'left top !important',
        },
        '&.left-center .gatsby-image-wrapper img': {
            objectPosition: 'left center !important',
        },
        '&.left-bottom .gatsby-image-wrapper img': {
            objectPosition: 'left bottom !important',
        },
        '&.center-top .gatsby-image-wrapper img': {
            objectPosition: 'center top !important',
        },
        '&.center-bottom .gatsby-image-wrapper img': {
            objectPosition: 'center bottom !important',
        },
        '&.right-top .gatsby-image-wrapper img': {
            objectPosition: 'right top !important',
        },
        '&.right-center .gatsby-image-wrapper img': {
            objectPosition: 'right center !important',
        },
        '&.right-bottom .gatsby-image-wrapper img': {
            objectPosition: 'right bottom !important',
        },
    },
    bannerMessageTheme: {
        ...theme.bannerMessageTheme,
    }
});