import transTopWhite from "../../../images/trans-tab-top-white.png";
import transBottomWhite from "../../../images/trans-tab-bottom-white.png";
import transTopWhiteFlipped from "../../../images/trans-tab-top-white-flipped.png";
import transBottomWhiteFlipped from "../../../images/trans-tab-bottom-white-flipped.png";

import transTopBackground from "../../../images/trans-tab-top-background.png";
import transBottomBackground from "../../../images/trans-tab-bottom-background.png";
import transTopBackgroundFlipped from "../../../images/trans-tab-top-background-flipped.png";
import transBottomBackgroundFlipped from "../../../images/trans-tab-bottom-background-flipped.png";

import transTopBlue from "../../../images/trans-tab-top-blue.png";
import transBottomBlue from "../../../images/trans-tab-bottom-blue.png";
import transTopBlueFlipped from "../../../images/trans-tab-top-blue-flipped.png";
import transBottomBlueFlipped from "../../../images/trans-tab-bottom-blue-flipped.png";

export default theme => ({
    tabSep: {
        position: 'relative',
        width: 'calc(100vw + 2px)',
        transform: 'translateX(-50%)',
        margin: '-1px 0 -1px 50%',
        height: 98,
        backgroundPosition: 'center',
        '&.top-transparent': {
            marginTop: -74,  
            '&.bottom-white': { 
                backgroundImage: 'url('+transBottomWhite+')', 
                '&.flip-tab': {
                    backgroundImage: 'url('+transBottomWhiteFlipped+')',
                }
            },
            '&.bottom-background': { 
                backgroundImage: 'url('+transBottomBackground+')', 
                '&.flip-tab': {
                    backgroundImage: 'url('+transBottomBackgroundFlipped+')',
                }
            },
            '&.bottom-primary': { 
                backgroundImage: 'url('+transBottomBlue+')', 
                '&.flip-tab': {
                    backgroundImage: 'url('+transBottomBlueFlipped+')',
                }
            }
        },
        '&.bottom-transparent': {
            marginTop: -74,  
            '&.top-white': {
                backgroundImage: 'url('+transTopWhite+')',
                '&.flip-tab': {
                    backgroundImage: 'url('+transTopWhiteFlipped+')',
                }
            }, 
            '&.top-background': {
                backgroundImage: 'url('+transTopBackground+')',
                '&.flip-tab': {
                    backgroundImage: 'url('+transTopBackgroundFlipped+')',
                }
            }, 
            '&.top-primary': {
                backgroundImage: 'url('+transTopBlue+')',
                '&.flip-tab': {
                    backgroundImage: 'url('+transTopBlueFlipped+')',
                }
            }
        },
        '& .top, & .bottom': {
            height: 74,
            display: 'block',
            width: '100%',
            '&.background': {
                backgroundColor: theme.palette.background.default,
            },
            '&.primary': {
                backgroundColor: theme.palette.primary.main,
            },
            '&.white': {
                backgroundColor: 'white',
            },
        },
        '& .top': {
            '&.transparent': {
                marginTop: -74,   
            }
        },
        '& .bottom': {
            marginTop: -50,
            clipPath: 'polygon(0% 50px, calc(50% - 82px) 50px, calc(50% - 40px) 0, calc(50% + 40px) 0, calc(50% + 82px) 50px, 100% 50px, 100% 100%, 0% 100%)',
        },
        '&.flip-tab': {
            '& .bottom': {
                clipPath: 'polygon(0% 0, calc(50% - 82px) 0, calc(50% - 40px) 50px, calc(50% + 40px) 50px, calc(50% + 82px) 0, 100% 0, 100% 100%, 0% 100%)',
            }
        }
    },
    tabSepTheme: {
        ...theme.tabSepTheme,
    }
})