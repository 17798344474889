import React from "react";
import Link from "../../Link";
import ReactHtmlParser from "react-html-parser";

function Logo(props) {
  if (!props.provider.courseProviderInfo.logo) return null;

  return (
    <img
      src={props.provider.courseProviderInfo.logo.sourceUrl}
      className="brand-logo"
      alt={``}
    />
  );
}

export default function Results({ courses, courseDates, classes }) {
  if (!courses.length) return null;
  return (
    <div className={classes.courseResults}>
      <div className="course-titles">
          <div className="provider">Provider</div>
          <div className="title">Course Title</div>
          <div className="category">Course Category</div>
          <div className="length">Length</div>
          <div className="dates">Date</div>
      </div>
      <div className="course-listing">
        {courses.map(course => {
          let hasOnline = false;
          if (course.courses.places) {
            hasOnline = course.courses.places.filter(place => place === 'Online').length > 0;
          }
          return (
            <div key={course.id} className="course-item">
              <div className="provider">
                <span className="title">Provider </span>
                <Logo provider={course.courses.provider} />
              </div>
              <div className="title">
                <span className="title">Course Title</span>
                <Link to={course.uri}>{ReactHtmlParser(course.title)}</Link>
              </div>
              <div className="category">
                <span className="title">Course Category</span>
                {course.courseCategories !== null && course.courseCategories.nodes.length > 0 && (
                  <span>{course.courseCategories.nodes[0].name ?? ''}</span>
                )}
              </div>
              <div className="length">
                <span className="title">Length</span>
                {course.courses.length !== null && (
                  <span>{course.courses.length}</span>
                )}
              </div>
              <div className="dates">
                <span className="title">Date</span>
                {hasOnline && <span>Accessible 24/7</span>}
                {!hasOnline && (
                  <span>
                    {courseDates.includes(course.databaseId) ? "Multiple dates available" : "Contact for Availability"}
                  </span>
                )}
              </div>
              <div className="link">
                <Link to={course.uri}>Course Detail</Link>
              </div>
            </div>
           );
        })}
      </div>
    </div>
  );
}
