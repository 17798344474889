import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbTabSeparatorBlock = (props) => {
    const classes = useStyles();

    return <div className={`tab-sep top-${props.attributes.topColor} bottom-${props.attributes.bottomColor} ${props.attributes.flipTabClass} ${props.attributes.className ? props.attributes.className : ''} ${classes.tabSep} ${classes.tabSepTheme}`}>
        {(props.attributes.topColor !== 'transparent' && props.attributes.bottomColor !== 'transparent') &&
            <>
                <div className={"top " + props.attributes.topColor} />
                <div className={"bottom " + props.attributes.bottomColor} />
            </>
        }
    </div>
};

export const CgbTabSeparatorBlockFragment = graphql`
  fragment CgbTabSeparatorBlock on WpBlock {
    ... on WpCgbTabSeparatorBlock {
        name
        attributes {
            className
            topColor
            bottomColor
            flipTabClass
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbTabSeparatorBlockPreview = `
    ... on CgbTabSeparatorBlock {
        name
        attributes {
            className
            topColor
            bottomColor
            flipTabClass
        }
    }
`;