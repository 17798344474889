export default theme => ({
    offerGridInner: {
        position: 'relative',
        width: 'calc(100% + 34px)',
        margin: '0px -17px 8px',
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
            backgroundColor: 'transparent',
            margin: '0 0 24px',
            width: '100%',
            '&::before': {
                content: '""',
                display: 'block',
                backgroundColor: theme.palette.secondary.main,
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 'calc(50% + 12px)',
                zIndex: '0',
            },
            '& .gatsby-image-wrapper': {
                maxWidth: 'calc(100% - 12px)',
            }
        },
        '& img, & .gatsby-image-wrapper': {
            '& > div': {
                paddingBottom: '73% !important',
            },
        },
        '& h1, & h2, & h3, & h4, & h5, & h6, & p, & a': {
            color: theme.palette.secondary.contrastText,
        },
        '& a': {
            textDecoration: 'underline',
            '&:hover, &:focus': {
                opacity: '0.9',
            }
        },
        '& .con-wrap': {
            padding: '60px 16px 16px',
            position: 'relative',
            zIndex: '2',
            [theme.breakpoints.up('md')]: {
                padding: '60px 60px 44px 72px',
                position: 'initial',
            },
            '& .day-tag': {
                display: 'block',
                width: '160px',
                fontSize: '12px',
                textTransform: 'uppercase',
                letterSpacing: '.156em',
                lineHeight: '1.33',
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.secondary.contrastText,
                textAlign: 'center',
                padding: 11,
                position: 'absolute',
                top: -20,
                left: 'calc(50% - 80px)',
                [theme.breakpoints.up('md')]: {
                    top: 0,
                    left: 'calc(50% + 12px)',
                },
            },
            '& h1, & h2, & h3, & h4, & h5, & h6': {
                fontWeight: '400',
                marginBottom: 20,
                fontSize: 32,
            }
        },
        '&:nth-of-type(odd)': {
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse',
                '& .gatsby-image-wrapper': {
                    marginLeft: 'auto',
                },
                '&::before': {
                    left: 0,
                    right: 'calc(50% + 12px)',
                },
                '& .con-wrap': {
                    [theme.breakpoints.up('md')]: {
                        padding: '60px 72px 44px 60px',
                    },
                    '& .day-tag': {
                        left: 0,
                    }
                }
            }
        }
    },
    offerGridInnerTheme: {
        ...theme.offerGridInnerTheme
    },
})