import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { postData } from "./actions";
import Grid from "@material-ui/core/Grid/Grid";

import styles from "./Form.styles";

const useStyles = makeStyles((theme) => styles(theme));

function QuoteBuilder() {
  const classes = useStyles();

  const [forename, setForename] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [type, setType] = useState("Garage");
  const [formLocked, lockForm] = useState(false);

  function handleSubmit(e) {
    if (e) e.preventDefault();

    const request = postData({
      forename,
      surname,
      company,
      type,
      email,
    });

    request.then((res) => {
      if (res.status === 200 && res.data.success === true) {
        lockForm(true);
      }
    });
  }

  return (
    <div className="enquiry-form">
      {formLocked && (
        <div className={classes.success}>
          <h2>Thank you for signing up</h2>
          <p>
            Thank you for signing up for our newsletter. Soon you’ll find
            industry-leading news and information coming directly to your inbox.
            Written by experts, for experts.
          </p>
        </div>
      )}

      {!formLocked && (
        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <fieldset>
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} sm={6}>
                <label htmlFor="company">Company Name</label>
                <input
                  type="text"
                  name="company"
                  placeholder="company name"
                  onChange={(e) => setCompany(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <label htmlFor="type">Please confirm if you are a</label>
                <select name="type" onBlur={(e) => setType(e.target.value)}>
                  <option value="Garage">Garage</option>
                  <option value="Factor">Factor</option>
                  <option value="Supplier">Supplier</option>
                  <option value="Other">Other</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label htmlFor="name">First Name*</label>
                <input
                  type="text"
                  name="name"
                  placeholder="John"
                  required
                  onChange={(e) => setForename(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <label htmlFor="surname">Last Name*</label>
                <input
                  type="text"
                  name="surname"
                  placeholder="Smith"
                  required
                  onChange={(e) => setSurname(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  name="email"
                  placeholder="example@gmail.com"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <input type="submit" value="Submit" id="SignupFormSubmit" />
                <p className="required">*Field is required</p>
              </Grid>
            </Grid>
          </fieldset>
        </form>
      )}
    </div>
  );
}

export default QuoteBuilder;
