import dateformat from "dateformat";
import ReactHtmlParser from "react-html-parser";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function formatDateToNames(date) {
  let usFormat = null;
  if (date.indexOf('/') === -1) {
    usFormat = date.substring(4, 6) + "/" + date.substring(6, 8) + "/" + date.substring(0, 4)
  } else {
    usFormat = formatDateToUs(date);
  }

  return dateformat(usFormat, "ddd[] d [/]mmm");
}

export function customWSPDateFormat(date) {
  return ReactHtmlParser(
    formatDateToNames(date)
      .replace("[]", "<span>")
      .replace("[/]", "</span>")
  );
}

export function formatDateToUs(date) {
  const chunks = date.split("/");
  return `${chunks[1]}/${chunks[0]}/${chunks[2]}`;
}

export function getMonthNumberFromName(month) {
  return months.map((m) => m.toLowerCase()).indexOf(month.toLowerCase()) + 1;
}

export function getMonthNameFromNumber(month) {
  return months[month - 1];
}

export function UKDateToTimestamp(date) {
  return new Date(formatDateToUs(date)).valueOf();
}

export function DBDateToTimestamp(date) {
  return new Date(date.substr(0,4), date.substr(4,2) - 1, date.substr(6,2)).valueOf();
}

export const filterDuplicates = (item, i, arr) => {
  if (typeof item === "string") {
    return arr.map((a) => a.toLowerCase()).indexOf(item.toLowerCase()) === i;
  } else {
    return arr.map((a) => a).indexOf(item) === i;
  }
};

export const makeDateKey = (year, month) => {
  let key = "" + year;
  key += (month < 10) ? "0" : "";
  key += month;
  return key;
}
