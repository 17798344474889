export default theme => ({
    latestPosts: {
        marginBottom: 36,
        position: 'relative',
        '& h2': {
            marginBottom: 24,
            [theme.breakpoints.up('md')]: {
                marginBottom: 60,
            }
        },
        '& .latest-post-grid': {
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                backgroundColor: 'white',
            },
            '& .newest': {
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 calc(63.6% - 12px)',
                    maxWidth: 'calc(63.6% - 12px)',
                },
                '& a': {
                    display: 'block',
                    textDecoration: 'none',
                    color: theme.palette.primary.contrastText,
                    position: 'relative',
                    '& .con-wrap': {
                        display: 'block',
                        padding: 30,
                        backgroundColor: theme.palette.primary.main,
                        [theme.breakpoints.up('md')]: {
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            padding: '25px 36px',
                            backgroundColor: theme.palette.primary.main,
                            backdropFilter: 'blur(5px)'
                        },
                        '& .type': {
                            fontSize: 12,
                            lineHeight: 1.333,
                            letterSpacing: '0.152em',
                            marginBottom: 12,
                            display: 'block',
                            textTransform: 'uppercase',
                            color: 'rgba(255,255,255,0.35)',
                            fontFamily: theme.subHeaderFont,
                        },
                        '& .title': {
                            display: 'block',
                            marginBottom: 15,
                            fontSize: 24,
                            lineHeight: 1.42,
                            letterSpacing: '0.008em',
                            fontFamily: theme.subHeaderFont,
                            [theme.breakpoints.up('md')]: {
                                fontSize: 32,
                                lineHeight: 1.5,
                            },
                        },
                        '& .excerpt': {
                            opacity: .57,
                            fontSize: 16,
                            lineHeight: 1.5,
                            letterSpacing: '0.016em',
                        }
                    }
                }
            },
            '& .more': {
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 calc(36.3% + 12px)',
                    maxWidth: 'calc(36.3% + 12px)',
                },
                '& a.more-link': {
                    backgroundColor: 'white',
                    display: 'block',
                    borderBottom: '1px solid #f0f0f0',
                    marginBottom: 30,
                    padding: '30px 20px',
                    textDecoration: 'none',
                    [theme.breakpoints.up('md')]: {
                        padding: '45px 50px',
                    },
                    '& .type': {
                        fontSize: 12,
                        lineHeight: 1.333,
                        letterSpacing: '0.152em',
                        marginBottom: 10,
                        display: 'block',
                        textTransform: 'uppercase',
                        color: 'rgba(0,0,0,0.24)',
                        [theme.breakpoints.up('md')]: {
                            marginBottom: 5,
                        },
                    },
                    '& .title': {
                        display: 'block',
                        marginBottom: 10,
                        fontSize: 20,
                        lineHeight: 2,
                        letterSpacing: '0.008em',
                        color: theme.palette.secondary.main,
                        [theme.breakpoints.up('md')]: {
                            fontSize: 24,
                            marginBottom: 2,
                        }
                    },
                    '& .excerpt': {
                        fontSize: 16,
                        lineHeight: 1.52,
                        letterSpacing: '0.032em',
                        color: 'rgba(0,0,0,0.5)',
                        height: 24,
                        display: 'block',
                        overflow: 'hidden',
                        position: 'relative',
                        paddingRight: 16,
                        '&::after': {
                            content: '"..."',
                            padding: '0 2px',
                            backgroundColor: 'white',
                            position: 'absolute',
                            right: 0,
                        }
                    }
                }
            }
        },
        '& .view-all': {
            backgroundColor: 'white !important',
            [theme.breakpoints.up('md')]: {
                position: 'absolute',
                top: 0,
                right: 0,   
            }
        },
    },
    latestPostsTheme: {
        ...theme.latestPostsTheme,
    }
})