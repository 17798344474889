import themePalette from "../../../../.brew-cache/theme.json";

export default theme => ({
    splitConBanner: {
        position: 'relative',
        marginBottom: 36,
        backgroundColor: theme.palette.primary.main,
        marginTop: 24,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap:'wrap',
            marginBottom: 36,
        },
        '& .image-wrap': {
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 calc(56.25% + 12px)',
                maxWidth: 'calc(56.25% + 12px)',
                '& .gatsby-image-wrapper': {
                    position: 'absolute !important',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }
            },
            '& [class*=" MuiTypography-h"]': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: theme.palette.primary.contrastText,
                padding: 30,
                textShadow: '0 0 15px black',
                [theme.breakpoints.up('md')]: {
                    transform: 'translateY(-50%)',
                    padding: 80,
                    right: 0,
                    left: 0,
                }
            }
        },
        '& .con-wrap': {
            padding: '40px 30px',
            [theme.breakpoints.up('md')]: {
                padding: 80,
                flex: '0 0 calc(43.75% - 12px)',
                maxWidth: 'calc(43.75% - 12px)',
            },
            '& [class*=" MuiTypography-"]': {
                color: theme.palette.primary.contrastText,
            },
            '& [class*=" MuiTypography-h"]': {
                textTransform: 'uppercase',
                marginTop: 0,
                marginBottom: 24,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 32,
                },
                '& strong': {
                    color: theme.palette.secondary.main,
                    fontWeight: 'inherit',
                }
            },
            '& a': {
                display: 'table',
                marginTop: 24,
                [theme.breakpoints.up('md')]: {
                    marginTop: 32,
                },
            }
        }
    },
    splitConBannerTheme: {
        ...theme.splitConBannerTheme,
    },
    root: {
        '& button': {
            fontFamily: theme.headerFont,
            padding: '12px 22px',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
            fontSize: 18,
            lineHeight: 1.33,
            fontWeight: 600,
            textAlign: 'center',
            minWidth: 185,
            textTransform: 'uppercase',
            borderRadius: 25,
            letterSpacing: '0.016em',
            transition: 'color .3s ease-in-out, background-color .3s ease-in-out',
            // Foreground colours
            '&[data-text-color="primary"]': { color: themePalette.ThemeOptions.mainColours.primaryColour},
            '&[data-text-color="secondary"]': { color: themePalette.ThemeOptions.mainColours.secondaryColour},
            '&[data-text-color="tertiary"]': { color: themePalette.ThemeOptions.mainColours.tertiaryColour},
            '&[data-text-color="foreground_primary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundPrimaryColour},
            '&[data-text-color="foreground_secondary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundSecondaryColour},
            '&[data-text-color="foreground_tertiary"]': { color: themePalette.ThemeOptions.foregroundColours.foregroundTertiaryColour},
            '&[data-text-color="background_primary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundPrimaryColour},
            '&[data-text-color="background_secondary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundSecondaryColour},
            '&[data-text-color="background_tertiary"]': { color: themePalette.ThemeOptions.backgroundColours.backgroundTertiaryColour},
            // Background colours
            '&[data-bg-color="primary"]': { 
                backgroundColor: themePalette.ThemeOptions.mainColours.primaryColour,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.primary.contrastText,
                    color: theme.palette.primary.main,
                }
            },
            '&[data-bg-color="secondary"]': { 
                backgroundColor: themePalette.ThemeOptions.mainColours.secondaryColour,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.secondary.contrastText,
                    color: theme.palette.secondary.main,
                }
            },
            '&[data-bg-color="tertiary"]': { 
                backgroundColor: themePalette.ThemeOptions.mainColours.tertiaryColour,
                '&:hover, &:focus': {
                    backgroundColor: theme.palette.tertiary.contrastText,
                    color: theme.palette.tertiary.main,
                }
            },
            '&[data-bg-color="foreground_primary"]': { 
                backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundPrimaryColour,
                '&:hover, &:focus': {
                    color: theme.palette.primary.contrastText,
                    backgroundColor: theme.palette.primary.main,
                }
            },
            '&[data-bg-color="foreground_secondary"]': { 
                backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundSecondaryColour,
                '&:hover, &:focus': {
                    color: theme.palette.secondary.contrastText,
                    backgroundColor: theme.palette.secondary.main,
                }
            },
            '&[data-bg-color="foreground_tertiary"]': { 
                backgroundColor: themePalette.ThemeOptions.foregroundColours.foregroundTertiaryColour,
                '&:hover, &:focus': {
                    color: theme.palette.tertiary.contrastText,
                    backgroundColor: theme.palette.tertiary.main,
                }
            },
            '&[data-bg-color="background_primary"]': { 
                backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundPrimaryColour
            },
            '&[data-bg-color="background_secondary"]': { 
                backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundSecondaryColour
            },
            '&[data-bg-color="background_tertiary"]': { 
                backgroundColor: themePalette.ThemeOptions.backgroundColours.backgroundTertiaryColour
            },
        }
    },
    buttonTheme: {
        ...theme.buttonTheme,
    }
});