import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { sort } from "fast-sort";

// import { buildBlocks } from "../../../services/builder";
import styles from "./styles";

import ProductRelatedProducts from "../../Product/ProductRelatedProducts";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbPopularProductsBlock = (props) => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query PopularProducts {
      allWpProduct(filter: { product: { order: { gt: 0 } } }, limit: 500) {
        nodes {
          id
          title
          uri
          product {
            code
            order
            productGallery {
              image
            }
          }
          productCategories {
            nodes {
              slug
            }
          }
        }
      }
      wp {
        themeOptions {
          ThemeOptions {
            defaultImages {
              productImage {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const filteredProducts = data.allWpProduct.nodes.filter((product) => {
    return product.productCategories.nodes
      .map((edge) => edge.slug)
      .includes(props.pageContext.slug);
  });

  const sortedProducts = sort(filteredProducts)
    .by([
      {
        desc: (product) => product.product.order,
      },
      { asc: (product) => product.title },
    ])
    .slice(0, 18);

  return (
    <div
      className={`popular-products ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.popularProducts}`}
    >
      <ProductRelatedProducts
        products={
          sortedProducts.length !== 0 ? sortedProducts : data.allWpProduct.nodes
        }
        title="Popular Products"
        default={data.wp.themeOptions.ThemeOptions.defaultImages.productImage}
      />
    </div>
  );
};

export const CgbPopularProductsBlockFragment = graphql`
  fragment CgbPopularProductsBlock on WpBlock {
    ... on WpCgbPopularProductsBlock {
      name
      attributes {
        __typename
        ... on WpCgbPopularProductsBlockAttributes {
          className
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbPopularProductsBlockPreview = `
    ... on CgbPopularProductsBlock {
        name
        attributes {
            __typename
            ... on CgbPopularProductsBlockAttributes {
                className
            }
        }
    }
`;
