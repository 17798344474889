import React from "react";
import { replaceMasks } from "../services/masks";

import { CoreBlock } from "../components/blocks/CoreBlock";
import { CoreParagraphBlock } from "../components/blocks/CoreParagraphBlock/";
import { CoreHeadingBlock } from "../components/blocks/CoreHeadingBlock/";
import { CoreColumnBlock } from "../components/blocks/CoreColumnBlock/";
import { CoreColumnsBlock } from "../components/blocks/CoreColumnsBlock/";
import { CoreImageBlock } from "../components/blocks/CoreImageBlock/";
import { CoreButtonsBlock } from "../components/blocks/CoreButtonsBlock/";
import { CoreButtonBlock } from "../components/blocks/CoreButtonBlock/";
import { CoreGalleryBlock } from "../components/blocks/CoreGalleryBlock/";
import { CoreQuoteBlock } from "../components/blocks/CoreQuoteBlock/";
import { CoreListBlock } from "../components/blocks/CoreListBlock/";
import { CoreTableBlock } from "../components/blocks/CoreTableBlock/";
import { CoreFileBlock } from "../components/blocks/CoreFileBlock/";
import { CoreSpacerBlock } from "../components/blocks/CoreSpacerBlock/";
import { GravityformsFormBlock } from "../components/blocks/GravityformsFormBlock";
import { CgbAccordionBlock } from "../components/blocks/CgbAccordionBlock";
import { CgbAccordionInnerBlock } from "../components/blocks/CgbAccordionInnerBlock";
import { CgbBackgroundWrapBlock } from "../components/blocks/CgbBackgroundWrapBlock";
import { CgbBannerCalloutCtaBlock } from "../components/blocks/CgbBannerCalloutCtaBlock";
import { CgbBannerMessageBlock } from "../components/blocks/CgbBannerMessageBlock";
import { CgbBannerMessageSliderBlock } from "../components/blocks/CgbBannerMessageSliderBlock";
import { CgbBlogBannerBlock } from "../components/blocks/CgbBlogBannerBlock";
import { CgbBlogPagePostsBlock } from "../components/blocks/CgbBlogPagePostsBlock";
import { CgbBlogPostWrapBlock } from "../components/blocks/CgbBlogPostWrapBlock";
import { CgbBreakoutGalleryIntroBlock } from "../components/blocks/CgbBreakoutGalleryIntroBlock";
import { CgbCalloutCtaBlock } from "../components/blocks/CgbCalloutCtaBlock";
import { CgbCourseCalendarBlock } from "../components/blocks/CgbCourseCalendarBlock";
import { CgbCourseSearchBlock } from "../components/blocks/CgbCourseSearchBlock";
import { CgbCourseListBlock } from "../components/blocks/CgbCourseListBlock";
import { CgbCardBlock } from "../components/blocks/CgbCardBlock";
import { CgbCardRowBlock } from "../components/blocks/CgbCardRowBlock";
import { CgbCategoryListingBlock } from "../components/blocks/CgbCategoryListingBlock";
import { CgbContactCalloutBlock } from "../components/blocks/CgbContactCalloutBlock";
import { CgbContactRowBlock } from "../components/blocks/CgbContactRowBlock";
import { CgbFeaturedIconsBlock } from "../components/blocks/CgbFeaturedIconsBlock";
import { CgbFeaturedIconsInnerBlock } from "../components/blocks/CgbFeaturedIconsInnerBlock";
import { CgbFileListBlock } from "../components/blocks/CgbFileListBlock";
import { CgbFileListInnerBlock } from "../components/blocks/CgbFileListInnerBlock";
import { CgbFileListInnerSmallBlock } from "../components/blocks/CgbFileListInnerSmallBlock";
import { CgbFinanceCalculatorBlock } from "../components/blocks/CgbFinanceCalculatorBlock";
import { CgbGoodToGoBlock } from "../components/blocks/CgbGoodToGoBlock";
import { CgbIconListIntroBlock } from "../components/blocks/CgbIconListIntroBlock";
import { CgbIconListInnerBlock } from "../components/blocks/CgbIconListInnerBlock";
import { CgbIconListBlock } from "../components/blocks/CgbIconListBlock";
import { CgbIconRowBlock } from "../components/blocks/CgbIconRowBlock";
import { CgbIconRowInnerBlock } from "../components/blocks/CgbIconRowInnerBlock";
import { CgbImageBreakdownBlock } from "../components/blocks/CgbImageBreakdownBlock";
import { CgbImageBreakdownInnerBlock } from "../components/blocks/CgbImageBreakdownInnerBlock";
import { CgbImageContentRowsBlock } from "../components/blocks/CgbImageContentRowsBlock";
import { CgbImageContentRowsInnerBlock } from "../components/blocks/CgbImageContentRowsInnerBlock";
import { CgbImageGalleryGridBlock } from "../components/blocks/CgbImageGalleryGridBlock";
import { CgbImageGalleryGridInnerLargeBlock } from "../components/blocks/CgbImageGalleryGridInnerLargeBlock";
import { CgbImageGalleryGridInnerSmallBlock } from "../components/blocks/CgbImageGalleryGridInnerSmallBlock";
import { CgbImageLinkBlock } from "../components/blocks/CgbImageLinkBlock";
import { CgbImageLinkRowBlock } from "../components/blocks/CgbImageLinkRowBlock";
import { CgbImageLinkSliderBlock } from "../components/blocks/CgbImageLinkSliderBlock";
import { CgbImageSliderBlock } from "../components/blocks/CgbImageSliderBlock";
import { CgbIntroGalleryBlock } from "../components/blocks/CgbIntroGalleryBlock";
import { CgbIntroGallerySplitBlock } from "../components/blocks/CgbIntroGallerySplitBlock";
import { CgbIntroImageSliderBlock } from "../components/blocks/CgbIntroImageSliderBlock";
import { CgbLatestPostsBlock } from "../components/blocks/CgbLatestPostsBlock";
import { CgbNextPreviousPostsBlock } from "../components/blocks/CgbNextPreviousPostsBlock";
import { CgbOfferGridBlock } from "../components/blocks/CgbOfferGridBlock";
import { CgbOfferGridInnerBlock } from "../components/blocks/CgbOfferGridInnerBlock";
import { CgbPopularProductsBlock } from "../components/blocks/CgbPopularProductsBlock";
import { CgbProductCategoryListBlock } from "../components/blocks/CgbProductCategoryListBlock";
import { CgbPullquoteBlock } from "../components/blocks/CgbPullquoteBlock";
import { CgbSeparatorBlock } from "../components/blocks/CgbSeparatorBlock";
import { CgbSignupFormBlock } from "../components/blocks/CgbSignupFormBlock";
import { CgbSocialSharingBlock } from "../components/blocks/CgbSocialSharingBlock";
import { CgbSplitContentBannerBlock } from "../components/blocks/CgbSplitContentBannerBlock";
import { CgbTabContentBlock } from "../components/blocks/CgbTabContentBlock";
import { CgbTabContentInnerBlock } from "../components/blocks/CgbTabContentInnerBlock";
import { CgbTabSeparatorBlock } from "../components/blocks/CgbTabSeparatorBlock";
import { CgbTestimonialBlock } from "../components/blocks/CgbTestimonialBlock";
import { CgbThickIntroBlock } from "../components/blocks/CgbThickIntroBlock";
import { CgbThinIntroBlock } from "../components/blocks/CgbThinIntroBlock";
import { CgbTopBrandsBlock } from "../components/blocks/CgbTopBrandsBlock";
import { CgbTwoCardIntroBlock } from "../components/blocks/CgbTwoCardIntroBlock";
import { CgbTwoColInnerBlock } from "../components/blocks/CgbTwoColInnerBlock";
import { CgbTwoColWrapperBlock } from "../components/blocks/CgbTwoColWrapperBlock";
import { CgbVideoBannerMessageBlock } from "../components/blocks/CgbVideoBannerMessageBlock";
import { CgbVideoTestimonialBlock } from "../components/blocks/CgbVideoTestimonialBlock";

/**
 * Returns the component for the block name
 * @param {String} name
 */
function mapBlocks(name) {
  let block = null;
  switch (name) {
    case "CoreBlock":
      block = CoreBlock;
      break;
    case "CoreParagraphBlock":
      block = CoreParagraphBlock;
      break;
    case "CoreHeadingBlock":
      block = CoreHeadingBlock;
      break;
    case "CoreColumnBlock":
      block = CoreColumnBlock;
      break;
    case "CoreColumnsBlock":
      block = CoreColumnsBlock;
      break;
    case "CoreImageBlock":
      block = CoreImageBlock;
      break;
    case "CoreButtonsBlock":
      block = CoreButtonsBlock;
      break;
    case "CoreButtonBlock":
      block = CoreButtonBlock;
      break;
    case "CoreGalleryBlock":
      block = CoreGalleryBlock;
      break;
    case "CoreQuoteBlock":
      block = CoreQuoteBlock;
      break;
    case "CoreListBlock":
      block = CoreListBlock;
      break;
    case "CoreTableBlock":
      block = CoreTableBlock;
      break;
    case "CoreFileBlock":
      block = CoreFileBlock;
      break;
    case "CoreSpacerBlock":
      block = CoreSpacerBlock;
      break;
    case "GravityformsFormBlock":
      block = GravityformsFormBlock;
      break;
    case "CgbAccordionBlock":
      block = CgbAccordionBlock;
      break;
    case "CgbAccordionInnerBlock":
      block = CgbAccordionInnerBlock;
      break;
    case "CgbBackgroundWrapBlock":
      block = CgbBackgroundWrapBlock;
      break;
    case "CgbBannerCalloutCtaBlock":
      block = CgbBannerCalloutCtaBlock;
      break;
    case "CgbBannerMessageBlock":
      block = CgbBannerMessageBlock;
      break;
    case "CgbBannerMessageSliderBlock":
      block = CgbBannerMessageSliderBlock;
      break;
    case "CgbBlogBannerBlock":
      block = CgbBlogBannerBlock;
      break;
    case "CgbBlogPagePostsBlock":
      block = CgbBlogPagePostsBlock;
      break;
    case "CgbBlogPostWrapBlock":
      block = CgbBlogPostWrapBlock;
      break;
    case "CgbBreakoutGalleryIntroBlock":
      block = CgbBreakoutGalleryIntroBlock;
      break;
    case "CgbCalloutCtaBlock":
      block = CgbCalloutCtaBlock;
      break;
    case "CgbCourseCalendarBlock":
      block = CgbCourseCalendarBlock;
      break;
    case "CgbCourseSearchBlock":
      block = CgbCourseSearchBlock;
      break;
    case "CgbCourseListBlock":
      block = CgbCourseListBlock;
      break;
    case "CgbCategoryListingBlock":
      block = CgbCategoryListingBlock;
      break;
    case "CgbContactRowBlock":
      block = CgbContactRowBlock;
      break;
    case "CgbCardBlock":
      block = CgbCardBlock;
      break;
    case "CgbCardRowBlock":
      block = CgbCardRowBlock;
      break;
    case "CgbContactCalloutBlock":
      block = CgbContactCalloutBlock;
      break;
    case "CgbFeaturedIconsBlock":
      block = CgbFeaturedIconsBlock;
      break;
    case "CgbFeaturedIconsInnerBlock":
      block = CgbFeaturedIconsInnerBlock;
      break;
    case "CgbFileListBlock":
      block = CgbFileListBlock;
      break;
    case "CgbFileListInnerBlock":
      block = CgbFileListInnerBlock;
      break;
    case "CgbFileListInnerSmallBlock":
      block = CgbFileListInnerSmallBlock;
      break;
    case "CgbFinanceCalculatorBlock":
      block = CgbFinanceCalculatorBlock;
      break;
    case "CgbGoodToGoBlock":
      block = CgbGoodToGoBlock;
      break;
    case "CgbIconListIntroBlock":
      block = CgbIconListIntroBlock;
      break;
    case "CgbIconListInnerBlock":
      block = CgbIconListInnerBlock;
      break;
    case "CgbIconListBlock":
      block = CgbIconListBlock;
      break;
    case "CgbIconRowBlock":
      block = CgbIconRowBlock;
      break;
    case "CgbIconRowInnerBlock":
      block = CgbIconRowInnerBlock;
      break;
    case "CgbImageBreakdownBlock":
      block = CgbImageBreakdownBlock;
      break;
    case "CgbImageBreakdownInnerBlock":
      block = CgbImageBreakdownInnerBlock;
      break;
    case "CgbImageContentRowsBlock":
      block = CgbImageContentRowsBlock;
      break;
    case "CgbImageContentRowsInnerBlock":
      block = CgbImageContentRowsInnerBlock;
      break;
    case "CgbImageGalleryGridBlock":
      block = CgbImageGalleryGridBlock;
      break;
    case "CgbImageGalleryGridInnerLargeBlock":
      block = CgbImageGalleryGridInnerLargeBlock;
      break;
    case "CgbImageGalleryGridInnerSmallBlock":
      block = CgbImageGalleryGridInnerSmallBlock;
      break;
    case "CgbImageLinkBlock":
      block = CgbImageLinkBlock;
      break;
    case "CgbImageLinkRowBlock":
      block = CgbImageLinkRowBlock;
      break;
    case "CgbImageLinkSliderBlock":
      block = CgbImageLinkSliderBlock;
      break;
    case "CgbImageSliderBlock":
      block = CgbImageSliderBlock;
      break;
    case "CgbIntroGalleryBlock":
      block = CgbIntroGalleryBlock;
      break;
    case "CgbIntroGallerySplitBlock":
      block = CgbIntroGallerySplitBlock;
      break;
    case "CgbIntroImageSliderBlock":
      block = CgbIntroImageSliderBlock;
      break;
    case "CgbLatestPostsBlock":
      block = CgbLatestPostsBlock;
      break;
    case "CgbNextPreviousPostsBlock":
      block = CgbNextPreviousPostsBlock;
      break;
    case "CgbOfferGridBlock":
      block = CgbOfferGridBlock;
      break;
    case "CgbOfferGridInnerBlock":
      block = CgbOfferGridInnerBlock;
      break;
    case "CgbPopularProductsBlock":
      block = CgbPopularProductsBlock;
      break;
    case "CgbProductCategoryListBlock":
      block = CgbProductCategoryListBlock;
      break;
    case "CgbPullquoteBlock":
      block = CgbPullquoteBlock;
      break;
    case "CgbSeparatorBlock":
      block = CgbSeparatorBlock;
      break;
    case "CgbSignupFormBlock":
      block = CgbSignupFormBlock;
      break;
    case "CgbSocialSharingBlock":
      block = CgbSocialSharingBlock;
      break;
    case "CgbSplitContentBannerBlock":
      block = CgbSplitContentBannerBlock;
      break;
    case "CgbTabContentBlock":
      block = CgbTabContentBlock;
      break;
    case "CgbTabContentInnerBlock":
      block = CgbTabContentInnerBlock;
      break;
    case "CgbTabSeparatorBlock":
      block = CgbTabSeparatorBlock;
      break;
    case "CgbTestimonialBlock":
      block = CgbTestimonialBlock;
      break;
    case "CgbThickIntroBlock":
      block = CgbThickIntroBlock;
      break;
    case "CgbThinIntroBlock":
      block = CgbThinIntroBlock;
      break;
    case "CgbTopBrandsBlock":
      block = CgbTopBrandsBlock;
      break;
    case "CgbTwoCardIntroBlock":
      block = CgbTwoCardIntroBlock;
      break;
    case "CgbTwoColInnerBlock":
      block = CgbTwoColInnerBlock;
      break;
    case "CgbTwoColWrapperBlock":
      block = CgbTwoColWrapperBlock;
      break;
    case "CgbVideoBannerMessageBlock":
      block = CgbVideoBannerMessageBlock;
      break;
    case "CgbVideoTestimonialBlock":
      block = CgbVideoTestimonialBlock;
      break;
    default:
      block = null;
      break;
  }
  return block;
}

function UCFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Converts the block name in to a component name
 * eg. core/banner = CoreBannerBlock
 * @param {String} name
 */
function convertName(name) {
  const split = name.split("/");
  const parts = [];
  parts.push(UCFirst(split[0]));
  const split2 = split[1].split("-");
  split2.map((part) => {
    return parts.push(UCFirst(part));
  });
  return parts.join("") + (name === "core/block" ? "" : "Block");
}

export const buildBlocks = (
  blocks,
  parentAttributes = {},
  pageContext = {}
) => {
  if (!blocks) return [];
  // Render the blocks
  let processed = [];
  blocks.map((block, index) => {
    block.attributes = replaceMasks(block.attributes);
    let elem = null;

    // Convert the block name to a component name
    const component = convertName(block.name);
    let OutputBlock = null;

    OutputBlock = mapBlocks(component);

    elem = OutputBlock ? (
      <OutputBlock
        key={index}
        attributes={block.attributes}
        parentAttributes={parentAttributes}
        originalContent={block.originalContent}
        innerBlocks={
          component === "CoreBlock"
            ? block.reusableBlock.blocks
            : block.innerBlocks
        }
        pageContext={pageContext}
      />
    ) : null;

    return processed.push(elem);
  });
  return processed;
};
