export default (theme) => ({
  iconRowInner: {
    display: "block",
    backgroundColor: theme.palette.primary.main,
    padding: "10px 32px",
    textAlign: "left",
    borderBottom: "1px solid #707070",
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      borderBottom: "none",
      borderLeft: "1px solid #707070",
      flex: "0 0 33.3333%",
      maxWidth: "33.333%",
    },
    "& .gatsby-image-wrapper": {
      fontSize: 0,
      display: "inline-block",
      marginRight: 40,
      verticalAlign: "middle",
      maxWidth: "36px !important",
      [theme.breakpoints.up("md")]: {
        maxWidth: "72px !important",
        marginRight: 24,
      },
    },
    "& span": {
      display: "inline-block",
      verticalAlign: "middle",
      fontWeight: 500,
      color: theme.palette.secondary.contrastText,
      fontSize: 20,
      lineHeight: 1.5,
      fontFamily: theme.subHeaderFont,
      paddingRight: 5,
      [theme.breakpoints.up("md")]: {
        fontSize: 26,
        letterSpacing: "0",
      },
    },
  },
  iconRowInnerTheme: {
    ...theme.iconListInnerTheme,
  },
});
