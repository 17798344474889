

export default theme => ({
    contactCallout: {
        position: 'relative',
        marginBottom: 36,
        padding: '40px 0',
        '&::before': {
            top: 0,
            bottom: 0,
            left: '50%',
            width: '100vw',
            position: 'absolute',
            content: '""',
            backgroundColor: theme.palette.secondary.main,
            display: 'block',
            transform: 'translateX(-50%)',
        },
        '& .MuiGrid-container': {
            position: 'relative',
            padding: 30,
            [theme.breakpoints.up('md')]: {
                padding: '80px 30px',
                alignItems: 'center',
            },
            '&::before': {
                position: 'absolute',
                backgroundColor: theme.palette.background.default,
                content: '""',
                display: 'block',
                boxShadow: '0 3px 32px rgba(0,0,0,0.16)',
                top: 0,
                left: 14,
                right: 14,
                bottom: 0,
                [theme.breakpoints.up('lg')]: {
                    left: '50%',
                    right: 'auto',
                    width: 'calc(100vw - 80px)',
                    transform: 'translateX(-50%)',
                }
            }
        },
        '& .text': {
            '& > a': {
                display: 'none',
            },
        },
        '& .buttons': {
            display: 'flex',
            flexDirection: 'column-reverse',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row-reverse',
            },
            '& h1, & h2, & h3, & h4, & h5, & h6, & p': {
                display: 'none',
            },
            '& > a': {
                display: 'block',
                [theme.breakpoints.up('md')]: {
                    flex: '0 0 33.333%',
                    maxWidth: '33.333%',
                },
            },
            '& > a button': {
                width: '100%',
                marginTop: '24px !important',
                [theme.breakpoints.up('md')]: {
                    flexDirection: 'column-reverse',
                    minWidth: 0,
                    maxWidth: 'calc(100% - 50px)',
                    textAlign: 'center',
                    padding: '20px 30px',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }
        },
    },
    contactCalloutTheme: {
        ...theme.contactCalloutTheme
    },
})