import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Grid from '@material-ui/core/Grid';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbImageBreakdownBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`image-breakdown ${props.attributes.className ? props.attributes.className : ''} ${classes.imageBreakdown} ${classes.imageBreakdownTheme}`}>
        <Grid container spacing={3} direction="row" justify="center" alignItems="center" className="title-wrap">
            <Grid item xs={12} >
                {blocks[0]}
            </Grid>
        </Grid>
        <Grid container spacing={3} direction="row" justify="center" alignItems="center" style={{justifyContent: 'center'}} >
            <Grid item xs={12} md={4} lg={3} className="left-con">
                {blocks[1]}
            </Grid>
            <Grid item xs={12} md={4} lg={5} className="image-wrap">
                {blocks[2]}
            </Grid>
            <Grid item xs={12} md={4} lg={3} className="right-con">
                {blocks[3]}
            </Grid>
        </Grid>
    </div>
};

export const CgbImageBreakdownBlockFragment = graphql`
  fragment CgbImageBreakdownBlock on WpBlock {
    ... on WpCgbImageBreakdownBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageBreakdownBlockPreview = `
    ... on CgbImageBreakdownBlock {
        name
        attributes {
            className
        }
    }
`;