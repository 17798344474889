import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from "@material-ui/styles";
// import { buildBlocks } from "../../../services/builder";
import Img from "gatsby-image";
import styles from "./styles";
import Link from "../../Link";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";

import RoomIcon from "@material-ui/icons/Room";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";
import EmailIcon from "@material-ui/icons/Email";

import Form from "./Form";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbContactRowBlock = (props) => {
  // console.log(props);
  // const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query ContactRow {
      wp {
        locationData {
          LocationData {
            address1
            address2
            town
            region
            postcode
            telephone
            email
            twitterHandle
            facebookHandle
            instagramHandle
            linkedinUrl
          }
        }
      }
    }
  `);

  let image = null;

  // Use the gatsby image if available
  if (props.attributes.localFile) {
    if (props.attributes.localFile.childImageSharp) {
      image = (
        <Img
          className="desktop-image"
          fluid={props.attributes.localFile.childImageSharp.fluid}
          alt=""
        />
      );
    } else {
      // Gifs do not have a childImageSharp
      image = (
        <img
          className="desktop-image"
          src={props.attributes.localFile.publicURL}
          alt=""
        />
      );
    }
  } else {
    // This is a preview image
    image = (
      <div className="gatsby-image-wrapper desktop-image">
        <div style={{ paddingBottom: "55%" }} />
        <img
          src={process.env.GATSBY_ADMIN_URL + props.attributes.mediaUrl}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </div>
    );
  }

  return (
    <div
      className={`contact-row ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.contactRow}`}
    >
      <div className="map">{image}</div>
      <div className="con-wrap">
        <h2>Our Details</h2>
        <h3 className="address">
          <RoomIcon />
          Find Us
        </h3>
        <p>
          {data.wp.locationData.LocationData.address1 !== null && (
            <span>
              {data.wp.locationData.LocationData.address1} <br />
            </span>
          )}
          {data.wp.locationData.LocationData.address2 !== null && (
            <span>
              {data.wp.locationData.LocationData.address2} <br />
            </span>
          )}
          {data.wp.locationData.LocationData.town !== null && (
            <span>
              {data.wp.locationData.LocationData.town} <br />
            </span>
          )}
          {data.wp.locationData.LocationData.region !== null && (
            <span>
              {data.wp.locationData.LocationData.region} <br />
            </span>
          )}
          {data.wp.locationData.LocationData.postcode !== null && (
            <span>
              {data.wp.locationData.LocationData.postcode} <br />
            </span>
          )}
        </p>
        {data.wp.locationData.LocationData.telephone !== null && (
          <>
            <h3 className="tel">
              <PhoneEnabledIcon />
              Tel
            </h3>
            <p>
              <Link to={"tel:" + data.wp.locationData.LocationData.telephone}>
                {data.wp.locationData.LocationData.telephone}
              </Link>
            </p>
          </>
        )}
        {data.wp.locationData.LocationData.telephone !== null && (
          <>
            <h3 className="email">
              <EmailIcon />
              Email
            </h3>
            <p>
              <Link to={"mailto:" + data.wp.locationData.LocationData.email}>
                {data.wp.locationData.LocationData.email}
              </Link>
            </p>
          </>
        )}
        <div className="socials">
          {data.wp.locationData.LocationData.facebookHandle !== null && (
            <Link
              to={
                "https://www.facebook.com/" +
                data.wp.locationData.LocationData.facebookHandle
              }
            >
              <FacebookIcon />
              Join us on Facebook
            </Link>
          )}
          {data.wp.locationData.LocationData.twitterHandle !== null && (
            <Link
              to={
                "https://www.twitter.com/" +
                data.wp.locationData.LocationData.twitterHandle
              }
            >
              <TwitterIcon />
              Follow us on Twitter
            </Link>
          )}
          {data.wp.locationData.LocationData.linkedinUrl !== null && (
            <Link to={data.wp.locationData.LocationData.linkedinUrl}>
              <LinkedInIcon />
              Connect on LinkedIn
            </Link>
          )}
          {data.wp.locationData.LocationData.instagramHandle !== null && (
            <Link
              to={
                "https://www.instgram.com/" +
                data.wp.locationData.LocationData.instagramHandle
              }
            >
              <InstagramIcon />
              Follow us on Instagram
            </Link>
          )}
        </div>
      </div>
      <div className="form-wrap">
        <h2>Contact us today</h2>
        <Form />
      </div>
    </div>
  );
};

CgbContactRowBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CgbContactRowBlockFragment = graphql`
  fragment CgbContactRowBlock on WpBlock {
    ... on WpCgbContactRowBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpCgbContactRowBlockAttributes {
          className
          mediaUrl
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbContactRowBlockPreview = `
    ... on CgbContactRowBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbContactRowBlockAttributes {
                className
                mediaUrl
            }
        }
    }
`;
