import React from "react";
import PropTypes from "prop-types";
import Calendar from "../../Calendar";

export const CgbCourseCalendarBlock = ({ attributes, pageContext }) => (
  <section className={`${attributes.className}`}>
    <Calendar courseFilter={attributes.course} pageContext={pageContext} />
  </section>
);

CgbCourseCalendarBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CgbCourseCalendarBlockFragment = graphql`
  fragment CgbCourseCalendarBlock on WpBlock {
    ... on WpCgbCourseCalendarBlock {
      name
      attributes {
        className
        course
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbCourseCalendarBlockPreview = `
    ... on CgbCourseCalendarBlock {
        name
        attributes {
          className
          course
        }
    }
`;
