import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { postData } from "./actions";

// import { CoreHeadingBlock } from '../CoreHeadingBlock';

import styles from "./Form.styles";

const useStyles = makeStyles((theme) => styles(theme));

function QuoteBuilder() {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [comments, setComments] = useState("");
  const [formLocked, lockForm] = useState(false);

  function handleSubmit(e) {
    if (e) e.preventDefault();

    const request = postData({
      forename: name,
      surname,
      email,
      accountNumber,
      query: comments,
    });

    request.then((res) => {
      if (res.status === 200 && res.data.success === true) {
        lockForm(true);
      }
    });
  }

  return (
    <div className="enquiry-form">
      {formLocked && (
        <p className={classes.success}>
          Thank you. A member of the team will get back to your shortly.
        </p>
      )}

      {!formLocked && (
        <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
          <p>
            Fill in the contact form below and one of our specialist team will
            be in touch.{" "}
          </p>

          <fieldset>
            <label htmlFor="name">First Name*</label>
            <input
              type="text"
              name="name"
              placeholder="John"
              required
              onChange={(e) => setName(e.target.value)}
            />

            <label htmlFor="surname">Last Name*</label>
            <input
              type="text"
              name="surname"
              placeholder="Smith"
              required
              onChange={(e) => setSurname(e.target.value)}
            />

            <label htmlFor="email">Email*</label>
            <input
              type="email"
              name="email"
              placeholder="example@gmail.com"
              required
              onChange={(e) => setEmail(e.target.value)}
            />

            <label htmlFor="account">Account Number*</label>
            <input
              type="text"
              name="account"
              placeholder="000000"
              required
              onChange={(e) => setAccountNumber(e.target.value)}
            />

            <label htmlFor="comments">Query</label>
            <textarea
              name="comments"
              placeholder="Comments here..."
              onChange={(e) => setComments(e.target.value)}
            />

            <input type="submit" value="Submit" id="ContactFormSubmit" />

            <p className="required">*Field is required</p>
          </fieldset>
        </form>
      )}
    </div>
  );
}

export default QuoteBuilder;
