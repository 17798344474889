import selectArrow from "../../images/chevron.png";

export default (theme) => ({
  financeCalc: {
    "& form": {
      backgroundColor: theme.palette.primary.main,
      boxShadow:
        "320px 0 " +
        theme.palette.primary.main +
        ", -320px 0 " +
        theme.palette.primary.main,
      padding: "50px 0 124px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      position: "relative",
      justifyContent: "space-between",
      [theme.breakpoints.up("mg")]: {
        paddingTop: 80,
      },
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-start",
        paddingBottom: 140,
      },
      "&:before": {
        content: "''",
        position: "absolute",
        display: "block",
        bottom: -1,
        left: "50%",
        width: "100vw",
        transform: "translateX(-50%)",
        height: 74,
        backgroundColor: "white",
        clipPath:
          "polygon(0% 0, calc(50% - 82px) 0, calc(50% - 40px) 50px, calc(50% + 40px) 50px, calc(50% + 82px) 0, 100% 0, 100% 100%, 0% 100%)",
      },
      "& h2": {
        flex: "0 0 100%",
        color: theme.palette.primary.contrastText,
        marginTop: 0,
        marginBottom: 24,
        fontSize: 32,
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "-0.008em",
        [theme.breakpoints.up("md")]: {
          fontSize: 56,
          lineHeight: 1,
          marginBottom: 42,
        },
      },
      "& > div": {
        flex: "0 0 100%",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 calc(50% - 12px)",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 fit-content",
          marginRight: 24,
        },
        "& label": {
          fontSize: 12,
          letterSpacing: "0.152em",
          fontFamily: "'Roboto', sans-serif",
          color: theme.palette.primary.contrastText,
          lineHeight: "16px",
          display: "block",
          textTransform: "uppercase",
          marginBottom: 6,
        },
        "& input, & select": {
          fontFamily: "'Overpass', sans-serif",
          border: "none",
          width: "100%",
          marginTop: 0,
          display: "block",
          fontSize: 16,
          letterSpacing: "0.016em",
          lineHeight: 1,
          padding: "15px 22px",
          borderRadius: "25px",
          boxShadow: "0 8px 16px rgba(0,0,0,0.16)",
          marginBottom: 24,
          [theme.breakpoints.up("lg")]: {
            marginBottom: 0,
          },
        },
        "& select": {
          "-webkit-appearance": "none",
          "-moz-appearance": "none",
          backgroundColor: "white",
          backgroundImage: "url(" + selectArrow + ")",
          backgroundPosition: "center right 20px",
          backgroundRepeat: "no-repeat",
          paddingRight: 50,
        },
      },
      '& input[type="submit"]': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        boxShadow: "0 8px 16px rgba(0,0,0,0.16)",
        border: "none",
        fontFamily: "'Bebas Neue', sans-serig",
        fontSize: 18,
        letterSpacing: "0.016em",
        lineHeight: 1,
        textAlign: "center",
        width: "100%",
        borderRadius: 25,
        padding: "14px 25px",
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 160px",
          marginTop: 22,
        },
      },
    },
    "& .results": {
      backgroundColor: "white",
      boxShadow: "320px 0 white, -320px 0 white",
      paddingBottom: 100,
      [theme.breakpoints.up("md")]: {
        paddingTop: 60,
      },
      "& .recap": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        paddingBottom: 12,
        borderBottom: "1px solid rgba(0,0,0,0.16)",
        marginBottom: 32,
        "& h2": {
          flex: "0 0 100%",
          fontSize: 24,
          fontFamily: "Roboto Condensed",
          letterSpacing: "0.008em",
          lineHeight: 1.2,
          color: theme.palette.primary.main,
          marginTop: 0,
          marginBottom: 32,
          textTransform: "none",
          fontWeight: "normal",
          [theme.breakpoints.up("md")]: {
            fontSize: 32,
            lineHeight: "44px",
            marginBottom: 55,
          },
        },
        "& span": {
          flex: "0 0 100%",
          display: "block",
          marginBottom: 12,
          [theme.breakpoints.up("md")]: {
            flex: "0 0 fit-content",
            paddingRight: 32,
          },
          [theme.breakpoints.up("lg")]: {
            "&.equi-cost": {
              flex: "0 0 18.75%",
              padding: "0 12px",
              marginLeft: -12,
            },
            "&.years": {
              flex: "0 0 18.75%",
              padding: "0 12px",
            },
            "&.term": {
              flex: "0 0 25%",
              padding: "0 12px",
            },
            "&.tax": {
              flex: "0 0 12.5%",
              padding: "0 12px",
            },
          },
          "& small": {
            fontFamily: "Overpass",
            fontSize: 16,
            lineHeight: 1,
            display: "block",
            letterSpacing: "0.016em",
            fontWeight: 300,
            marginBottom: 4,
          },
          "& strong": {
            fontFamily: "Overpass",
            fontSize: 24,
            letterSpacing: "0.016em",
            display: "block",
            fontWeight: "bold",
            lineHeight: 1,
            "& i": {
              fontWeight: "normal",
              fontStyle: "italic",
            },
          },
        },
      },
      "& .payments": {
        [theme.breakpoints.up("md")]: {
          width: "calc(100% + 24px)",
          margin: "0 -12px",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
        },
        "& > div": {
          padding: "24px 16px",
          marginBottom: 24,
          [theme.breakpoints.up("md")]: {
            flex: "0 0 calc(25% - 24px)",
            margin: "0 12px",
            padding: "72px 40px",
          },
          "& .h-space": {
            [theme.breakpoints.up("md")]: {
              display: "block",
              height: 32,
            },
          },
          "& h3": {
            fontSize: 20,
            letterSpacing: 0,
            marginTop: 0,
            marginBottom: 0,
            color: theme.palette.primary.main,
            fontWeight: "normal",
            lineHeight: "24px",
            fontFamily: "Roboto Condensed",
            [theme.breakpoints.up("md")]: {
              fontSize: 24,
              lineHeight: "32px",
            },
          },
          "& h4": {
            fontFamily: "Roboto Condensed",
            fontWeight: "normal",
            letterSpacing: "0.152em",
            lineHeight: "16px",
            fontSize: 12,
            color: theme.palette.secondary.main,
            marginBottom: 16,
            marginTop: 20,
            textTransform: "uppercase",
          },
          "& p": {
            marginBottom: 0,
            marginTop: 0,
            fontSize: 16,
            letterSpacing: "0.016em",
            lineHeight: "22px",
            fontWeight: "normal",
            [theme.breakpoints.up("md")]: {
              fontSize: 19,
              lineHeight: "26px",
            },
            "&.cost": {
              fontSize: 34,
              lineHeight: "46px",
              fontWeight: "bold",
            },
            "&.guidanceInfo": {
              marginTop: 10,
            },
            "& strong": {},
          },
          "&.initial": {
            paddingLeft: 0,
          },
          "&.before-tax": {
            boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
            borderRadius: 8,
          },
          "&.after-tax": {
            boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
            borderRadius: 8,
            backgroundColor: "#EDEDED",
            [theme.breakpoints.up("md")]: {
              flex: "0 0 calc(50% - 24px)",
            },
            [theme.breakpoints.up("lg")]: {
              paddingLeft: 80,
              paddingRight: 80,
            },
            "& > div": {
              width: "calc(50% - 12px)",
              display: "inline-block",
              verticalAlign: "top",
              "&:nth-of-type(1)": {
                marginRight: 24,
              },
            },
          },
        },
      },
      "& .further": {
        paddingTop: 24,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
          paddingTop: 40,
        },
        "& .logo": {
          flex: "0 0 100%",
          [theme.breakpoints.up("md")]: {
            flex: "0 0 50%",
          },
          "& img": {
            width: 215,
            margin: "0 auto",
            display: "block",
            [theme.breakpoints.up("md")]: {
              margin: "0",
            },
          },
        },
        "& .link": {
          flex: "0 0 100%",
          textAlign: "center",
          [theme.breakpoints.up("md")]: {
            textAlign: "right",
            flex: "0 0 50%",
          },
          "& a.button": {
            margin: "24px auto 0",
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            boxShadow: "0 8px 16px rgba(0,0,0,0.16)",
            border: "none",
            fontFamily: "'Bebas Neue', sans-serig",
            fontSize: 18,
            letterSpacing: "0.016em",
            lineHeight: 1,
            textAlign: "center",
            borderRadius: 25,
            padding: "14px 60px",
            display: "block",
            width: "fit-content",
            textDecoration: "none",
            [theme.breakpoints.up("md")]: {
              margin: 0,
              marginLeft: "auto",
            },
          },
        },
        "& .exp": {
          flex: "0 0 100%",
          marginTop: 32,
          [theme.breakpoints.up("md")]: {
            marginTop: 50,
          },
          [theme.breakpoints.up("lg")]: {
            marginTop: 90,
          },
          "& p": {
            fontSize: 14,
            fontStyle: "italic",
            letterSpacing: "0.016em",
            lineHeight: "24px",
            marginBottom: 0,
            marginTop: 0,
            "& span": {
              color: theme.palette.secondary.main,
            },
          },
        },
      },
    },
  },
});
