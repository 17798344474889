import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import { buildBlocks } from "../../../services/builder";
import Img from "gatsby-image";
import styles from './styles';
// import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(theme => styles(theme));

export const CgbBlogBannerBlock = (props) => {
    // console.log(props);
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    let image = null;

    // Use the gatsby image if available
    if(props.attributes.localFile){
        if(props.attributes.localFile.childImageSharp){
            image = <Img
                fluid={props.attributes.localFile.childImageSharp.fluid}
                alt=""
            />
        } else {
            // Gifs do not have a childImageSharp
            image = <img
                className="desktop-image"
                src={props.attributes.localFile.publicURL}
                alt=""
            />
        }
    } else {
        // This is a preview image
        image = <div className="gatsby-image-wrapper desktop-image">
            <div style={{paddingBottom: '55%'}} />
            <img
                src={process.env.GATSBY_ADMIN_URL + props.attributes.mediaUrl}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
    }
    
    return <div className={`blog-banner ${classes.blogBanner} ${classes.blogBannerTheme}`}>
        <div className="image-wrap">            
            {image}
        </div>
        <div className="con-wrap">
            <div className="date">{props.pageContext.date}</div>
            {blocks[0]}
            {blocks[1]}
        </div>
    </div>
};

CgbBlogBannerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbBlogBannerBlockFragment = graphql`
    fragment CgbBlogBannerBlock on WpBlock {
        ... on WpCgbBlogBannerBlock {
            name
            originalContent
            attributes {
                __typename
                ... on WpCgbBlogBannerBlockAttributes {
                    className
                    mediaUrl
                    localFile {
                        publicURL
                        childImageSharp {
                            fluid(quality: 80, maxWidth: 1660) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbBlogBannerBlockPreview = `
    ... on CgbBlogBannerBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbBlogBannerBlockAttributes {
                className
                mediaUrl
            }
        }
    }
`;