export default (theme) => ({
  prodCatList: {
    position: "relative",
    width: "calc(100% + 24px)",
    margin: "0 -12px 0",
    padding: "32px 0",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      padding: "80px 0",
    },
    "&::before": {
      content: '""',
      backgroundColor: "white",
      zIndex: -1,
      top: 0,
      bottom: 0,
      width: "100vw",
      left: "50%",
      transform: "translateX(-50%)",
      position: "absolute",
    },
    "& .cat-link": {
      border: "20px solid white",
      position: "relative",
      color: "white",
      flex: "0 0 calc(100% - 24px)",
      margin: 12,
      boxShadow: "0 4px 8px rgba(0,0,0,0.08)",
      transition: "opacity .3s ease-in-out",
      [theme.breakpoints.up(400)]: {
        flex: "0 0 calc(50% - 24px)",
      },
      [theme.breakpoints.up("sm")]: {
        flex: "0 0 calc(33.333% - 24px)",
      },
      [theme.breakpoints.up("md")]: {
        flex: "0 0 calc(25% - 24px)",
      },
      "&:hover, &:focus": {
        opacity: 0.8,
      },
      "& .gatsby-image-wrapper": {
        "& > div": {
          paddingBottom: "100% !important",
          maxHeight: 250,
        },
      },
      "& span": {
        backgroundColor: "white",
        fontFamily: theme.headerFont,
        fontSize: 18,
        letterSpacing: "0.016em",
        position: "absolute",
        bottom: -1,
        right: 40,
        left: -5,
        color: theme.palette.primary.main,
        padding: "10px 12px 5px 5px",
        clipPath:
          "polygon(0% 0%, calc(100% - 32px) 0, 100% 40px, 100% 100%, 0 100%)",
      },
      "& > img": {
        width: 36,
        height: 36,
        position: "absolute",
        bottom: 2,
        right: 2,
        filter: "brightness(0) invert(1)",
      },
    },
  },
});
