export default theme => ({
    thickIntro: {
        position: 'relative',
        marginBottom: 36,
        [theme.breakpoints.up('md')]: {
            width: 'calc(87.5% - 24px)',
            margin: '0 auto 36px',
        },
        '&.push-left': {
            margin: '0 0 36px',
        },
        '& [class*=" MuiTypography-h"]': {
            color: theme.palette.secondary.main,
            margin: 0,
            marginBottom: 16,
            fontFamily: "Roboto Condensed",
            fontSize: 12,
            lineHeight: 1.2,
            letterSpacing: '0.152em',
            fontWeight: 400,
            textTransform: 'uppercase',
        },
        '& p': {
            lineHeight: 1.33,
            fontFamily: 'Roboto Condensed',
            color: theme.palette.primary.main,
            letterSpacing: 0,
            fontSize: 24,
            margin: '16px 0',
            [theme.breakpoints.up('md')]: {
                fontSize: 48,
            }
        }
    },
    thickIntroTheme: {
        ...theme.thickIntroTheme,
    }
})