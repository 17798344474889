export default theme => ({
    iconListInner: {
        display: 'block',
        fontSize: 16,
        lineHeight: '1.5',
        marginBottom: 10,
        letterSpacing: '0.032em',
        [theme.breakpoints.up('md')]: {
            marginBottom: 15,
        },
        '& .gatsby-image-wrapper': {
            fontSize: 0,
            width: '24px !important',
            display: 'inline-block',
            marginRight: 10,
            verticalAlign: 'middle',
            '& > div': {
                paddingBottom: '24px !important',
            }
        }
    },
    iconListInnerTheme: {
        ...theme.iconListInnerTheme,
    }
})