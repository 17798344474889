import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';
import GoBack from '../../GoBack';
import Link from "../../Link";
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import brewCache from "../../../../.brew-cache/data.json";

const useStyles = makeStyles(theme => styles(theme));

export const CgbBlogPostWrapBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    // console.log(brewCache);

    return <div className={`blog-post-wrapper ${props.attributes.className ? props.attributes.className : ''} ${classes.blogPostWrapper}`}>
        <div className="extras">
            <div className="go-back">
                <GoBack fallback={'/case-studies'} />
            </div>
            <div className="share">
                <span>Share</span>
                <Link to={"https://www.facebook.com/sharer/sharer.php?u=" + brewCache.url + props.pageContext.pagePath} className="facebook"><FacebookIcon/></Link>
                <Link to={"https://twitter.com/intent/tweet?text=" + props.pageContext.pageTitle + "&url=" + brewCache.url + props.pageContext.pagePath} className="twitter"><TwitterIcon/></Link>
            </div>
        </div>
        <div className="container">
            {blocks.map((block) => {
                return block;
            })}
        </div>
    </div>
};

export const CgbBlogPostWrapBlockFragment = graphql`
  fragment CgbBlogPostWrapBlock on WpBlock {
    ... on WpCgbBlogPostWrapBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbBlogPostWrapBlockPreview = `
    ... on CgbBlogPostWrapBlock {
        name
        attributes {
            className
        }
    }
`;