import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import Grid from '@material-ui/core/Grid';
import {buildBlocks} from "../../../services/builder";

export const CoreColumnsBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, {childrenLength: props.innerBlocks.length},  props.pageContext);
    return <Grid className={props.attributes.className} container spacing={3}>
        {blocks.map((block) => {
            return block;
        })}
    </Grid>
};

CoreColumnsBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CoreColumnsBlockFragment = graphql`
  fragment CoreColumnsBlock on WpBlock {
    ... on WpCoreColumnsBlock {
        name
        attributes {
          __typename
         ... on WpCoreColumnsBlockAttributes {
            className
            backgroundColor
            textColor
            verticalAlignment
          }
        }
      }
  }`;

  // This is the string version of this that is used within previews and without WP prefixes
export const CoreColumnsBlockPreview = `
... on CoreColumnsBlock {
    name
    attributes {
      __typename
     ... on CoreColumnsBlockAttributes {
        className
        backgroundColor
        textColor
        verticalAlignment
      }
    }
  }
`;