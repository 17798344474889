import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import ReactHtmlParser from "react-html-parser";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import scrollTo from "gatsby-plugin-smoothscroll";

import Link from "../../Link";
import { buildBlocks } from "../../../services/builder";
import styles from "./styles";
// import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles((theme) => styles(theme));

export const CgbSplitContentBannerBlock = (props) => {
  // console.log(props);
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();
  const { bannerCtaLink, bannerCtaText } = props.attributes;

  let image = null;

  // Use the gatsby image if available
  if (props.attributes.localFile) {
    if (props.attributes.localFile.childImageSharp) {
      image = (
        <Img fluid={props.attributes.localFile.childImageSharp.fluid} alt="" />
      );
    } else {
      // Gifs do not have a childImageSharp
      image = (
        <img
          className="desktop-image"
          src={props.attributes.localFile.publicURL}
          alt=""
        />
      );
    }
  } else {
    // This is a preview image
    image = (
      <div className="gatsby-image-wrapper desktop-image">
        <div style={{ paddingBottom: "55%" }} />
        <img
          src={process.env.GATSBY_ADMIN_URL + props.attributes.mediaUrl}
          alt=""
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </div>
    );
  }

  const isAnchor =
    (bannerCtaLink &&
      !bannerCtaLink.includes("http") &&
      bannerCtaLink.includes(".")) ||
    bannerCtaLink.includes("#");

  function handleScroll(e) {
    if (isAnchor) e.preventDefault();

    scrollTo(bannerCtaLink);
  }

  return (
    <div
      className={`split-content-banner ${classes.splitConBanner} ${classes.splitConBannerTheme}`}
    >
      <div className="image-wrap">
        {image}
        {blocks[0]}
      </div>
      <div className="con-wrap">
        {blocks[1]}
        {blocks[2]}
        {blocks[3]}
        {bannerCtaLink && bannerCtaText && !isAnchor && (
          <Link
            to={bannerCtaLink}
            style={{ textDecoration: "none" }}
            className={classes.root}
            onClick={(e) => handleScroll(e)}
          >
            <Button
              variant="contained"
              title={bannerCtaText}
              className={"foreground_primary"}
              data-text-color={"foreground_primary"}
              data-bg-color={"secondary"}
            >
              {ReactHtmlParser(bannerCtaText)}
            </Button>
          </Link>
        )}
        {bannerCtaLink && bannerCtaText && isAnchor && (
          <div // eslint-disable-line
            to={bannerCtaLink}
            style={{ textDecoration: "none" }}
            className={classes.root}
            onClick={(e) => handleScroll(e)}
            role="button"
          >
            <Button
              variant="contained"
              title={bannerCtaText}
              className={"foreground_primary"}
              data-text-color={"foreground_primary"}
              data-bg-color={"secondary"}
            >
              {ReactHtmlParser(bannerCtaText)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

CgbSplitContentBannerBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
  bannerCtaLink: PropTypes.string,
  bannerCtaText: PropTypes.string,
};

export const CgbSplitContentBannerBlockFragment = graphql`
  fragment CgbSplitContentBannerBlock on WpBlock {
    ... on WpCgbSplitContentBannerBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpCgbSplitContentBannerBlockAttributes {
          className
          mediaUrl
          bannerCtaLink
          bannerCtaText
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbSplitContentBannerBlockPreview = `
    ... on CgbSplitContentBannerBlock {
        name
        originalContent
        attributes {
            __typename
            ... on CgbSplitContentBannerBlockAttributes {
                className
                mediaUrl
                bannerCtaLink
                bannerCtaText
            }
        }
    }
`;
