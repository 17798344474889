import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbCalloutCtaBlock = (props) => {
    // console.log(props);

    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    let contained = '';

    if(props.attributes.contained === true) {
        contained = 'contained';
    }

    return <div className={`callout-cta ${props.attributes.className ? props.attributes.className : ''} ${classes.calloutCta} ${classes.calloutCtaTheme} ${contained}`}>
        {blocks[0]}
        {blocks[1]}
    </div>
};

export const CgbCalloutCtaBlockFragment = graphql`
  fragment CgbCalloutCtaBlock on WpBlock {
    ... on WpCgbCalloutCtaBlock {
        name
        attributes {
            className
            contained
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbCalloutCtaBlockPreview = `
    ... on CgbCalloutCtaBlock {
        name
        attributes {
            className
            contained
        }
    }
`;