export default theme => ({
    blogPostWrapper: {
        position: 'relative',
        marginBottom: 36,
        padding: '50px 0',
        [theme.breakpoints.up('md')]: {
            margin: '0 -12px',
            width: 'calc(100% + 24px)',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            padding: '50px 12px',
            justifyContent: 'center',
        },
        '& .extras': {
            display: 'flex',
            flex: '0 0 100%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            marginBottom: 24,
            [theme.breakpoints.up('md')]: {
                marginBottom: '-100px',
            },
            '& .go-back': {
                '& button': {
                    fontSize: 18,
                    padding: 0,
                    lineHeight: 1.5,
                    border: 'none',
                    backgroundColor: 'transparent',
                    color: theme.palette.secondary.main,
                    letterSpacing: '0.016em',
                    textTransform: 'uppercase',
                    fontFamily: theme.headerFont,
                    outline: 'none',
                    '&::before': {
                        content: '""',
                        borderLeft: '2px solid ' + theme.palette.secondary.main,
                        borderBottom: '2px solid ' + theme.palette.secondary.main,
                        display: 'inline-block',
                        width: 10,
                        height: 10,
                        transform: 'rotate(45deg)',
                        marginRight: 6,
                        marginTop: -4,
                    }
                }       
            },
            '& .share': {
                '& span': {
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginRight: 15,
                    fontSize: 16,
                    fontFamily: "Overpass",
                    letterSpacing: '0.025em',
                    lineHeight: 1.5,
                    fontWeight: 600,
                },
                '& a': {
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    marginLeft: 10,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    color: 'white',
                    padding: 7,
                    transition: 'opacity .3s ease-in-out',
                    '&:hover, &:focus': {
                        opacity: .8,
                    },
                    '&.facebook': {
                        backgroundColor: '#385C8E'
                    },
                    '&.twitter': {
                        backgroundColor: '#03A9F4'
                    },
                    '& svg': {
                        width: 16,
                        height: 16,
                    }
                }
            }
        },
        '& .container': {
            [theme.breakpoints.up('md')]: {
                flex: '0 0 75%',
                maxWidth: '75%',
                padding: '0 12px',
            },
            [theme.breakpoints.up('lg')]: {
                flex: '0 0 62.5%',
                maxWidth: '62.5%',
                padding: '0 12px',
            },
            '& > * ': {
                marginBottom: 50,
            },
            '& > figure.wp-block-image': {
                [theme.breakpoints.up('md')]: {
                    width: '120%',
                    margin: '0 -10% 50px !important',
                },
                '& span.gatsby-image-wrapper': {
                    width: '100% !important',
                },
                '&.full-width': {
                    [theme.breakpoints.up('lg')]: {
                        width: '160%',
                        margin: '0 -30% 50px !important',
                    },
                },
                '& figcaption': {
                    fontSize: 10,
                    textTransform: 'uppercase',
                    letterSpacing: '0.152em',
                    lineHeight: 1.6,
                    fontWeight: 500,
                    padding: '28px 0',
                }
            },
            '& > figure.wp-block-gallery': {
                margin: '0 auto 36px !important',
                '& .blocks-gallery-grid': {
                    margin: 0,
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    [theme.breakpoints.up('sm')]: {
                        width: 'calc(100% + 24px)',
                        margin: '0 -12px',
                    },
                    '& li': {
                        display: 'block',
                        flex: '0 0 100%',
                        maxWidth: '100%',
                        marginBottom: 24,
                        [theme.breakpoints.up('sm')]: {
                            flex: '0 0 50%',
                            maxWidth: '50%',
                            padding: '0 12px',
                        },
                        '& figure': {
                            display: 'block',
                            margin: 0,
                            width: '100%',
                            position: 'relative',
                            '& span.gatsby-image-wrapper': {
                                width: '100% !important',
                            },
                            '& figcaption': {
                                fontSize: 10,
                                textTransform: 'uppercase',
                                letterSpacing: '0.152em',
                                lineHeight: 1.6,
                                fontWeight: 500,
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                                backgroundColor: 'white',
                                padding: '30px 20px',
                                width: 135
                            }
                        }
                    }
                }
            },
            '& > p': {
                lineHeight: 2,
                letterSpacing: '0.016em',
            },
            '& > p.lead': {
                fontSize: 18,
                fontFamily: "Overpass",
                color: theme.palette.primary.main,
                letterSpacing: '0.016em',
                lineHeight: 1.78,
                fontWeight: 800,
            },
        },
        '& span.image-with-caption': {
            display: 'block',
        }
    },
})