import chevron from "../../images/chevron.png";

export default (theme) => ({
  courseAvailability: {
    position: "relative",
    marginBottom: 36,
    "&::before": {
      backgroundColor: theme.palette.primary.main,
      content: '""',
      position: "absolute",
      top: -32,
      bottom: -36,
      left: "50%",
      width: "100vw",
      transform: "translateX(-50%)",
      zIndex: -1,
      [theme.breakpoints.up("md")]: {
        top: -80,
        bottom: -80,
      },
    },
    "& .course-wrapper": {
      backgroundColor: "white",
      padding: 32,
      marginTop: 32,
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        padding: 80,
        marginTop: 80,
        marginBottom: 80,
      },
    },
    "& p": {
      "& a": {
        color: theme.palette.primary.main,
      },
    },
  },
  courseTitle: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
  },
  courseFilters: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",

      "& > div": {
        flex: 1,
        margin: "0 8px",
      },
    },
    "& label": {
      fontSize: 12,
      lineHeight: 1.6,
      letterSpacing: "0.0152em",
      textTransform: "uppercase",
      margin: 0,
      fontFamily: theme.bodyFont,
    },
    "& input, & textarea, & select, & button, .select": {
      fontFamily: theme.bodyFont + ", sans-serif",
      borderRadius: 20,
      fontSize: 12,
      lineHeight: 2,
      padding: "7px 20px",
      border: "1px solid " + theme.palette.primary.contrastText,
      marginBottom: 24,
      outline: "none",
      display: "block",
      width: "100%",
      boxShadow: "0 8px 16px rgba(0,0,0,0.16)",
      "-moz-appearance": "none",
      "-webkit-appearance": "none",
    },
    "& select, .select": {
      backgroundImage: "url(" + chevron + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center right 20px",
      paddingRight: 50,
    },
    "& .select": {
      maxWidth: 250,
      padding: "0 55px 0 0",
      overflow: "hidden",

      "&::before": {
        display: "none",
      },

      "& .MuiSelect-select": {
        padding: "7px 20px",
      },

      "& .MuiSelect-icon": {
        display: "none",
      },
    },
  },
  availListing: {
    marginTop: 36,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    "& .month-select": {
      whiteSpace: "nowrap",
      height: 45,
      overflowX: "scroll",
      overflowY: "hidden",
      [theme.breakpoints.up("sm")]: {
        flex: "0 0 180px",
        maxWidth: "180px",
        height: "auto",
        whiteSpace: "normal",
        overflow: "hidden",
      },
      "& button": {
        border: "none",
        backgroundColor: "transparent",
        borderBottom: "4px solid " + theme.palette.secondary.main,
        fontFamily: theme.headerFont,
        fontSize: 16,
        display: "inline-block",
        verticalAlign: "bottom",
        padding: "3px 10px",
        [theme.breakpoints.up("sm")]: {
          display: "block",
          borderBottom: "none",
          borderRight: "4px solid " + theme.palette.secondary.main,
          width: 160,
          textAlign: "right",
          fontSize: 24,
          padding: 24,
        },
      },
      "& .month": {
        position: "relative",
        "& label": {
          borderBottom: "4px solid " + theme.palette.secondary.main,
          fontSize: 12,
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          fontFamily: "Overpass",
          display: "inline-block",
          cursor: "pointer",
          padding: "5px 20px",
          position: "relative",
          [theme.breakpoints.up("sm")]: {
            display: "block",
            borderBottom: "none",
            borderRight: "4px solid " + theme.palette.secondary.main,
            width: 160,
            textAlign: "right",
            fontSize: 16,
          },
          "&.active": {
            backgroundColor: theme.palette.secondary.main,
            fontWeight: "bold",
            textShadow: "2px 0 black",
            color: theme.palette.secondary.contrastText,
            "&::after": {
              position: "absolute",
              content: '""',
              display: "block",
              top: "100%",
              left: "calc(50% - 15px)",
              width: 0,
              height: 0,
              borderStyle: "solid",
              borderWidth: "50px 15px 0 15px",
              borderColor:
                theme.palette.secondary.main +
                " transparent transparent transparent",
              [theme.breakpoints.up("sm")]: {
                top: 0,
                left: "calc(100% + 4px)",
                borderWidth: "17px 0 17px 65px",
                borderColor:
                  "transparent transparent transparent " +
                  theme.palette.secondary.main,
              },
            },
          },
        },
        "& input": {
          position: "absolute",
          top: -6,
          left: 0,
          opacity: 0,
          width: "100%",
          height: "28px",
          margin: 0,
          cursor: "pointer",
          [theme.breakpoints.up("sm")]: {
            width: 156,
            height: 34,
          },
        },
      },
    },
    "& .listing-wrap": {
      borderBottom: "1px solid rgba(0,0,0,0.3)",
      [theme.breakpoints.up("sm")]: {
        flex: "0 0 calc(100% - 215px)",
        minWidth: "calc(100% - 215px)",
      },
      "& .course-titles": {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          fontFamily: "Roboto Condensed",
          letterSpacing: "0.152em",
          lineHeight: 1.2,
          fontSize: 10,
          opacity: 0.3,
          textTransform: "uppercase",
          "& .dates": {
            flex: "0 0 55.55%",
            minWidth: "55.55%",
            padding: "6px 20px",
          },
          "& .location, & .spaces": {
            flex: "0 0 22.222%",
            minWidth: "22.222%",
            padding: "6px 20px",
          },
        },
        [theme.breakpoints.up("lg")]: {
          "& .dates": {
            flex: "0 0 45.45%",
            minWidth: "45.45%",
          },
          "& .location, & .spaces": {
            flex: "0 0 18.18%",
            minWidth: "18.18%",
          },
        },
      },
      "& .course-item": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        borderTop: "1px solid rgba(0,0,0,0.3)",
        backgroundColor: "transparent",
        alignItems: "center",
        "&:nth-of-type(even)": {
          backgroundColor: "#ededed4d",
        },
        "&.full *": {
          filter: "grayscale(1)",
          opacity: 0.5,
        },
        "&.full a": {
          cursor: "not-allowed",
        },
        "& span.title": {
          fontFamily: "Roboto Condensed",
          letterSpacing: "0.152em",
          lineHeight: 1.2,
          fontSize: 10,
          opacity: 0.3,
          display: "block",
          marginBottom: 5,
          color: "#0F0F0F",
          textTransform: "uppercase",
        },
        "& .dates": {
          fontFamily: theme.headerFont,
          color: theme.palette.secondary.main,
          fontSize: 16,
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          textTransform: "uppercase",
          fontWeight: "bold",
          padding: 12,
          flex: "0 0 100%",
          minWidth: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& > span": {
            display: "block",
            textAlign: "center",
            margin: " 0 10px",
            "& > span": {
              display: "block",
              fontSize: 38,
              lineHeight: 0.75,
            },
          },
        },
        "& .details": {
          flex: "0 100%",
          minWidth: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          "& > .course-title": {
            flex: "0 0 100%",
            minWidth: "100%",
            padding: 12,
            "& a": {
              fontSize: 14,
              color: theme.palette.primary.main,
              fontFamily: "Overpass",
              fontWeight: "bold",
              textDecoration: "underline",
              lineHeight: 1.5,
              letterSpacing: "0.016em",
              [theme.breakpoints.up("md")]: {
                fontSize: 16,
              },
            },
          },
          "& .location, & .spaces, & .provider, & .length, & .category": {
            flex: "0 0 50%",
            minWidth: "50%",
            padding: 12,
            fontFamily: "Overpass",
            letterSpacing: "0.016em",
            lineHeight: "16px",
            fontSize: 12,
            "& strong": {
              fontWeight: "bold",
              fontSize: 13,
              width: 25,
              color: "#279B1E",
              display: "inline-block",
              "&.full": {
                color: theme.palette.secondary.main,
              },
            },
          },
        },
        "& .actions": {
          flex: "0 0 100%",
          minWidth: "100%",
          padding: 12,
          paddingBottom: 32,
          "& a": {
            display: "table",
            margin: "0 auto",
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            textTransform: "uppercase",
            fontFamily: theme.headerFont,
            fontSize: 16,
            lineHeight: 1.5,
            borderRadius: 16,
            boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
            textDecoration: "none",
            padding: "5px 20px",
            transition:
              "background-color .3s ease-in-out, color .3s ease-in-out",
            "&:hover, &:focus": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
            "&.more": {
              backgroundColor: theme.palette.primary.contrastText,
              color: theme.palette.primary.main,
              marginBottom: 16,
              "&:hover, &:focus": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            },
            [theme.breakpoints.up("md")]: {
              marginLeft: "auto",
              marginRight: 0,
            },
          },
        },
        [theme.breakpoints.up("md")]: {
          "& .dates": {
            flex: "0 0 130px",
            minWidth: "130px",
            padding: 20,
          },
          "& .details": {
            flex: "0 0 calc(100% - 280px)",
            minWidth: "calc(100% - 280px)",
            "& .course-title, & .location, & .spaces, & .provider, & .length, & .category": {
              // flex: '0 0 25%',
              // minWidth: '25%',
              padding: "20px !important",
            },
            "& .course-title": {
              paddingBottom: "4px !important",
            },
          },
          "& .actions": {
            flex: "0 0 150px",
            minWidth: "150px",
            padding: 20,
          },
        },
        [theme.breakpoints.up("lg")]: {
          "& .details": {
            "& .location, & .spaces, & .provider, & .length, & .category": {
              flex: "0 0 20% !important",
              minWidth: "20% !important",
            },
          },
        },
      },
    },
  },
});
