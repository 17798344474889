import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import dateformat from "dateformat";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { customWSPDateFormat, filterDuplicates, makeDateKey } from "./helpers";
import Link from "../Link";
import Months from "./Months";
import { CoreHeadingBlock } from "../blocks/CoreHeadingBlock";
import styles from "./styles";
import {
  selectMonth,
  selectYear,
  selectCategory,
  selectType,
  selectLocation,
  deselectLocation,
  resetLocations,
  selectProvider,
  setStatus,
  setCourseDates,
} from "./app";
import {
  fetchCourseDates,
  fetchCourseCategories,
  fetchCourseProviders,
  fetchCourseLocations,
} from "./actions";

const useStyles = makeStyles((theme) => styles(theme));

const Calendar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const now = new Date();
  const {
    selectedMonth,
    selectedYear,
    selectedCategory,
    selectedType,
    selectedLocation,
    selectedProvider,
    status,
    filteredCourseDates,
  } = useSelector((state) => state.CourseCalendarSlice);

  const [allCourseDates, setAllCourseDates] = useState({});
  const [categories, setCategories] = useState([]);
  const [providers, setProviders] = useState([]);
  const [locations, setLocations] = useState([]);

  async function retrieveCourseDates() {
    const startDate = makeDateKey(selectedYear, selectedMonth) + "01";
    let endYear = parseInt(selectedYear);
    let endMonth = parseInt(selectedMonth) + 1;
    if (endMonth === 13) {
      endYear = endYear + 1;
      endMonth = 1;
    }
    const endDate = makeDateKey(endYear, endMonth) + "01";

    dispatch(setStatus("fetching"));
    const courseDates = await fetchCourseDates("", [], startDate, endDate);
    const sortedCourses = courseDates
      .map((courseDate) => courseDate.node)
      .sort(
        (a, b) =>
          parseInt(a.courseData.startDate) - parseInt(b.courseData.startDate)
      );
    let newCourseDates = allCourseDates;
    newCourseDates[makeDateKey(selectedYear, selectedMonth)] = sortedCourses;
    setAllCourseDates(newCourseDates);
    dispatch(setCourseDates(sortedCourses));
    dispatch(setStatus("complete"));
    return sortedCourses;
  }

  useEffect(() => {
    const currentMonth = dateformat(now, "m") - 1;
    const currentYear = dateformat(now, "yyyy");

    dispatch(selectMonth(parseInt(currentMonth) + 1));
    dispatch(selectYear(currentYear));

    async function fetchData() {
      const categories = await fetchCourseCategories();
      const providers = await fetchCourseProviders();
      const locations = await fetchCourseLocations();

      setCategories(categories.map((category) => category.node));
      setProviders(providers.map((provider) => provider.node));

      setLocations(locations);

      dispatch(setStatus("complete"));
    }

    fetchData();
  }, []); //eslint-disable-line

  // Fetch course dates when necessary
  useEffect(() => {
    if (selectedMonth && selectedYear) {
      let dates = allCourseDates[makeDateKey(selectedYear, selectedMonth)];
      if (typeof dates === "undefined") {
        retrieveCourseDates();
      } else {
        dispatch(setCourseDates(dates));
      }
    }
  }, [selectedMonth, selectedYear]);

  function handleMonth(e) {
    if (e) e.preventDefault();
    if (e.target.type !== "radio") return false;
    const year = Number(e.target.getAttribute("year"));

    dispatch(selectYear(year));
    dispatch(selectMonth(Number(e.target.value)));
  }

  function renderCourseDates() {
    if (status === "fetching") {
      return <p>Loading...</p>;
    }
    const dateKey = makeDateKey(selectedYear, selectedMonth);
    if (typeof allCourseDates[dateKey] === "undefined") return null;
    const thisMonthsCourseDates = allCourseDates[dateKey]
      .filter((courseDate) => {
        if (!courseDate.courseData.course) {
          return false;
        }

        const courseCategories =
          courseDate.courseData.course.courseCategories.edges;

        if (selectedCategory === "all") return courseDate;

        return courseCategories
          .map((cat) => cat.node.slug)
          .includes(selectedCategory);
      })
      .filter((courseDate) => {
        const courseType = courseDate.courseData.course.courses.type?.toLowerCase();
        if (!courseDate.courseData.place) {
          // no place set for the coursedate
          return false;
        }

        if (selectedLocation.length === 0) {
          // not filtering by location
          return true;
        }
        const courseLocation = courseDate.courseData.place.title.toLowerCase();

        return selectedLocation.includes(courseLocation);
      })
      .filter((courseDate) => {
        const courseProvider = courseDate.courseData.course.courses.provider.slug.toLowerCase();

        if (selectedProvider === "all") return courseDate;

        return courseProvider === selectedProvider;
      });

    const output = thisMonthsCourseDates.map((courseDate) => {
      const { id, title } = courseDate;
      const {
        seatsAvailable,
        seatsBooked,
        startDate,
        endDate,
        place,
        course,
      } = courseDate.courseData;
      const { uri, courseCategories } = course;
      const { provider } = course.courses;

      const isFull = seatsAvailable && seatsAvailable - seatsBooked === 0;

      return (
        <div key={id} className={`course-item ${isFull ? "full" : ""}`}>
          <div className="dates">
            <span className="from">{customWSPDateFormat(startDate)}</span>-
            <span className="to">{customWSPDateFormat(endDate)}</span>
          </div>

          <div className="details">
            <div className="course-title">
              <Link to={uri}>{title}</Link>
            </div>
            <div className="provider">
              <span className="title">Provider</span>
              <span>
                {provider.courseProviderInfo.logo && (
                  <img
                    src={provider.courseProviderInfo.logo.sourceUrl}
                    alt={provider.slug}
                  />
                )}
              </span>
            </div>
            <div className="location">
              <span className="title">Location</span>
              <span>{place.title}</span>
            </div>
            <div className="length">
              <span className="title">Length</span>
              <span>{course.courses.length}</span>
            </div>
            <div className="category">
              <span className="title">Category</span>
              <span>{courseCategories?.edges[0]?.node?.name ?? ""}</span>
            </div>
            <div className="spaces">
              {seatsAvailable && (
                <>
                  <span className="title">Spaces</span>
                  <span>
                    <strong className={isFull ? "full" : ""}>
                      {Math.max(0, seatsAvailable - seatsBooked)}
                    </strong>
                    /{seatsAvailable}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="actions">
            <Link to={uri} className="more">
              Read more
            </Link>
            <Link
              className="enquiry"
              to={isFull ? "" : "/course-enquiry"}
              state={{
                courseId: course.id,
                dateId: id,
                startDate,
                endDate,
                // location,
              }}
            >
              {isFull ? "Fully Booked" : "Book Today"}
            </Link>
          </div>
        </div>
      );
    });

    if (status === "complete" && thisMonthsCourseDates.length > 0) {
      return output;
    }

    return (
      <p>
        We currently don't have any courses scheduled for this month that match
        your filters. Please check other months, refine your filters or{" "}
        <Link to="/contact-us">get in touch</Link>.
      </p>
    );
  }

  return (
    <div className={`${classes.courseAvailability}`}>
      <div id="course-wrapper" className="course-wrapper">
        <div className={classes.courseTitle}>
          <CoreHeadingBlock
            attributes={{
              align: "",
              anchor: "",
              className: "",
              content: "Course Calendar",
              level: 2,
              textColor: "primary",
              backgroundColor: "",
              __typename: "WpCoreHeadingBlockAttributes",
            }}
            innerBlocks={[]}
          />
        </div>

        <div className={classes.courseFilters}>
          <div className="category">
            <label htmlFor="category">Course Category</label>
            <Select
              name="category"
              className="select"
              value={selectedCategory}
              inputProps={{ MenuProps: { disableScrollLock: true } }}
              onChange={(e) => dispatch(selectCategory(e.target.value))}
            >
              <MenuItem value="all">All</MenuItem>
              {categories.map((category) => {
                if (!filteredCourseDates) return null;
                const availableCategories = filteredCourseDates
                  .filter((course) => {
                    if (!course.courseData.course) {
                      return false;
                    }
                    return true;
                  })
                  .map((course) =>
                    course.courseData.course.courseCategories.edges.map(
                      (category) => category.node.slug
                    )
                  )
                  .flat()
                  .filter(filterDuplicates);
                // if (!availableCategories.includes(category.slug)) return null;
                return (
                  <MenuItem key={category.id} value={category.slug}>
                    {category.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="location">
            <label htmlFor="location">Location</label>
            <Select
              name="location"
              className="select"
              value={selectedLocation}
              onChange={(e) => {
                if (e.target.value.includes("all")) {
                  return dispatch(resetLocations());
                }
                return dispatch(selectLocation(e.target.value));
              }}
              multiple
              displayEmpty
              disableUnderline
              inputProps={{ MenuProps: { disableScrollLock: true } }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "All";
                }

                return selected.join(", ");
              }}
            >
              <MenuItem value="all">All</MenuItem>
              {locations.map((location, index) => {
                return (
                  <MenuItem key={index} value={location.title.toLowerCase()}>
                    {location.title}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="provider">
            <label htmlFor="provider">Provider</label>
            <Select
              name="provider"
              className="select"
              value={selectedProvider}
              inputProps={{ MenuProps: { disableScrollLock: true } }}
              onChange={(e) => dispatch(selectProvider(e.target.value))}
            >
              <MenuItem value="all">All</MenuItem>
              {providers.map((provider) => {
                if (!filteredCourseDates) return null;
                const availableProviders = filteredCourseDates
                  .filter((course) => {
                    if (!course.courseData.course) {
                      return false;
                    }
                    return true;
                  })
                  .map(
                    (course) => course.courseData.course.courses.provider.slug
                  )
                  .filter(filterDuplicates);
                // if (!availableProviders.includes(provider.slug)) return null;

                return (
                  <MenuItem key={provider.id} value={provider.slug}>
                    {provider.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </div>

        <div className={classes.availListing}>
          <Months
            handleMonth={handleMonth}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
          />

          <div className="listing-wrap">
            <div className="course-listing">{renderCourseDates()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
