export default theme => ({
    breakoutGalleryIntro: {
        position: 'relative',
        marginBottom: 36,
        // marginBottom: 100,
        '&::after': {
            clear: 'both',
            display: 'table',
            content: '""',
        },
        '& .image-wrapper': {
            position: 'relative',
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            maxWidth: '100vw',
            width: 'calc(100% + 50px)',
            zIndex: '1',
            [theme.breakpoints.up('md')]: {
                height: 'auto',
                width: '100vw',
                marginBottom: '-75px',
            },
            '& .gatsby-image-wrapper': {
                maxWidth: '100vw !important',
                width: '100% !important'
            },
            '&::after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: 18,
                backgroundColor: theme.palette.background.default,
                [theme.breakpoints.up('md')]: {
                    height: 130,
                    left: 18,
                },
                [theme.breakpoints.up('lg')]: {
                    height: 130,
                    left: 50,
                    right: 50,
                },
            },
        },
        '& .content-wrap': {
            position: 'relative',
        },
        '& .micro-header': {
            fontSize: 8,
            letterSpacing: '0.152em',
            lineHeight: '2',
            textTransform: 'uppercase',
            marginBottom: 30,
            display: 'block',
            position: 'relative',
            zIndex: '1',
        },
        '& .bold-con': {
            position: 'relative',
            zIndex: '1',
            [theme.breakpoints.up('md')]: {
                float: 'left',
                width: '50%',
            },
            '& p': {
                fontWeight: '600',
                marginBottom: 45,
                lineHeight: '2',
                letterSpacing: '0',
                [theme.breakpoints.up('md')]: {
                    fontSize: 18,
                },
            }
        },
        '& .standard-con': {
            position: 'relative',
            zIndex: '1',
            [theme.breakpoints.up('md')]: {
                float: 'left',
                width: '50%',
                paddingLeft: 30,
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: 140,
            },
            '& p': {
                marginBottom: 45,
                lineHeight: '2',
                letterSpacing: '0.032em',
            }
        },
        '& .gallery': {
            position: 'relative',
            zIndex: '1',
            clear: 'both',
            width: 'calc(100% + 56px)',
            margin: '0 -28px',
            [theme.breakpoints.up('md')]: {
                position: 'absolute',
                width: '60%',
                right: '-12px',
                bottom: 'calc(100% + 65px)',
            },
            '& ul': {
                justifyContent: 'center',
                overflow: 'visible',
                '& li': {
                    width: '50% !important',
                    padding: '12px !important',
                    [theme.breakpoints.up('md')]: {
                        width: '33.333% !important',
                        '& > div': {
                            boxShadow: '0 23px 25px rgba(0,0,0,0.4)',
                        }
                    },
                    '& img, & .gatsby-image-wrapper': {
                        '& > div': {
                            paddingBottom: '120% !important',
                        }
                    },
                }
            }
        }
    },
    breakoutGalleryIntroTheme: {
        ...theme.breakoutGalleryIntroTheme,
    }
})