export default theme => ({
    thinIntro: {
        position: 'relative',
        marginBottom: 36,
        // padding: '50px 0 70px',
        [theme.breakpoints.up('md')]: {
            // padding: '180px 0 150px',
        },
        '& p': {
            lineHeight: 1.75,
        }
    },
    thinIntroTheme: {
        ...theme.thinIntroTheme,
    }
})