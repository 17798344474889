export default theme => ({
    list: {
        display: 'block',
        position: 'relative',
        marginBottom: 36,
        '& li': {
            fontSize: 16,
            letterSpacing: '0.024em',
            lineHeight: 1.5,
            marginBottom: 6,
            '& a': {
                color: theme.palette.primary.main,
                '&:hover, &:focus': {
                    color: theme.palette.primary.dark,
                }
            }
        },
        '& ul, & ol': {
            marginTop: 24,
        }
    },
})