export default theme => ({
    twoColWrapper: {
        position: 'relative',
        marginBottom: 36,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        width: 'calc(100% + 24px)',
        marginLeft: -12,
        marginRight: -12,
        '& .left': {
            flex: '0 0 100%',
            padding: '0 12px',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 56.25%',
                maxWidth: '56.25%',
            },
        },
        '& .right': {
            flex: '0 0 100%',
            padding: '0 12px',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 43.75%',
                maxWidth: '43.75%',
                paddingTop: 40,
                '& [class*=" MuiTypography-h"]': {
                    textTransform: 'uppercase',
                }
            },
            [theme.breakpoints.up('lg')]: {
                marginLeft: '6.25%',
                flex: '0 0 32.25%',
                maxWidth: '32.25%',
            }
        },
        '&.white-bg': {
            padding: '32px 0 0',
            [theme.breakpoints.up('md')]: {
                padding: '70px 0 34px',
            },
            '&::before': {
                zIndex: -1,
                position: 'absolute',
                left: '50%',
                width: '100vw',
                backgroundColor: 'white',
                top: 0,
                bottom: 0,
                content: '""',
                display: 'block',
                transform: 'translateX(-50%)',
            }
        }
    },
    twoColWrapperTheme: {
        // ...theme.twoColWrapperTheme,
    }
})