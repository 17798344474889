import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from "@material-ui/styles";
// import { buildBlocks } from "../../../services/builder";
import styles from "./styles";
import Slider from "react-slick";
// import Img from "gatsby-image";
import Link from "../../Link";

import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbTopBrandsBlock = (props) => {
  // console.log(props);
  // const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query TopBrands {
      wp {
        themeOptions {
          ThemeOptions {
            featuredBrands {
              name
              uri
              slug
              BrandExtras {
                logo
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div
      className={`top-brands ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.topBrands}`}
    >
      <CoreHeadingBlock
        attributes={{
          align: "",
          anchor: "",
          className: "",
          content: "Top Brands",
          level: 2,
          backgroundColor: "",
          textColor: "primary",
          __typename: "WpCoreHeadingBlockAttributes",
        }}
        innerBlocks={[]}
      />
      <Slider
        arrows={true}
        dots={true}
        className="product-slider"
        slidesToShow={3}
        infinite={false}
        centerMode={false}
        responsive={[
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {data.wp.themeOptions.ThemeOptions.featuredBrands.map((brand) => {
          return (
            <Link to={`/brands/${brand.slug}`}>
              <div className="logo-wrap">
                {brand.BrandExtras.logo !== null && (
                  <img Src={brand.BrandExtras.logo} alt="" />
                )}
              </div>
              <span>{brand.name}</span>
            </Link>
          );
        })}
      </Slider>
    </div>
  );
};

export const CgbTopBrandsBlockFragment = graphql`
  fragment CgbTopBrandsBlock on WpBlock {
    ... on WpCgbTopBrandsBlock {
      name
      attributes {
        __typename
        ... on WpCgbTopBrandsBlockAttributes {
          className
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbTopBrandsBlockPreview = `
    ... on CgbTopBrandsBlock {
        name
        attributes {
            __typename
            ... on CgbTopBrandsBlockAttributes {
                className
            }
        }
    }
`;
