import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbVideoTestimonialBlock = (props) => {
    const classes = useStyles();

    return <div
        className={`video-testimonial ${props.attributes.className ? props.attributes.className : ''} ${classes.videoTestimonial} ${classes.videoTestimonialTheme}`}
    >
        <p className="testimonial-quote">{ReactHtmlParser(props.attributes.videoTestimonialQuote)}</p>
        <div className="video">
            <div className="video-wrapper">
                <iframe title={"video-banner-"+props.attributes.videoTestimonialVideoID}
                        src={"https://player.vimeo.com/video/"+props.attributes.videoTestimonialVideoID+"?allowfullscreen=1&amp;webkitallowfullscreen=1&autoplay=0&muted=0&background=0"}
                        frameBorder="0"
                        allowFullScreen
                />
            </div>
        </div>
    </div>
};

CgbVideoTestimonialBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbVideoTestimonialBlockFragment = graphql`
    fragment CgbVideoTestimonialBlock on WpBlock {
        ... on WpCgbVideoTestimonialBlock {
            name
            attributes {
                __typename
                ... on WpCgbVideoTestimonialBlockAttributes {
                    className
                    videoTestimonialVideoID
                    videoTestimonialQuote
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbVideoTestimonialBlockPreview = `
    ... on CgbVideoTestimonialBlock {
        name
        attributes {
            __typename
            ... on CgbVideoTestimonialBlockAttributes {
                className
                videoTestimonialVideoID
                videoTestimonialQuote
            }
        }
    }
`;