import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from "@material-ui/styles";

// import { buildBlocks } from "../../../services/builder";
import styles from "./styles";
import { CoreHeadingBlock } from "../CoreHeadingBlock";
import Results from "./results";
import Link from "../../Link";
import { makeDateKey } from "../../Calendar/helpers";
import dateformat from "dateformat";

const useStyles = makeStyles((theme) => styles(theme));

const queryObjectCourses = (next = "") => {
  return {
    query: `
      {
        courses(first: 1000, after: "${next}") {
          edges {
            node {
              id
              title
              uri
              databaseId
              courses {
                type
                length
                provider {
                  name
                  slug
                  courseProviderInfo {
                    logo {
                      sourceUrl(size: MEDIUM)
                    },
                  }
                }
              }
              courseCategories {
                nodes {
                  name
                  slug
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  };
};

const queryObjectCourseDates = (next = "") => {
  return {
    query: `
      {
        courseDates(first: 1000, after: "${next}") {
          edges {
            node {
              id
              courseData {
                course {
                  ... on Course {
                    id
                    databaseId
                  }
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  };
};

export const CgbCourseListBlock = (props) => {
  // const blocks = buildBlocks(
  //   props.innerBlocks,
  //   props.parentAttributes,
  //   props.pageContext
  // );
  const classes = useStyles();

  const categories = useStaticQuery(graphql`
    query CategoriesList {
      allWpCourseCategory {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
      allWpCourseProvider {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `);

  const [category, setCategory] = useState("all");
  const [searching, setSearching] = useState(false);
  const [courses, setCourses] = useState(null);
  const [courseDates, setCourseDates] = useState([]);

  useEffect(() => {
    fetchCourses("", [], "");
    fetchCourseDates("", [], "");
  }, [props.pageContext]); //eslint-disable-line

  function handleSubmit(e) {
    if (e) e.preventDefault();

    setCourses([]);
    setSearching(true);
    fetchCourses("", []);
  }

  function fetchCourses(next = "", prevData = []) {
    setSearching(true);
    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        queryObjectCourses(next),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return {
          courses: res.data.data.courses.edges.map((node) => node.node),
          next: res.data.data.courses.pageInfo.endCursor,
          hasNextPage: res.data.data.courses.pageInfo.hasNextPage,
        };
      })
      .then((data) => {
        return {
          courses: data.courses.filter((course) => {
            if (
              category !== "all" &&
              !course.courseCategories.nodes
                .map((n) => n.slug)
                .includes(category)
            )
              return false;
            return course;
          }),
          next: data.next,
          hasNextPage: data.hasNextPage,
        };
      })
      .then((data) => {
        return {
          courses: data.courses.sort((a, b) => a.title.localeCompare(b.title)),
          next: data.next,
          hasNextPage: data.hasNextPage,
        };
      })
      .then((data) => {
        if (data.hasNextPage) {
          fetchCourses(data.next, prevData.concat(data.courses));
        } else {
          setCourses(prevData.concat(data.courses));
          setSearching(false);
        }
      });
  }


  const now = new Date();
  const currentMonth = dateformat(now, "m") - 1;
  const currentYear = dateformat(now, "yyyy");
  const startDate = makeDateKey(currentYear, currentMonth);

  function fetchCourseDates(next = "", prevData = []) {
    axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        queryObjectCourseDates(next, startDate),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return {
          courseDates: res.data.data.courseDates.edges
            .map((node) => node.node)
            .filter((node) => node.courseData.course ?? false)
            .map((node) => node.courseData.course.databaseId),
          next: res.data.data.courseDates.pageInfo.endCursor,
          hasNextPage: res.data.data.courseDates.pageInfo.hasNextPage,
        };
      })
      .then((data) => {
        if (data.hasNextPage) {
          fetchCourseDates(data.next, prevData.concat(data.courseDates));
        } else {
          setCourseDates(prevData.concat(data.courseDates));
        }
      });
  }

  const filteredCourses =
    courses &&
    courses.filter((course) => {
      if (category !== "all" && !course.courseCategories.nodes.map((n) => n.slug).includes(category)) {
        return false;
      }
      if (props.attributes.course && props.attributes.course !== course.courses.type.toLowerCase()) {
        return false;
      }
      return course;
    });

  const categoriesToShow =
    courses &&
    courses
      .map((course) =>
        course.courseCategories.nodes
          .filter((category) => category)
          .map((category) => category.slug)
      )
      .flat()
      .filter((slug, index, arr) => arr.includes(slug));

  return (
    <section
      className={`${classes.courseListingBlock} ${
        props.attributes.className ? props.attributes.className : ""
      }`}
    >
      <div className={classes.courseTitle}>
        <CoreHeadingBlock
          attributes={{
            align: "",
            anchor: "",
            className: "",
            content: "Course List",
            level: 2,
            textColor: "primary",
            backgroundColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="category">
            <label htmlFor="category">Course Category</label>
            <select
              name="category"
              defaultValue={category}
              onBlur={(e) => setCategory(e.target.value)}
            >
              <option value="all">All</option>
              {categories.allWpCourseCategory.edges.map((category) => {
                if (
                  !categoriesToShow ||
                  !categoriesToShow.includes(category.node.slug)
                )
                  return null;
                return (
                  <option key={category.node.id} value={category.node.slug}>
                    {category.node.name}
                  </option>
                );
              })}
            </select>
          </div>
        </form>
      </div>

      {searching && (
        <div>
          <p className={classes.searching}>Searching...</p>
        </div>
      )}

      {!searching && filteredCourses && !filteredCourses.length && (
        <div>
          <p>Your search hasn’t matched any of our available courses.</p>
          <p>Get in touch with the experts at Workshop Pro today.</p>
          <Link to="/contact-us/" className={classes.cta}>
            Contact Us
          </Link>
        </div>
      )}

      {!searching && filteredCourses && filteredCourses.length > 0 && (
        <Results
          courses={filteredCourses}
          courseDates={courseDates}
          classes={classes}
        />
      )}
    </section>
  );
};

CgbCourseListBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CgbCourseListBlockFragment = graphql`
  fragment CgbCourseListBlock on WpBlock {
    ... on WpCgbCourseListBlock {
      name
      attributes {
        className
        course
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbCourseListBlockPreview = `
    ... on CgbCourseListBlock {
        name
        attributes {
          className
          course
        }
    }
`;
