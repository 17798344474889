export default theme => ({
    featuredIcons: {
        position: 'relative',
        marginBottom: 36,
        '&.white-bg': {
            padding: '24px 0 48px',
            '&::before': {
                backgroundColor: 'white',
                content: '""',
                position: 'absolute',
                top: 0,
                left: '50%',
                width: '100vw',
                transform: 'translateX(-50%)',
                bottom: 0,
                display: 'block',
            },
            
        }
    },
    featuredIconsTheme: {
        ...theme.featuredIconsTheme,
    }
})