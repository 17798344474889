export default theme => ({
    imageLinkSliderInner: {
        width: '100%',
        boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
        display: 'block',
        textDecoration: 'none',
        '& .text-wrap': {
            backgroundColor: theme.palette.primary.main,
            position: 'relative',
            display: 'block',
            paddingBottom: 36,
            [theme.breakpoints.up('md')]: {
                backgroundColor: 'transparent',
                paddingBottom: 0,
            },
            '& .text': {
                fontFamily: theme.headerFont,
                color: theme.palette.primary.contrastText,
                textDecoration: 'none',
                fontSize: 20,
                letterSpacing: '-0.008em',
                lineHeight: 1.1,
                clipPath: 'polygon(0 0, calc(100% - 38px) 0, 100% 40px, 100% 100%, 0% 100%)',
                marginTop: -40,
                backgroundColor: theme.palette.primary.main,
                padding: 24,
                display: 'block',
                marginBottom: -1,
                boxSizing: 'content-box',
                [theme.breakpoints.up('md')]: {
                    fontSize: 32,
                    padding: 30,
                    clipPath: 'polygon(0 0, 302px 0, 344px 40px, 100% 40px, 100% 100%, 0% 100%)',
                    paddingRight: 245,
                    height: 70,
                }
            },
            '& .sub-text': {
                display: 'block',
                borderRadius: 25,
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.main,
                boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
                padding: '10px 40px',
                textAlign: 'center',
                letterSpacing: '0.016em',
                fontSize: 18,
                fontFamily: theme.headerFont,
                margin: '24px 24px 0',
                textDecoration: 'none',
                transition: 'background-color .3s ease-in-out',
                [theme.breakpoints.up('md')]: {
                    position: 'absolute',
                    right: 30,
                    top: 20,
                    margin: 0,
                    width: 195,
                }
            }
        },
        '&:hover, &:focus': {
            '& .text-wrap': {
                '& .sub-text': {
                    backgroundColor: theme.palette.secondary.dark,
                }
            }
        }
    },
    imageLinkSliderInnerTheme: {
        ...theme.imageLinkSliderTheme,
    }
})