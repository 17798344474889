export default theme => ({
    blogBanner: {
        position: 'relative',
        marginBottom: 36,
        backgroundColor: theme.palette.primary.main,
        marginTop: 24,
        boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap:'wrap',
            marginBottom: 36,
        },
        '& .image-wrap': {
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                flex: '0 0 calc(56.25% + 12px)',
                maxWidth: 'calc(56.25% + 12px)',
                '& .gatsby-image-wrapper': {
                    position: 'absolute !important',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }
            },
            '& [class*=" MuiTypography-h"]': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: theme.palette.primary.contrastText,
                padding: 30,
                [theme.breakpoints.up('md')]: {
                    transform: 'translateY(-50%)',
                    padding: 80,
                    right: 0,
                    left: 0,
                }
            }
        },
        '& .con-wrap': {
            padding: '40px 30px',
            [theme.breakpoints.up('md')]: {
                padding: 80,
                flex: '0 0 calc(43.75% - 12px)',
                maxWidth: 'calc(43.75% - 12px)',
            },
            '& [class*=" MuiTypography-"]': {
                color: theme.palette.primary.contrastText,
            },
            '& [class*=" MuiTypography-h"]': {
                marginTop: 0,
                marginBottom: 24,
                fontSize: 28,
                fontWeight: 'normal',
                fontFamily: theme.bodyFont,
                [theme.breakpoints.up('md')]: {
                    marginBottom: 38,
                    fontSize: 48,
                },
            },
            '& > p': {
                opacity: .57,
            },
            '& div.date': {
                fontFamily: theme.bodyFont,
                color: theme.palette.primary.contrastText,
                opacity: 1,
                fontSize: 12,
                letterSpacing: '0.152em',
                lineHeight: 1.25,
                textTransform: 'uppercase',
                marginBottom: 32,
            }
        }
    },
    blogBannerTheme: {
        ...theme.blogBannerTheme,
    }
});