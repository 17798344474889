export default theme => ({
    tabContent: {
        position: 'relative',
        marginBottom: 36,
        '& header': {
            zIndex: 1,
            position: 'relative',
            marginBottom: -1,
            width: 'calc(100% + 36px)',
            marginLeft: -18,
            marginRight: -18,
            [theme.breakpoints.up('md')]: {
                width: '100%',
                marginLeft: 0,
                marginRight: 0,
            }
        },
        '& .MuiTabs-scrollButtonsDesktop': {
            display: 'inline-flex !important',
        },
        '& .MuiAppBar-colorDefault': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '& .MuiTab-textColorSecondary': {
                color: theme.palette.primary.main,
                fontSize: 14,
                letterSpacing: '0.04em',
                lineHeight: 1.5,
                padding: '15px 20px',
                maxWidth: 'none',
                width: 'auto',
                [theme.breakpoints.up('md')]: {
                    fontSize: 18,
                    padding: '16px 25px',
                },
                '&.Mui-selected': {
                    color: theme.palette.secondary.main,
                    backgroundColor: 'white',
                    boxShadow: '0 3px 32px rgba(0,0,0,0.13)',
                }
            },
        },
        '& .MuiBox-root': {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 50,
            paddingBottom: 100,
            position: 'relative',
            '&::before': {
                position: 'absolute',
                top: 0,
                bottom: 0,
                width: '100vw',
                left: '50%',
                transform: 'translateX(-50vw)',
                backgroundColor: 'white',
                content: '""',
                [theme.breakpoints.up(1300)]: {
                    width: 'calc(100vw - 100px)',
                    transform: 'translateX(-50%)',
                }
            }
        },
    },
    tabContentTheme: {
        ...theme.tabContentTheme,
    }
})