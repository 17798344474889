import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";

export const CoreSpacerBlock = (props) => {
  // console.log(props);
  return (
      <div
          className={props.attributes.className ? props.attributes.className : ''}
          style={{height: props.attributes.heightPx}}
      />
  )
};

CoreSpacerBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

// NOTE: there is a type issue on the height field so using alias avoids the problem
export const CoreSpacerBlockFragment = graphql`
  fragment CoreSpacerBlock on WpBlock {
    ... on WpCoreSpacerBlock {
      name
      attributes {
        __typename
        ... on WpCoreSpacerBlockAttributes {
          heightPx: height
          className
        }
      }
    }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CoreSpacerBlockPreview = `
... on CoreSpacerBlock {
    name
    attributes {
      __typename
      ... on CoreSpacerBlockAttributes {
        heightPx: height
        className
      }
    }
  }
`;  