export const queryObjectCourses = (param = "", next = "") => {
  return {
    query: `
      {
        courses(first: 1000, where: {search: "${param}"}, after: "${next}") {
          edges {
            node {
              id
              databaseId
              title
              uri
              courses {
                type
                length
                places {
                  ... on Place {
                    title
                  }
                }
                provider {
                  name
                  slug
                  courseProviderInfo {
                    logo {
                      sourceUrl(size: MEDIUM)
                    },
                  }
                }
              }
              courseCategories {
                nodes {
                  name
                  slug
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  };
};

export const queryObjectCourseDates = (next = "") => {
  return {
    query: `
    {
      courseDates(first: 1000, after: "${next}") {
        edges {
          node {
            id
            courseData {
              startDate
              course {
                ... on Course {
                  id
                  databaseId
                }
              }
              place {
                ... on Place {
                  title
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `,
  };
};
