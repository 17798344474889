export default theme => ({
    videoTestimonial: {
        position: 'relative',
        marginBottom: 36,
        '& .video': {
            width: 'calc(100% + 34.8px)',
            margin: '0 -17.4px',
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                margin: '0 0 36px 50%',
                transform: 'translateX(-50vw)',
                width: '100vw',
            },
            '& .video-wrapper': {
                paddingBottom: '56% !important',
                width: '100%',
                '& iframe': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100% !important',
                    height: '100% !important',
                },
            },
        },
        '& .testimonial-quote': {
            fontStyle: 'italic',
            fontSize: 24,
            lineHeight: '1.8',
            letterSpacing: '0.04em',
            margin: '0',
            marginBottom: 30,
            [theme.breakpoints.up('md')]: {
                fontSize: 48,
                lineHeight: 1.5,
            },
            '&::before': {
                content: '\'“\'',
                display: 'inline-block',
                width: 66,
                height: 45,
                verticalAlign: 'text-bottom',
                transformOrigin: '57% 26%',
                textAlign: 'center',
                transform: 'scale(5.5)',
                fontSize: 24,
                [theme.breakpoints.up('md')]: {
                    fontSize: 24,
                    transform: 'scale(6.5)',
                    transformOrigin: '56% 20%',
                },
            }
        },
    },
    videoTestimonialTheme: {
        ...theme.videoTestimonialTheme,
    }
})