import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Link from '../../Link';
import styles from './styles';
import Img from 'gatsby-image';

const useStyles = makeStyles(theme => styles(theme));

export const CgbImageLinkBlock = (props) => {
    const classes = useStyles();

    let image = null;

    // Use the gatsby image if available
    if(props.attributes.localFile){
        if(props.attributes.localFile.childImageSharp){
            image = <Img
                className="desktop-image"
                fluid={props.attributes.localFile.childImageSharp.fluid}
                alt=""
            />
        } else {
            // Gifs do not have a childImageSharp
            image = <img
                className="desktop-image"
                src={props.attributes.localFile.publicURL}
                alt=""
            />
        }
    } else {
        // This is a preview image
        image = <div className="gatsby-image-wrapper desktop-image">
            <div style={{paddingBottom: '55%'}} />
            <img
                src={process.env.GATSBY_ADMIN_URL + props.attributes.mediaUrl}
                alt=""
                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
            />
        </div>
    }

    return <Link to={props.attributes.linkURL} className={`image-link ${props.attributes.className ? props.attributes.className : ''} ${classes.imageLinkSliderInner} ${classes.imageLinkSliderInnerTheme}`} >
        {image}
        <div className="text-wrap">
            <span className="text">{props.attributes.text}</span>
            <span className="sub-text">{props.attributes.subText}</span>
        </div>
    </Link>
};

CgbImageLinkBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CgbImageLinkBlockFragment = graphql`
    fragment CgbImageLinkBlock on WpBlock {
        ... on WpCgbImageLinkBlock {
            name
            attributes {
                __typename
                ... on WpCgbImageLinkBlockAttributes {
                    className
                    subText
                    text
                    linkURL
                    mediaUrl
                    localFile {
                        publicURL
                        childImageSharp {
                            fluid(quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbImageLinkBlockPreview = `
    ... on CgbImageLinkBlock {
        name
        attributes {
            __typename
            ... on CgbImageLinkBlockAttributes {
                className
                subText
                text
                linkURL
                mediaUrl
            }
        }
    }
`;