import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
import { makeStyles } from "@material-ui/styles";

import { CoreHeadingBlock } from "../CoreHeadingBlock";
import Results from "./results";
import Link from "../../Link";

import {
  setCourseName,
  setCourseCategory,
  setCourseType,
  setCourseProvider,
  setSearchPlaces,
} from "../../../app/actions";
import styles from "./styles";
import { queryObjectCourses, queryObjectCourseDates } from "./queries";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => styles(theme));

const mapStateToProps = (state) => ({
  name: state.CourseSearchSettings.name,
  category: state.CourseSearchSettings.category,
  type: state.CourseSearchSettings.type,
  provider: state.CourseSearchSettings.provider,
  searchPlaces: state.CourseSearchSettings.searchPlaces,
});

const mapDispatchToProps = (dispatch) => ({
  setCourseName: (name) => dispatch(setCourseName(name)),
  setCourseCategory: (category) => dispatch(setCourseCategory(category)),
  setCourseType: (type) => dispatch(setCourseType(type)),
  setCourseProvider: (provider) => dispatch(setCourseProvider(provider)),
  setSearchPlaces: (searchPlaces) => dispatch(setSearchPlaces(searchPlaces)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const CgbCourseSearch = ({
  name,
  category,
  type,
  provider,
  searchPlaces,
  setCourseName,
  setCourseCategory,
  setCourseType,
  setCourseProvider,
  setSearchPlaces,
  attributes,
  pageContext,
}) => {
  const classes = useStyles();

  const categories = useStaticQuery(graphql`
    query Categories {
      allWpCourseCategory {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
      allWpCourseProvider {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `);

  const [searching, setSearching] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [courses, setCourses] = useState(null);
  const [courseDates, setCourseDates] = useState([]);
  const [coursePlaces, setCoursePlaces] = useState([]);
  const [courseDatePlaces, setCourseDatePlaces] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const nameFromParams = params.get("search");
    const categoryFromParams = params.get("category");

    if (nameFromParams) {
      setCourseName(nameFromParams);
    }

    if (categoryFromParams) {
      setCourseCategory(categoryFromParams);
    }

    if (categoryFromParams && !nameFromParams) {
      setCourseName("");
    }

    // @todo params for places

    Promise.all([
      fetchCourses({
        name: nameFromParams ? nameFromParams : "",
        category: categoryFromParams ? categoryFromParams : category,
      }),
      fetchCourseDates({}),
    ]).then((responses) => {
      filterCourses(responses[0], responses[1]);
      setSearching(false);
    });

    if (nameFromParams || categoryFromParams) {
      scrollTo(".course-search");
    }
  }, [pageContext]); //eslint-disable-line

  function handleSubmit(e) {
    if (e) e.preventDefault();

    setCourses([]);
    setSearching(true);
    
    Promise.all([
      fetchCourses({ name, category, next: "", prevData: [] }),
      fetchCourseDates({}),
    ]).then((responses) => {
      filterCourses(responses[0], responses[1]);
      setSearching(false);
    });
    setPage(0);
  }

  function handleReset(e) {
    if (e) e.preventDefault();
    setCourseName("");
    setCourseCategory("all");
    setSearchPlaces([]);
    setCourseProvider("all");
  }

  function fetchCourses({
    next = "",
    prevData = [],
    name = "",
    category = "",
    coursePlaces = [],
  }) {
    setSearching(true);
    return axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        queryObjectCourses(name, next),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return {
          courses: res.data.data.courses.edges.map((node) => node.node),
          next: res.data.data.courses.pageInfo.endCursor,
          hasNextPage: res.data.data.courses.pageInfo.hasNextPage,
        };
      })
      .then((data) => {
        data = {
          ...data,
          courses: data.courses.map((course) => {
            if (course.courses.places === null) {
              course.courses.places = [];
            } else {
              course.courses.places = course.courses.places.map((place) => {
                // add place to list
                if (coursePlaces.indexOf(place.title) === -1) {
                  coursePlaces.push(place.title);
                }
                return place.title;
              });
            }
            return course;
          }),
        };

        return data;
      })
      .then((data) => {
        return {
          courses: data.courses.filter((course) => {
            if (
              category !== "all" &&
              !course.courseCategories.nodes
                .map((n) => n.slug)
                .includes(category)
            )
              return false;
            if (provider !== "all" && course.courses.provider.slug !== provider)
              return false;
            return course;
          }),
          next: data.next,
          hasNextPage: data.hasNextPage,
        };
      })
      .then((data) => {
        return {
          courses: data.courses.sort((a, b) => a.title.localeCompare(b.title)),
          next: data.next,
          hasNextPage: data.hasNextPage,
        };
      })
      .then((data) => {
        const concatData = prevData.concat(data.courses);
        if (data.hasNextPage) {
          return fetchCourses({
            next: data.next,
            prevData: concatData,
            name,
            category,
            coursePlaces: coursePlaces.concat(data.coursePlaces),
          });
        } else {
          setCourses(concatData);
          setCoursePlaces(coursePlaces);
        }
        return concatData;
      });
  }

  function fetchCourseDates({
    next = "",
    prevData = [],
    courseDatePlaces = []
  }) {
    return axios
      .post(
        `${process.env.GATSBY_ADMIN_URL}graphql`,
        queryObjectCourseDates(next),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return {
          courseDates: res.data.data.courseDates.edges.map((node) => node.node),
          next: res.data.data.courseDates.pageInfo.endCursor,
          hasNextPage: res.data.data.courseDates.pageInfo.hasNextPage,
        };
      })
      .then((data) => {
        data.courseDates.map(courseDate => {
          const courseDatePlace = courseDate.courseData?.place?.title;
          if (courseDatePlace !== undefined) {
            if (courseDatePlaces.indexOf(courseDatePlace) === -1) {
              courseDatePlaces.push(courseDatePlace);
            }
          }
        });
        return data;
      })
      .then((data) => {
        return {
          ...data,
          courseDates: data.courseDates.filter(
            (courseDate) => courseDate.courseData.course !== null
          ),
        };
      })
      .then((data) => {
        const concatData = prevData.concat(data.courseDates);
        if (data.hasNextPage) {
          return fetchCourseDates({
            next: data.next,
            prevData: concatData,
            courseDatePlaces: courseDatePlaces
          });
        } else {
          setCourseDates(concatData);
          setCourseDatePlaces(courseDatePlaces);
        }
        return concatData;
      });
  }

  function filterCourses(courses, courseDates) {
    let matchingCourses = [];

    if (courses && courses.length && courseDates && courseDates.length && !searching) {
      if (searchPlaces.length) {
        setFiltering(true);
        let filterPlaces = [...searchPlaces];
        let nationwideSearch = filterPlaces.indexOf("Nationwide");
        if (nationwideSearch === -1) {
          nationwideSearch = false;
        } else {
          filterPlaces.splice(nationwideSearch, 1);
          nationwideSearch = true;
        }

        // let onlineSearch = false;
        // if (filterPlaces.indexOf("eLearning") !== -1 || filterPlaces.indexOf("Virtual Classroom") !== -1) {
        //   filterPlaces.splice(onlineSearch, 1);
        //   onlineSearch = true;
        // }
        // console.log('online search is', onlineSearch)

        const courseHasMatchingPlaceInCourseDates = function (course, courseDates, filterPlaces) {
          const matchingCourseDates = courseDates.filter(
            (courseDate) => {
              // only search course dates that belong to this course
              if (courseDate.courseData.course.id !== course.id) {
                return false;
              }
              if (!courseDate.courseData.place || filterPlaces.indexOf(courseDate.courseData.place.title ) === -1) {
                return false;
              }
              // Must be in the filterPlaces to get here, check the date is in the future
              if (parseInt(courseDate.courseData.startDate) < now) {
                // In the past
                return false;
              }
              return true;
            }
          );
          return matchingCourseDates && matchingCourseDates.length > 0;
        }
        
        let now = new Date();
        now = parseInt(
          "" +
          now.getFullYear() +
          ("0" + (now.getMonth() + 1)).slice(-2) +
          now.getDate()
        );
        matchingCourses = courses.filter((course) => {
          for (let index = 0; index < course.courses.places.length; index++) {
            const element = course.courses.places[index];
            switch (element) {
              case "Nationwide":
                course.weight = 1;

                if (nationwideSearch) {
                  return true;
                }
                continue;
              // case "eLearning":
              // case "Virtual Classroom":
              //   course.weight = 1;
              //   return onlineSearch;
              default:
                let placeInFilter = filterPlaces.indexOf(element);
                if (placeInFilter === -1) {
                  // place is not in filter
                    course.weight = 0;
                    continue;
                }

                course.weight = 2;
                return true;
            }
          }

          // Check if there are any courseDates matching the searched place
          if (filterPlaces.length > 0) {
            if (courseHasMatchingPlaceInCourseDates(course, courseDates, filterPlaces)) {
              return true;
            }
          }

          return false;
        });
        setFiltering(false);
      } else {
        // no places filter
        matchingCourses = [...courses];
      }
    } else {
    }

    matchingCourses =
      matchingCourses &&
      matchingCourses.sort((courseA, courseB) => {
        if (courseA.weight === courseB.weight) {
          return 0;
        }
        return courseA.weight < courseB.weight ? 1 : -1;
      });
    setFilteredCourses(matchingCourses);
  }

  const pagedResults = filteredCourses.slice(0, page * 10 + 10);

  let placeOptions = coursePlaces.filter((place) => place !== "Nationwide" && place !== "eLearning" && place !== "Virtual Classroom");
  courseDatePlaces.map(courseDatePlace => {
    if (placeOptions.indexOf(courseDatePlace) === -1) {
      placeOptions.push(courseDatePlace);
    }
  });
  placeOptions = placeOptions.sort((a, b) => a.localeCompare(b));

  placeOptions = ["Nationwide", "eLearning", "Virtual Classroom", " ", ...placeOptions];

  return (
    <section className={`${attributes.className ? attributes.className : ""}`}>
      <div className={classes.courseForm}>
        <CoreHeadingBlock
          attributes={{
            align: "",
            anchor: "",
            className: "",
            content: "All courses",
            level: 2,
            textColor: "foreground_primary",
            backgroundColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="name">
            <label htmlFor="name">Course Name</label>
            <input
              name="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setCourseName(e.target.value)}
            />
          </div>
          <div className="category">
            <label htmlFor="category">Course Category</label>
            <select
              name="category"
              value={category}
              onChange={(e) => setCourseCategory(e.target.value)}
            >
              <option value="all">All</option>
              {categories.allWpCourseCategory.edges.map((category) => {
                return (
                  <option key={category.node.id} value={category.node.slug}>
                    {category.node.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="places">
            <label htmlFor="places">Location</label>
            <Autocomplete
              multiple
              disableRipple
              className="placesAutocomplete"
              id="places-filter"
              options={placeOptions}
              getOptionDisabled={(option) =>
                option === " "
              }
              name="places"
              value={searchPlaces}
              onChange={(e, values) => setSearchPlaces(values)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  disableRipple
                  placeholder={searchPlaces.length ? "" : "All"}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    style: {
                      color: "red",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      color: "black",
                    },
                  }}
                />
              )}
            ></Autocomplete>
          </div>
          <div className="provider">
            <label htmlFor="provider">Provider</label>
            <select
              name="provider"
              value={provider}
              onChange={(e) => setCourseProvider(e.target.value)}
            >
              <option value="all">All</option>
              {categories.allWpCourseProvider.edges.map((provider) => {
                return (
                  <option key={provider.node.id} value={provider.node.slug}>
                    {provider.node.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="actions">
            <input type="submit" value="search" />
            <button onClick={(e) => handleReset(e)}>Reset</button>
          </div>
        </form>
      </div>

      {(searching || filtering || courseDates.length === 0) && (
        <p className={classes.searching}>Searching...</p>
      )}

      {searching === false &&
        filteredCourses &&
        courseDates &&
        courseDates.length > 0 &&
        !filteredCourses.length && (
          <div>
            <p>Your search hasn’t matched any of our available courses.</p>
            <p>Get in touch with the experts at Workshop Pro today.</p>
            <Link to="/contact-us/" className={classes.cta}>
              Contact Us
            </Link>
          </div>
        )}

      {searching === false && filteredCourses && filteredCourses.length > 0 && (
        <Results
          courses={pagedResults}
          courseDates={courseDates}
          classes={classes}
        />
      )}

      {searching === false &&
        filteredCourses &&
        filteredCourses.length !== pagedResults.length && (
          <div className={classes.loadMore}>
            <button onClick={(e) => setPage(page + 1)}>Load more</button>
          </div>
        )}
    </section>
  );
};

CgbCourseSearch.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CgbCourseSearchBlock = connector(CgbCourseSearch);

export const CgbCourseSearchBlockFragment = graphql`
  fragment CgbCourseSearchBlock on WpBlock {
    ... on WpCgbCourseSearchBlock {
      name
      attributes {
        className
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbCourseSearchBlockPreview = `
    ... on CgbCourseSearchBlock {
        name
        attributes {
          className
        }
    }
`;
