import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import Grid from '@material-ui/core/Grid';
import Slider from "react-slick/lib";
import Img from "gatsby-image";
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbIntroImageSliderBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }

    return <div className={`intro-image-slider ${props.attributes.className ? props.attributes.className : ''} ${classes.introImageSlider} ${classes.introImageSliderTheme}`}>
        <Grid container spacing={3} direction="row" justiy="center" alignItems="center">
            <Grid item xs={12} md={7} lg={6} className='con-wrap'>
                {blocks[0]}
                {blocks[1]}
                {props.attributes.hasButton === true &&
                    blocks[2]
                }
            </Grid>
        </Grid>
        <Slider {...settings}>
            { blocks[3].props.attributes.images.map((image) => {
                if(image.fullUrlSharp){
                    if(image.fullUrlSharp.childImageSharp){
                        return <div className="image-slide" key={image.id}>
                            <Img
                                fluid={image.fullUrlSharp.childImageSharp.fluid}
                                alt={image.alt ? image.alt : ''}
                            />
                        </div>
                    } else {
                        // Gifs do not have a childImageSharp
                        return <div className="image-slide" key={image.id}>
                            <img
                                src={image.fullUrlSharp.publicURL}
                                alt={image.alt ? image.alt : ''}
                            />
                        </div>
                    }
                } else {
                    return <div className="image-slide" key={image.id}>
                        <div className="gatsby-image-wrapper">
                            <div style={{ paddingBottom: '50%' }} />
                            <img
                                src={image.url}
                                alt={image.alt ? image.alt : ''}
                                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
                            />
                        </div>
                    </div>
                }
            })}
        </Slider>
    </div>
};

export const CgbTwoColIntroBlockFragment = graphql`
  fragment CgbIntroImageSliderBlock on WpBlock {
    ... on WpCgbIntroImageSliderBlock {
        name
        attributes {
            className
            hasButton
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbIntroImageSliderBlockPreview = `
    ... on CgbIntroImageSliderBlock {
        name
        attributes {
            className
            hasButton
        }
    }
`;