export default theme => ({
    featuredIconsInner: {
        position: 'relative',
        '& [class*=" MuiTypography-h"]': {
            color: theme.palette.primary.main,
        },
        '& p': {
            paddingRight: 32,
        },
        '& .gatsby-image-wrapper': {
            width: 56,
            margin: 0,
            [theme.breakpoints.up('md')]: {
                margin: '0 auto 12px',
            }
        }
    },
    featuredIconsInnerTheme: {
        ...theme.featuredIconsInnerTheme,
    }
})