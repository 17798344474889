import React from "react";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import Grid from "@material-ui/core/Grid/Grid";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbFeaturedIconsBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();

  return (
    <div
      className={`featured-icons ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.featuredIcons} ${classes.featuredIconsTheme}`}
    >
      <Grid container spacing={3} direction="row">
        {blocks.map((block, index) => {
          return (
            <Grid key={index} item xs={12} sm={6} md={3}>
              {block}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export const CgbFeaturedIconsBlockFragment = graphql`
  fragment CgbFeaturedIconsBlock on WpBlock {
    ... on WpCgbFeaturedIconsBlock {
      name
      attributes {
        className
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbFeaturedIconsBlockPreview = `
    ... on CgbFeaturedIconsBlock {
        name
        attributes {
            className
        }
    }
`;
