import axios from "axios";

import {
  queryCourseDates,
  queryCourseCategories,
  queryCourseProviders,
  queryCourseLocations,
} from "./queries";

const apiUrl = `${process.env.GATSBY_ADMIN_URL}graphql`;

const headers = {
  "Content-Type": "application/json",
};

export function fetchCourseDates(
  next = "",
  prevData = [],
  startDate = 0,
  endDate = 20300101,
  courseDatabaseId = ""
) {
  return axios
    .post(
      apiUrl,
      queryCourseDates(next, startDate, endDate, courseDatabaseId),
      {
        headers,
      }
    )
    .then((res) => {
      const next = res.data.data.courseDates.pageInfo.endCursor;
      const hasNextPage = res.data.data.courseDates.pageInfo.hasNextPage;
      const data = [...res.data.data.courseDates.edges, ...prevData];
      if (hasNextPage) {
        return fetchCourseDates(
          next,
          data,
          startDate,
          endDate,
          courseDatabaseId
        );
      } else {
        return data;
      }
    });
}

export function fetchCourseCategories(next = "", prevData = []) {
  return axios
    .post(apiUrl, queryCourseCategories(next), {
      headers,
    })
    .then((res) => {
      const next = res.data.data.courseCategories.pageInfo.endCursor;
      const hasNextPage = res.data.data.courseCategories.pageInfo.hasNextPage;
      const data = [...res.data.data.courseCategories.edges, ...prevData];

      if (hasNextPage) {
        return fetchCourseCategories(next, data);
      } else {
        return data;
      }
    });
}

export function fetchCourseLocations() {
  return axios
    .post(apiUrl, queryCourseLocations(), {
      headers,
    })
    .then((res) => {
      return [...res.data.data.courseLocations];
    });
}

export function fetchCourseProviders(next = "", prevData = []) {
  return axios
    .post(apiUrl, queryCourseProviders(next), {
      headers,
    })
    .then((res) => {
      const next = res.data.data.courseProviders.pageInfo.endCursor;
      const hasNextPage = res.data.data.courseProviders.pageInfo.hasNextPage;
      const data = [...res.data.data.courseProviders.edges, ...prevData];

      if (hasNextPage) {
        return fetchCourseProviders(next, data);
      } else {
        return data;
      }
    });
}

export function fetchCourseTypes(next = "", prevData = []) {}
