import React, { useState } from "react";
import scrollTo from "gatsby-plugin-smoothscroll";

import { makeStyles } from "@material-ui/styles";
import styles from "./styles";
import Link from "../Link";

import logo from "../../images/tower-2019-logo-RBG.png";

const useStyles = makeStyles((theme) => styles(theme));

export default function FinanceCalculator() {
  const classes = useStyles();

  const [cost, setCost] = useState(1000);
  const [years, setYears] = useState("Over 3 Years");
  const [term, setTerm] = useState(60);
  const [tax, setTax] = useState(19);

  function calculateMonthlyPayments() {
    if (years === "Under 3 Years") {
      return (39.89 * cost) / 1000;
    }

    if (term === 36) {
      return (35.35 * cost) / 1000;
    }

    if (term === 48) {
      return (27.66 * cost) / 1000;
    }

    if (term === 60) {
      return (23.08 * cost) / 1000;
    }
  }

  function toPrice(n) {
    return Math.round(n * 100) / 100;
  }

  function calculateSavings() {
    return (calculateMonthlyPayments() * tax) / 100;
  }

  function onFormSubmit(e) {
    e.preventDefault();

    scrollTo(".results");
  }

  return (
    <div className={`finance-calculator ${classes.financeCalc}`}>
      <form onSubmit={(e) => onFormSubmit(e)}>
        <h2>Calculator</h2>
        <div>
          <label htmlFor="total">Total equipment cost (ex-vat)</label>
          <input
            name="total"
            placeholder="£20,957"
            value={cost}
            onBlur={(e) => setCost(Number(e.target.value))}
          />
        </div>

        <div>
          <label htmlFor="years">Years trading</label>
          <select
            name="years"
            onBlur={(e) => setYears(e.target.value)}
            value={years}
          >
            <option value="Over 3 Years">Over 3 years</option>
            <option value="Under 3 Years">Under 3 years</option>
          </select>
        </div>

        <div>
          <label htmlFor={"term"}>Lease term</label>
          <select
            name="term"
            onBlur={(e) => setTerm(Number(e.target.value))}
            value={term}
          >
            <option value="36">36 Months (1+35)</option>
            {years === "Over 3 Years" && (
              <option value="48">48 Months (1+47)</option>
            )}
            {years === "Over 3 Years" && (
              <option value="60">60 Months (1+59)</option>
            )}
          </select>
        </div>

        <div>
          <label htmlFor="tax-band">Company tax band</label>
          <select
            name="tax-band"
            onBlur={(e) => setTax(e.target.value)}
            style={{ color: "#bbb", pointerEvents: "none" }}
          >
            <option value={19} disabled selected>
              19%
            </option>
          </select>
        </div>

        <input type="submit" value="Calculate" />
      </form>

      <div className="results">
        <div className="recap">
          <h2>Your personalized calculation</h2>

          <span className="equi-cost">
            <small>Equipment cost</small>
            <strong>£{cost}</strong>
          </span>

          <span className="years">
            <small>Years Trading</small>
            <strong>{years}</strong>
          </span>

          <span className="term">
            <small>Lease Term</small>
            <strong>
              {term} Months <i>(1+{term - 1})</i>
            </strong>
          </span>

          <span className="tax">
            <small>Tax Band</small>
            <strong>{tax}%</strong>
          </span>
        </div>

        <div className="payments">
          <div className="initial">
            <span className="h-space" />
            <h4>Initial payment</h4>
            <p className="cost">£{toPrice(calculateMonthlyPayments())}</p>
            <p>One-Off payment</p>
          </div>

          <div className="before-tax">
            <h3>Before Tax Relief</h3>
            <h4>Monthly payments</h4>
            <p className="cost">£{toPrice(calculateMonthlyPayments())}*</p>
            <p>
              {term - 1} Monthly Payments or{" "}
              <strong>
                £{toPrice((calculateMonthlyPayments() * 12) / 52)}
              </strong>
              * per week
            </p>
          </div>

          <div className="after-tax">
            <h3>After Tax Relief**</h3>
            <div>
              <h4>Monthly payments</h4>
              <p className="cost">
                £{toPrice(calculateMonthlyPayments() - calculateSavings())}**
              </p>
              <p>
                {term - 1} Monthly Payments or{" "}
                <strong>
                  £
                  {toPrice(
                    ((calculateMonthlyPayments() - calculateSavings()) * 12) /
                      52
                  )}
                </strong>
                ** per week
              </p>
            </div>
            <div>
              <h4>Monthly tax saving</h4>
              <p className="cost">{toPrice(calculateSavings())}**</p>
              <p>
                A major benefit of leasing is{" "}
                <strong>Tax Relief of 100%</strong>
              </p>
            </div>
            <p className="guidanceInfo">
              <strong>**Tax Relief is for guidance Only**</strong>
            </p>
          </div>
        </div>

        <div className="further">
          <div className="logo">
            <img src={logo} alt="Tower" />
          </div>
          <div className="link">
            <Link to="/contact-us" className="button">
              Contact us
            </Link>
          </div>
          <div className="exp">
            <p>
              <span>*</span>All figures above exclude VAT and are for Payments
              assumed by Direct Debit ONLY. Figures quoted are strictly subject
              to status and credit clearance and are for guideline purposes
              only.
            </p>
            <p>
              <span>**</span>Please Note: Tax Notice is given without liability
              and suggest for further guidance and advice to contact a
              professional advisor so as all relevant considerations are
              accounted for.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
