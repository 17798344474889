import active from "../../../images/active-cat.png";

export default theme => ({
    categoryListing: {
        position: 'relative',
        marginBottom: 36,
        '& .title': {
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                marginBottom: 50,
                alignItems: 'center',
            },
            '& .button-wrap': {
                padding: '12px 0 24px',
                [theme.breakpoints.up('md')]: {
                    padding: 0,
                },
            }
        },
        '& .category-listing': {
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems: 'flex-start',
            },
            '& .top-level': {
                display: 'flex',
                width: 'calc(100% + 34px)',
                margin: '0 -17px',
                flexWrap: 'wrap',
                flexDirection: 'row',
                position: 'relative',
                zIndex: 1,
                [theme.breakpoints.up('md')]: {
                    margin: 0,
                    flex: '0 0 25%',
                    minWidth: '25%',
                },
                '& button': {
                    flex: '0 0 50%',
                    minWidth: '50%',
                    height: 100,
                    backgroundColor: theme.palette.secondary.main,
                    padding: 10,
                    fontSize: 18,
                    lineHeight: 1.1,
                    letterSpacing: '0.016em',
                    fontFamily: theme.headerFont,
                    fontWeight: 400,
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    color: theme.palette.secondary.contrastText,
                    position: 'relative',
                    border: 'none',
                    [theme.breakpoints.up('md')]: {
                        flex: '0 0 100%',
                        minWidth: '100%',
                        height: 250,
                        fontSize: 32,
                        padding: 32,
                        lineHeight: 1,
                        textAlign: 'left',
                    },
                    '& .gatsby-image-wrapper': {
                        position: 'absolute !important',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 0,
                        transition: 'opacity .3s ease-in-out',
                    },
                    '& span': {
                        position: 'absolute',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1,
                        left: '50%',
                        maxWidth: 'calc(100% - 20px)',
                        [theme.breakpoints.up('md')]: {
                            width: 'calc(100% - 64px)',
                        }
                    },
                    '&::before': {
                        content: '""',
                        width: 88,
                        height: 26,
                        position: 'absolute',
                        backgroundImage: 'url('+active+')',
                        left: 'calc(50% - 45.5px)',
                        zIndex: -1,
                        bottom: 0,
                        transition: 'bottom .3s ease-in-out',
                        [theme.breakpoints.up('md')]: {
                            bottom: 'auto',
                            top: '50%',
                            left: 'auto',
                            right: -30,
                            transform: 'translateY(-50%) rotate(-90deg)',
                        }
                    },
                    '&[open]': {
                        '& .gatsby-image-wrapper': {
                            opacity: 0,
                        },
                        '&::before': {
                            bottom: -25,
                            [theme.breakpoints.up('md')]: {
                                right: -55,
                            }
                        }
                    }
                }
            },
            '& .children': {
                [theme.breakpoints.up('md')]: {
                    margin: '0 6.25%',
                    flex: '0 0 62.5%',
                    minWidth: '62.5%',
                },
                '& .inner-list': {
                    display: 'none',
                    width: 'calc(100% + 34px)',
                    margin: '0 -17px 0',
                    flexWrap: 'wrap',
                    paddingTop: 32,
                    flexDirection: 'row',
                    backgroundColor: 'white',
                    [theme.breakpoints.up('md')]: {
                        margin: 0,
                        width: '100%',
                        backgroundColor: 'transparent',
                        paddingTop: 0,
                    },
                    '&[open]': {
                        display: 'flex',
                    },
                    '& a': {
                        flex: '0 0 calc(100% - 24px)',
                        margin: ' 0 12px 12px',
                        backgroundColor: '#EDEDED',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        padding: 12,
                        color: theme.palette.primary.main,
                        textDecoration: 'none',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.08)',
                        [theme.breakpoints.up(400)]: {
                            flex: '0 0 calc(50% - 24px)',
                        },
                        [theme.breakpoints.up('lg')]: {
                            flex: '0 0 calc(33.333% - 24px)',
                            padding: '12px 24px',
                        },
                        '& img': {
                            flex: '0 0 44px',
                            height: 44,
                            [theme.breakpoints.up('md')]: {
                                flex: '0 0 72px',
                                height: 72,
                            },
                        },
                        '& span': {
                            flex: '0 0 calc(100% - 44px)',
                            paddingRight: 12,
                            fontFamily: 'Roboto Condensed',
                            fontSize: 16,
                            lineHeight: 1.5,
                            [theme.breakpoints.up('md')]: {
                                flex: '0 0 calc(100% - 72px)',
                                fontSize: 18,
                                maxWidth: 'calc(100% - 72px)',
                            },
                        }
                    }
                }
            }
        }
    },
});