
import arrowPrev from "../../../images/arrow-prev-light.png";
import arrowNext from "../../../images/arrow-next-light.png";

export default theme => ({
    introImageSlider: {
        position: 'relative',
        marginBottom: 36,
        '& button': {
            marginTop: '24px !important',
        },
        '& .slick-slider': {
            marginTop: 60,
            '& .slick-list': {
                '& .slick-track': {
                    '& .slick-slide': {
                        '& > div': {
                            '& .image-slide': {
                                paddingRight: 16,
                                [theme.breakpoints.up('md')]: {
                                    paddingRight: 32,
                                },
                                '& .gatsby-image-wrapper': {
                                    height: 'auto',
                                    width: '100%',
                                }
                            }
                        }
                    }
                }
            }
        },
        '& .slick-prev': {
            left: 24,
            zIndex: '1',
            width: 24,
            height: 24,
            backgroundSize: 'cover !important',
            backgroundColor: 'transparent !important',
            backgroundImage: 'url(' + arrowPrev + ') !important',
            '&::before': {
                display: 'none',
            }
        },
        '& .slick-next': {
            right: 40,
            width: 24,
            height: 24,
            backgroundSize: 'cover !important',
            backgroundColor: 'transparent !important',
            backgroundImage: 'url(' + arrowNext + ') !important',
            [theme.breakpoints.up('md')]: {
                right: 56,
            },
            '&::before': {
                display: 'none',
            }
        },
        '& .slick-disabled': {
            opacity: '0.25',
        }
    },
    introImageSliderTheme: {
        ...theme.introImageSliderTheme
    },
})