import React from "react";
import dateformat from "dateformat";

import {
  months,
  getMonthNameFromNumber,
  getMonthNumberFromName,
} from "./helpers";

export default function Months({
  handleMonth,
  selectedMonth = "",
  selectedYear,
}) {
  const now = new Date();

  const currentMonth = dateformat(now, "mmmm").toLowerCase();
  const currentYear = Number(dateformat(now, "yyyy"));

  return (
    <form //eslint-disable-line
      className="month-select"
      onClick={(e) => handleMonth(e)}
    >
      <button className={`${currentYear === selectedYear ? "is-active" : ""}`}>
        {currentYear}
      </button>
      {months.slice(getMonthNumberFromName(currentMonth) - 1).map((month) => {
        const slug = month;
        return (
          <span key={slug} className="month">
            <label
              name="month"
              htmlFor={slug}
              className={`${
                getMonthNameFromNumber(selectedMonth) === slug ? "active" : ""
              }`}
            >
              {month}
              <input
                type="radio"
                name="month"
                value={getMonthNumberFromName(slug)}
                year={currentYear}
              />
            </label>
          </span>
        );
      })}
      <button
        className={`${currentYear + 1 === selectedYear ? "is-active" : ""}`}
      >
        {currentYear + 1}
      </button>
      {months
        .slice(0, getMonthNumberFromName(currentMonth) - 1)
        .map((month) => {
          const slug = month;
          return (
            <span key={slug} className="month">
              <label
                name="month"
                htmlFor={slug}
                className={`${
                  getMonthNameFromNumber(selectedMonth) === slug ? "active" : ""
                }`}
              >
                {month}
                <input
                  type="radio"
                  name="month"
                  value={getMonthNumberFromName(slug)}
                  year={currentYear + 1}
                />
              </label>
            </span>
          );
        })}
    </form>
  );
}
