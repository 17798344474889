import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
import Grid from '@material-ui/core/Grid';
import {buildBlocks} from "../../../services/builder";

export const CoreColumnBlock = (props) => {
  
    const blocks = buildBlocks(props.innerBlocks, props.attributes, props.pageContext);
    let { width } = props.attributes;

    let cols = Math.floor(width / 8.3);
    if(width===null){
      cols = 12 / props.parentAttributes.childrenLength;
    }

    let xs = cols <= 6 ? cols * 2 : cols;
    let sm = cols <= 6 ? cols * 2 : cols;
    let md = cols;
    let lg = cols;
    let xl = cols;

    if(cols===12){
      xs = 12;
      sm = 12;
      md = 12;
      lg = 12;
    }

    if(cols===10){
      xs = 12;
      sm = 12;
      md = 10;
      lg = 10;
    }

    if(cols===8){
      xs = 12;
      sm = 12;
      md = 8;
      lg = 8;
    }

    if(cols===6){
      xs = 12;
      sm = 6;
      md = 6;
      lg = 6;
    }

    if(cols===4){
      xs = 6;
      sm = 6;
      md = 4;
      lg = 4;
    }

    if(cols===2){
      xs = 4;
      sm = 4;
      md = 2;
      lg = 2;
    }

    // Class overrides
    if(props.attributes.className){
      const classes = props.attributes.className.split(" ");

      if(classes.length){
        classes.map(className => {
          if(className.indexOf('col-xs-')!==-1){
            xs = className.replace('col-xs-')
          }
          if(className.indexOf('col-sm-')!==-1){
            sm = className.replace('col-sm-')
          }
          if(className.indexOf('col-md-')!==-1){
            md = className.replace('col-md-')
          }
          if(className.indexOf('col-lg-')!==-1){
            lg = className.replace('col-lg-')
          }
          if(className.indexOf('col-xl-')!==-1){
            xl = className.replace('col-xl-')
          }
          return null;
        });
      }
    }

    return <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={props.attributes.className}>
        {blocks.map((block) => {
            return block;
        })}
    </Grid>
};

CoreColumnBlock.propTypes = {
    attributes: PropTypes.object.isRequired,
};

export const CoreColumnBlockFragment = graphql`
  fragment CoreColumnBlock on WpBlock {
    ... on WpCoreColumnBlock {
      name
      attributes {
        __typename
       ... on WpCoreColumnBlockAttributes {
          className
          verticalAlignment
          width
        }
      }
    }
  }`;

  // This is the string version of this that is used within previews and without WP prefixes
export const CoreColumnBlockPreview = `
... on CoreColumnBlock {
  name
  attributes {
    __typename
   ... on CoreColumnBlockAttributes {
      className
      verticalAlignment
      width
    }
  }
}
`;