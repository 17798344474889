import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import FinanceCalculator from "../../FinanceCalculator";

export const CgbFinanceCalculatorBlock = (props) => {
  return <FinanceCalculator />;
};

CgbFinanceCalculatorBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CgbFinanceCalculatorBlockFragment = graphql`
  fragment CgbFinanceCalculatorBlock on WpBlock {
    ... on WpCgbFinanceCalculatorBlock {
      name
      attributes {
        className
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbFinanceCalculatorBlockPreview = `
    ... on CgbFinanceCalculatorBlock {
        name
        attributes {
          className
        }
    }
`;
