export default theme => ({
    imageLinkRow: {
        position: 'relative',
        marginBottom: 36,
        '&.white-bg': {
            padding: '32px 0 32px',
            '&::before': {
                zIndex: -1,
                position: 'absolute',
                left: '50%',
                width: '100vw',
                backgroundColor: 'white',
                top: 0,
                bottom: 0,
                content: '""',
                display: 'block',
                transform: 'translateX(-50%)',
            }
        }
    },
    imageLinkRowTheme: {
        ...theme.imageLinkRowTheme
    },
})