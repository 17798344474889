export default theme => ({
    blogPagePosts: {
        position: 'relative',
        paddingBottom: 50,
        [theme.breakpoints.up('md')]: {
            paddingBottom: 100,
        },
        marginBottom: 36,
        '& .page-title': {
            padding: '24px 0',
            [theme.breakpoints.up('md')]: {
                padding: '40px 0 50px',
            },
            '& .MuiTypography-h1, & .MuiTypography-h2, & .MuiTypography-h3, & .MuiTypography-h4, & .MuiTypography-h5, & .MuiTypography-h6': {
                marginBottom: 0,
                position: 'relative',
            },
        },
        '& .blog-list': {
            position: 'relative',
            zIndex: '1',
            '& .featured-item': {
                position: 'relative',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'block',
                marginBottom: 24,
                textDecoration: 'none',
                overflow: 'hidden',
                color: theme.palette.primary.contrastText,
                [theme.breakpoints.up('md')]: {    
                    width: '87.5%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                },
                '& .gatsby-image-wrapper': {
                    '& > div': {
                        paddingBottom: '265px !important',
                        [theme.breakpoints.up('md')]: {
                            paddingBottom: '485px !important',
                        },
                    }
                },
                '& .con-wrap': {
                    display: 'block',
                    padding: 30,
                    backgroundColor: theme.palette.primary.main,
                    [theme.breakpoints.up('md')]: {
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '25px 36px',
                        backgroundColor: theme.palette.primary.main,
                        backdropFilter: 'blur(5px)',
                        paddingRight: 320,
                    },
                    '& .date': {
                        fontSize: 12,
                        lineHeight: 2,
                        letterSpacing: '0.152em',
                        marginBottom: 12,
                        display: 'block',
                        opacity: .35,
                        textTransform: 'uppercase',
                        fontFamily: theme.bodyFont,
                    },
                    '& .title': {
                        display: 'block',
                        fontSize: 20,
                        lineHeight: 1.4,
                        letterSpacing: '0.008em',
                        fontFamily: theme.bodyFont,
                        [theme.breakpoints.up('md')]: {
                            fontSize: 32,
                            lineHeight: 1.5,
                        },
                    },
                    '& .excerpt': {
                        opacity: .57,
                        fontSize: 16,
                        lineHeight: 1.5,
                        letterSpacing: '0.016em',
                        fontFamily: "Overpass",
                        marginBottom: 24,
                        display: 'block',
                        [theme.breakpoints.up('md')]: {
                            marginBottom: 0,
                        }
                    },
                    '& a': {
                        display: 'table',
                        [theme.breakpoints.up('md')]: {
                            bottom: 45,
                            right: 80,
                            position: 'absolute',
                        }
                    }
                }
            },
            '& .list-item': {
                marginBottom: 24,
                backgroundColor: 'white',
                [theme.breakpoints.up('md')]: {
                    width: '87.5%',
                    margin: '0 auto 36px',
                },
                '& .gatsby-image-wrapper': {
                    overflow: 'hidden',
                    '& > div': {
                        paddingBottom: '78% !important',
                    },
                    [theme.breakpoints.up('md')]: {
                        width: '50%',
                        float: 'left',
                    }
                },
                '& .con-wrap': {
                    padding: '24px 35px',
                    [theme.breakpoints.up('md')]: {
                        width: '50%',
                        float: 'left',
                        padding: '70px 80px',
                    },
                    '& .date': {
                        fontSize: 12,
                        letterSpacing: '0.152em',
                        fontWeight: '500',
                        textTransform: 'uppercase',
                        margin: 0,
                        marginBottom: 10,
                        fontFamily: theme.bodyFont,
                        opacity: .24,
                    },
                    '& h2': {
                        color: theme.palette.secondary.main,
                        fontSize: 24,
                        lineHeight: 1.4,
                        marginBottom: 16,
                        marginTop: 0,
                        fontWeight: 'normal',
                        fontFamily: theme.bodyFont,
                    },
                    '& p': {
                        fontSize: 14,
                        lineHeight: 1.5,
                        marginBottom: 44,
                        letterSpacing: '0.016em',
                        opacity: .5,
                        fontFamily: "Overpass",
                        [theme.breakpoints.up('md')]: {
                            fontSize: 16,
                        },
                    },
                    '& .cta': {
                        fontSize: 16,
                        lineHeight: 1.5,
                        display: 'inline-block',
                        marginTop: 16,
                        padding: '15px 50px',
                        borderRadius: 6,
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        fontWeight: '500',
                        boxShadow: '0 4px 6px rgba(0,0,0,0.25)',
                        transition: 'background-color .3s ease-in-out',
                        '&:hover, &:focus': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: 17,
                        }
                    }
                },
                '&::after': {
                    clear: 'both',
                    display: 'table',
                    content: '""',
                }
            }
        },
        '& ul.MuiPagination-ul': {
            justifyContent: 'center',
            backgroundColor: 'white',
            '& li': {
                '& button': {
                    fontSize: 18,
                    fontWeight: 800,
                    margin: '0 10px',
                    borderRadius: 0,
                    transition: 'opacity .3s ease-in-out',
                    backgroundColor: 'transparent !important',
                    color: theme.palette.common.black,
                    fontFamily: theme.headerFont,
                    '&:hover, &:focus': {
                        opacity: '.8',
                    },
                    '& span': {
                        display: 'none',
                    },
                    '&.Mui-selected': {
                        color: theme.palette.secondary.contrastText,
                        backgroundColor: theme.palette.secondary.main + ' !important',
                        width: 58,
                        height: 58,
                        paddingTop: 0,
                    }
                }
            },
            [theme.breakpoints.up('md')]: {
                width: '87.5%',
                margin: '36px auto',
            }
        }
    },
    blogPagePostsTheme: {
        ...theme.blogPagePostsTheme,
    }
})