
import nextArrow from "../../../images/arrow-next-light.png";
import prevArrow from "../../../images/arrow-prev-light.png";

export default theme => ({
    nextPrev: {
        position: 'relative',
        marginBottom: 36,
        '&::after': {
            clear: 'both',
            display: 'table',
            content: '""',
        },
        '& .back, & .next': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            fontSize: 26,
            fontFamily: theme.headerFont,
            letterSpacing: '0.008em',
            padding: '15px 15px 44px',
            borderRadius: 6,
            lineHeight: '1',
            boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
            textDecoration: 'none',
            display: 'inline-flex',
            backgroundSize: 24,
            backgroundPosition: 'bottom 15px left 15px',
            backgroundRepeat: 'no-repeat',
            width: 'calc(50% - 12px)',
            height: 125,
            maxWidth: 206,
            marginRight: 24,
            alignItems: 'flex-end',
            verticalAlign: 'bottom',
            '&:hover, &:focus': {
                backgroundColor: theme.palette.secondary.dark,
            },
            '& .next': {
                marginRight: 0,
            }
        },
        '& .back': {
            backgroundImage: "url(" + prevArrow + ")",
        },
        '& .next': {
            backgroundImage: "url(" + nextArrow + ")",
        }
    },
    nextPrevTheme: {
        ...theme.nextPrevTheme,
    }
})