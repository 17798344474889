import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import { buildBlocks } from "../../../services/builder";
import Slider from "react-slick/lib";
import styles from "./styles";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbBannerMessageSliderBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.parentAttributes,
    props.pageContext
  );
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    centerMode: false,
    // lazyLoad: true,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: false,
    // focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: true,
  };

  return (
    <div
      className={`banner-message-slider ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.bannerSlider} ${classes.bannerSliderTheme}`}
    >
      <Slider {...settings}>
        {blocks.map((block) => {
          return block;
        })}
      </Slider>
    </div>
  );
};

CgbBannerMessageSliderBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CgbBannerMessageSliderBlockFragment = graphql`
  fragment CgbBannerMessageSliderBlock on WpBlock {
    ... on WpCgbBannerMessageSliderBlock {
      name
      attributes {
        __typename
        ... on WpCgbBannerMessageSliderBlockAttributes {
          className
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbBannerMessageSliderBlockPreview = `
    ... on CgbBannerMessageSliderBlock {
        name
        attributes {
            __typename
            ... on CgbBannerMessageSliderBlockAttributes {
                className
            }
        }
    }
`;
