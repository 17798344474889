export default theme => ({
    imageBreakdownInner: {
        position: 'relative',
        '& h1, & h2, & h3, & h4, & h5, & h6' : {
            marginBottom: 15,
        },
        '& p' : {
            marginBottom: 34,
        },
    },
    imageBreakdownInnerTheme: {
        ...theme.imageBreakdownInnerTheme,
    }
})