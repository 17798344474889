export default theme => ({
    contactRow: {
        position: 'relative',
        marginBottom: 36,
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        '& .map': {
            flex: '0 0 100%',
            minWidth: '100%',
            height: 250,
            position: 'relative',
            overflow: 'hidden',
            '& .gatsby-image-wrapper': {
                position: 'absolute !important',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            },
            [theme.breakpoints.up('lg')]: {
                flex: '0 0 33.333%',
                minWidth: '33.333%',
                height: 'initial',
            }
        },
        '& .con-wrap': {
            flex: '0 0 100%',
            minWidth: '100%',
            padding: 32,
            [theme.breakpoints.up('md')]: {
                flex: '0 0 50%',
                minWidth: '50%',
            },
            [theme.breakpoints.up('lg')]: {
                flex: '0 0 33.333%',
                minWidth: '33.333%',
                padding: 64,
            }
        },
        '& .form-wrap': {
            flex: '0 0 100%',
            minWidth: '100%',
            padding: 32,
            [theme.breakpoints.up('md')]: {
                flex: '0 0 50%',
                minWidth: '550%',
                padding: 64,
            },
            [theme.breakpoints.up('lg')]: {
                flex: '0 0 33.333%',
                minWidth: '33.333%',
                padding: 64,
            }
        },
        '& h2': {
            marginBottom: 24,
            marginTop: 0,
            color: theme.palette.secondary.main,
            textTransform: 'uppercase',
            fontWeight: 400,
            lineHeight: 1,
            fontSize: 24,
            letterSpacing: '0.016em',
        },
        '& h3': {
            color: theme.palette.secondary.main,
            fontSize: 16,
            lineHeight: 1.5,
            letterSpacing: '0.016em',
            fontWeight: 400,
            marginTop: 0,
            marginBottom: 5,
            textTransform: 'uppercase',
            '& svg': {
                width: 16,
                height: 16,
                marginRight: 5,
                display: 'inline-block',
                verticalAlign: 'middle',
            }
        },
        '& p': {
            color: theme.palette.primary.contrastText,
            fontSize: 16,
            fontFamily: "Overpass",
            letterSpacing: '0.016em',
            lineHeight: 1.5,
            marginBottom: 24,
            marginTop: 0,
            '& a': {
                color: theme.palette.primary.contrastText,
                textDecoration: 'none',
                fontWeight: 'bold',
                transition: 'color .3s ease-in-out',
                '&:hover, &:focus': {
                    color: theme.palette.secondary.main,
                }
            }
        },
        '& .socials': {
            marginTop: 24,
            '& a': {
                color: theme.palette.primary.contrastText,
                fontSize: 16,
                fontFamily: "Overpass",
                letterSpacing: '0.016em',
                lineHeight: 1.5,
                margin: 0,
                fontWeight: 'bold',
                display: 'block',
                textDecoration: 'none',
                marginBottom: 5,
                transition: 'color .3s ease-in-out',
                '&:hover, &:focus': {
                    color: theme.palette.secondary.main,
                },
                '& svg': {
                    width: 25,
                    height: 25,
                    marginRight: 15,
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    '& *': {
                        fill: theme.palette.secondary.main,
                    }
                }
            }
        }
    },
});