import slickDot from "../../../images/slick-dot.png";
import slickActive from "../../../images/slick-active.png";

export default (theme) => ({
  topBrands: {
    position: "relative",
    paddingTop: 50,
    paddingBottom: 50,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      paddingTop: 140,
      paddingBottom: 140,
      flexWrap: "wrap",
      flexDirection: "row",
      width: "calc(100% + 24px)",
      margin: "0 -12px",
    },
    "& h2": {
      [theme.breakpoints.up("md")]: {
        flex: "0 0 25%",
        maxWidth: "25%",
        padding: "0 12px",
      },
    },
    "& .slick-slider": {
      position: "relative",
      width: "calc(100% + 24px)",
      margin: "0 -12px",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 calc(75% + 24px)",
        maxWidth: "calc(75% + 24px)",
        padding: "0 12px",
      },
      "& .slick-slide": {
        padding: "0 12px 12px",
        "& a": {
          border: "20px solid white",
          position: "relative",
          color: "white",
          boxShadow: "0 4px 8px rgba(0,0,0,0.08)",
          transition: "opacity .3s ease-in-out",
          "&:hover, &:focus": {
            opacity: 0.8,
          },
          "& .logo-wrap": {
            display: "block",
            paddingBottom: "100%",
            position: "relative",
            maxHeight: 250,
            backgroundColor: theme.palette.background.main,
            "& img": {
              position: "absolute !important",
              width: "90%",
              maxWidth: 220,
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            },
          },
          "& span": {
            backgroundColor: "white",
            fontFamily: theme.headerFont,
            fontSize: 18,
            letterSpacing: "0.016em",
            position: "absolute",
            bottom: -8,
            right: 32,
            left: -5,
            color: theme.palette.primary.main,
            padding: "15px 12px 0px 5px",
            clipPath:
              "polygon(0% 0%, calc(100% - 22px) 0, 100% 32px, 100% 100%, 0 100%)",
          },
        },
      },
      "& button.slick-arrow": {
        display: "none !important",
        [theme.breakpoints.up("md")]: {
          display: "block !important",
          fontSize: 0,
          width: 55,
          height: 55,
          position: "absolute",
          border: "1px solid " + theme.palette.secondary.main,
          borderRadius: "50%",
          bottom: 62,
          top: "auto",
          left: "auto",
          "&::before": {
            content: '""',
            borderRight: "2px solid " + theme.palette.secondary.main,
            borderBottom: "2px solid " + theme.palette.secondary.main,
            display: "inline-block",
            width: 16,
            height: 16,
            position: "absolute",
            left: "calc(50% - 4px)",
            top: "50%",
            transform: "translate(-50%, -50%) rotate(-45deg)",
            transition: "bottom .3s ease-in-out",
          },
        },
        "&.slick-prev": {
          right: "calc(129.5% - 55px)",
          "&::before": {
            left: "calc(50% + 4px)",
            transform: "translate(-50%, -50%) rotate(135deg)",
          },
        },
        "&.slick-next": {
          right: "calc(129.5% - 125px)",
        },
      },
      "& .slick-dots": {
        position: "relative",
        margin: "0 0 24px 0",
        width: "100%",
        bottom: 0,
        [theme.breakpoints.up("md")]: {
          bottom: 32,
          margin: 0,
          position: "absolute",
          padding: "0 12px",
          width: "calc((100% - 48px) / 3)",
          right: "100%",
          textAlign: "left",
        },
        "& li": {
          "& button": {
            padding: 0,
            width: 24,
            height: 22,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            fontSize: 0,
            backgroundImage: "url(" + slickDot + ")",
            "&::before": {
              display: "none",
            },
          },
          "&.slick-active": {
            "& button": {
              backgroundImage: "url(" + slickActive + ")",
            },
          },
        },
      },
    },
  },
});
