import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { CoreFields } from "../../../templates/fragment"; // eslint-disable-line
import { buildBlocks } from "../../../services/builder";

export const CoreBlock = (props) => {
  const blocks = buildBlocks(
    props.innerBlocks,
    props.attributes,
    props.pageContext
  );
  return (
    <>
      {blocks.map((block) => {
        return block;
      })}
    </>
  );
};

CoreBlock.propTypes = {
  attributes: PropTypes.object.isRequired,
};

export const CoreBlockFragment = graphql`
  fragment CoreBlock on WpBlock {
    ... on WpCoreBlock {
      reusableBlock {
        __typename
        ... on WpReusableBlock {
          id
          blocks {
            ...CoreFields
          }
        }
      }
    }
  }
`;

// This is queried direct in the preview page query
export const CoreBlockPreview = false;
