import chevron from "../../../images/chevron.png";
// import search from "../../../images/search-solid.png";
import searchAnimation from "../../../images/search-animation.gif";

export default (theme) => ({
  courseListingBlock: {
    padding: "180px 32px",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      padding: "180px 80px",
    },
    "&::before": {
      backgroundColor: theme.palette.primary.main,
      zIndex: -2,
      top: 0,
      bottom: 0,
      left: "50%",
      width: "100vw",
      transform: "translateX(-50%)",
      content: '""',
      position: "absolute",
      display: "block",
    },
    "&::after": {
      backgroundColor: theme.palette.primary.contrastText,
      zIndex: -1,
      top: 95,
      bottom: 95,
      left: 0,
      right: 0,
      position: "absolute",
      content: '""',
      display: "block",
    },
  },
  courseTitle: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    "& label": {
      fontSize: 12,
      lineHeight: 1.6,
      letterSpacing: "0.0152em",
      textTransform: "uppercase",
      margin: 0,
      fontFamily: theme.bodyFont,
    },
    "& input, & textarea, & select, & button": {
      fontFamily: theme.bodyFont + ", sans-serif",
      borderRadius: 20,
      fontSize: 12,
      lineHeight: 2,
      padding: "7px 50px 7px 20px",
      border: "1px solid " + theme.palette.primary.contrastText,
      marginBottom: 24,
      outline: "none",
      display: "block",
      width: "100%",
      boxShadow: "0 8px 16px rgba(0,0,0,0.16)",
      "-moz-appearance": "none",
      "-webkit-appearance": "none",
    },
    "& select": {
      backgroundImage: "url(" + chevron + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center right 20px",
      paddingRight: 50,
    },
  },
  courseResults: {
    position: "relative",
    paddingTop: 32,
    "& h2": {
      marginBottom: 50,
    },
    "& .course-titles": {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        fontFamily: "Roboto Condensed",
        letterSpacing: "0.152em",
        lineHeight: 1.2,
        fontSize: 12,
        textTransform: "uppercase",
        "& .provider": {
          flex: "0 0 16.666%",
          minWidth: "16.666%",
          padding: "0 12px 30px 0",
        },
        "& .title": {
          flex: "0 0 41.666%",
          minWidth: "41.666%",
          padding: "0 12px 30px",
        },
        "& .category": {
          flex: "0 0 16.666%",
          minWidth: "16.666%",
          padding: "0 12px 30px",
        },
        "& .length": {
          flex: "0 0 8.333%",
          minWidth: "8.333%",
          padding: "0 12px 30px",
        },
        "& .dates": {
          flex: "0 0 16.666%",
          minWidth: "16.666%",
          padding: "0 12px 30px",
        },
      },
      [theme.breakpoints.up("lg")]: {
        "& .provider": {
          flex: "0 0 14.28%",
          minWidth: "14.28%",
        },
        "& .title": {
          flex: "0 0 35.71%",
          minWidth: "35.71%",
        },
        "& .category": {
          flex: "0 0 14.28%",
          minWidth: "14.28%",
        },
        "& .length": {
          flex: "0 0 7.14%",
          minWidth: "7.14%",
        },
        "& .dates": {
          flex: "0 0 14.28%",
          minWidth: "14.28%",
        },
      },
    },
    '& .course-listing': {
      '& .course-item': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderTop: '1px solid rgba(0,0,0,0.3)',
        backgroundColor: 'transparent',
        '&:nth-of-type(even)': {
          backgroundColor: '#ededed4d',
        },
        '& span.title': {
          fontFamily: "Roboto Condensed",
          letterSpacing: '0.152em',
          lineHeight: 1.2,
          fontSize: 12,
          opacity: .3,
          display: 'block',
          marginBottom: 5,
          textTransform: 'uppercase',
          [theme.breakpoints.up('md')]: {
            display: 'none',
          }
        },
        '& .provider': {
          flex: '0 0 100%',
          minWidth: '100%',
          padding: 12,
          '& .gatsby-image-wrapper': {
            width: 80,
          }
        },
        '& div.title': {
          flex: '0 0 100%',
          minWidth: '100%',
          padding: 12,
          '& a': {
            fontFamily: 'Overpass',
            letterSpacing: '0.016em',
            lineHeight: 1.5,
            fontSize: 16,
            fontWeight: 'bold',
            color: theme.palette.primary.main,
          }
        },
        '& .category': {
          flex: '0 0 50%',
          minWidth: '50%',
          padding: 12,
          fontFamily: 'Overpass',
          letterSpacing: '0.016em',
          lineHeight: 1.5,
          fontSize: 16,
          opacity: .5
        },
        '& .length': {
          flex: '0 0 50%',
          minWidth: '50%',
          padding: 12,
          fontFamily: 'Overpass',
          letterSpacing: '0.016em',
          lineHeight: 1.5,
          fontSize: 16,
          opacity: .5
        },
        '& .dates': {
          flex: '0 0 50%',
          minWidth: '50%',
          padding: 12,
          fontFamily: 'Overpass',
          letterSpacing: '0.016em',
          lineHeight: 1.5,
          fontSize: 16,
          opacity: .5
        },
        '& .link': {
          flex: '0 0 100%',
          minWidth: '100%',
          padding: 12,
          paddingBottom: 32,
          '& a': {
            display: 'table',
            margin: '0 auto',
            backgroundColor: 'white',
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            fontFamily: theme.headerFont,
            fontSize: 16,
            lineHeight: 1.5,
            borderRadius: 15,
            boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
            textDecoration: 'none',
            padding: '5px 20px',
            transition: 'background-color .3s ease-in-out, color .3s ease-in-out',
            '&:hover, &:focus': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }
          }
        },
        [theme.breakpoints.up('md')]: {
          '& .provider': {
            flex: '0 0 16.666%',
            minWidth: '16.666%',
            padding: '24px 12px 32px',
          },
          '& div.title': {
            flex: '0 0 41.666%',
            minWidth: '41.666%',
            padding: '24px 12px 32px',
          },
          '& .category': {
            flex: '0 0 16.666%',
            minWidth: '16.666%',
            padding: '24px 12px 32px',
          },
          '& .length': {
            flex: '0 0 8.333%',
            minWidth: '8.333%',
            padding: '24px 12px 32px',
          },
          '& .dates': {
            flex: '0 0 16.666%',
            minWidth: '16.666%',
            padding: '24px 12px 32px',
          },
          '& .link': {
            flex: '0 0 100%',
            minWidth: '100%',
            padding: '24px 12px 32px',
          },
        },
        [theme.breakpoints.up('lg')]: {
          '& .provider': {
            flex: '0 0 14.28%',
            minWidth: '14.28%',
          },
          '& div.title': {
            flex: '0 0 35.71%',
            minWidth: '35.71%',
          },
          '& .category': {
            flex: '0 0 14.28%',
            minWidth: '14.28%',
          },
          '& .length': {
            flex: '0 0 7.14%',
            minWidth: '7.14%',
          },
          '& .dates': {
            flex: '0 0 14.28%',
            minWidth: '14.28%',
          },
          '& .link': {
            flex: '0 0 14.28%',
            minWidth: '14.28%',
          }
        }
      }
    },
    searching: {
      opacity: '1 !important',
      fontSize: 18,
      textAlign: 'center',
      padding: '40px 0 40px',
      '&::before': {
        content: '""',
        backgroundImage: 'url(' + searchAnimation + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'contain',
        width: 44,
        height: 44,
        backgroundSize: 'cover',
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: 10,
      }
    },
    cta: {
      display: 'block',
      borderRadius: 25,
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
      padding: '10px 40px',
      textAlign: 'center',
      letterSpacing: '0.016em',
      fontSize: 18,
      fontFamily: theme.headerFont,
      margin: '24px 24px 10px',
      textDecoration: 'none',
      transition: 'background-color .3s ease-in-out',
      [theme.breakpoints.up('md')]: {
        margin: '0 0 10px 0',
        width: 195,
      }
    }
  }
});
