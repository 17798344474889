import { graphql } from "gatsby"
import { CoreParagraphBlockFragment } from "../components/blocks/CoreParagraphBlock/" // eslint-disable-line
import { CoreHeadingBlockFragment } from "../components/blocks/CoreHeadingBlock" // eslint-disable-line
import { CoreImageBlockFragment } from "../components/blocks/CoreImageBlock" // eslint-disable-line
import { CoreColumnBlockFragment } from "../components/blocks/CoreColumnBlock" // eslint-disable-line
import { CoreColumnsBlockFragment } from "../components/blocks/CoreColumnsBlock" // eslint-disable-line
import { CoreButtonsBlockFragment } from "../components/blocks/CoreButtonsBlock" // eslint-disable-line
import { CoreButtonBlockFragment } from "../components/blocks/CoreButtonBlock" // eslint-disable-line
import { CoreGalleryBlockFragment } from "../components/blocks/CoreGalleryBlock" // eslint-disable-line
import { CoreQuoteBlockFragment } from "../components/blocks/CoreQuoteBlock" // eslint-disable-line
import { CoreListBlockFragment } from "../components/blocks/CoreListBlock" // eslint-disable-line
import { CoreTableBlockFragment } from "../components/blocks/CoreTableBlock" // eslint-disable-line
import { CoreFileBlockFragment } from "../components/blocks/CoreFileBlock" // eslint-disable-line
import { CoreSpacerBlockFragment } from "../components/blocks/CoreSpacerBlock" // eslint-disable-line
import { GravityformsFormBlockFragment } from "../components/blocks/GravityformsFormBlock" // eslint-disable-line

import { CgbAccordionBlockFragment } from "../components/blocks/CgbAccordionBlock" // eslint-disable-line
import { CgbAccordionInnerBlockFragment } from "../components/blocks/CgbAccordionInnerBlock" // eslint-disable-line
import { CgbBackgroundWrapBlockFragment } from "../components/blocks/CgbBackgroundWrapBlock" // eslint-disable-line
import { CgbBannerCalloutCtaBlockFragment } from "../components/blocks/CgbBannerCalloutCtaBlock" // eslint-disable-line
import { CgbBannerMessageBlockFragment } from "../components/blocks/CgbBannerMessageBlock" // eslint-disable-line
import { CgbBannerMessageSliderBlockFragment } from "../components/blocks/CgbBannerMessageSliderBlock" // eslint-disable-line
import { CgbBlogBannerBlockFragment } from "../components/blocks/CgbBlogBannerBlock" // eslint-disable-line
import { CgbBlogPagePostsBlockFragment } from "../components/blocks/CgbBlogPagePostsBlock" // eslint-disable-line
import { CgbBlogPostWrapBlockFragment } from "../components/blocks/CgbBlogPostWrapBlock" // eslint-disable-line
import { CgbBreakoutGalleryIntroBlockFragment } from "../components/blocks/CgbBreakoutGalleryIntroBlock" // eslint-disable-line
import { CgbCalloutCtaBlockFragment } from "../components/blocks/CgbCalloutCtaBlock" // eslint-disable-line
import { CgbCourseCalendarBlockFragment } from "../components/blocks/CgbCourseCalendarBlock" // eslint-disable-line
import { CgbCourseSearchBlockFragment } from "../components/blocks/CgbCourseSearchBlock" // eslint-disable-line
import { CgbCourseListBlockFragment } from "../components/blocks/CgbCourseListBlock" // eslint-disable-line
import { CgbCardBlockFragment } from "../components/blocks/CgbCardBlock" // eslint-disable-line
import { CgbCardRowBlockFragment } from "../components/blocks/CgbCardBlock" // eslint-disable-line
import { CgbCategoryListingBlockFragment } from "../components/blocks/CgbCategoryListingBlock" //eslint-disable-line
import { CgbContactCalloutBlockFragment } from "../components/blocks/CgbContactCalloutBlock" // eslint-disable-line
import { CgbContactRowBlockFragment } from "../components/blocks/CgbContactRowBlock" // eslint-disable-line
import { CgbFeaturedIconsBlockFragment } from "../components/blocks/CgbFeaturedIconsBlock" // eslint-disable-line
import { CgbFeaturedIconsInnerBlockFragment } from "../components/blocks/CgbFeaturedIconsInnerBlock" // eslint-disable-line
import { CgbFileListBlockFragment } from "../components/blocks/CgbFileListBlock" // eslint-disable-line
import { CgbFileListInnerBlockFragment } from "../components/blocks/CgbFileListInnerBlock" // eslint-disable-line
import { CgbFileListInnerSmallBlockFragment } from "../components/blocks/CgbFileListInnerSmallBlock" // eslint-disable-line
import { CgbFinanceCalculatorBlockFragment } from "../components/blocks/CgbFinanceCalculatorBlock" // eslint-disable-line
import { CgbGoodToGoBlockFragment } from "../components/blocks/CgbGoodToGoBlock" // eslint-disable-line
import { CgbIconListIntroBlockFragment } from "../components/blocks/CgbIconListIntroBlock" // eslint-disable-line
import { CgbIconListInnerBlockFragment } from "../components/blocks/CgbIconListInnerBlock" // eslint-disable-line
import { CgbIconListBlockFragment } from "../components/blocks/CgbIconListBlock" // eslint-disable-line
import { CgbIconRowBlockFragment } from "../components/blocks/CgbIconRowBlock" // eslint-disable-line
import { CgbIconRowInnerBlockFragment } from "../components/blocks/CgbIconListInnerBlock" // eslint-disable-line
import { CgbImageBreakdownBlockFragment } from "../components/blocks/CgbImageBreakdownBlock" // eslint-disable-line
import { CgbImageBreakdownInnerBlockFragment } from "../components/blocks/CgbImageBreakdownInnerBlock" // eslint-disable-line
import { CgbImageContentRowsBlockFragment } from "../components/blocks/CgbImageContentRowsBlock" // eslint-disable-line
import { CgbImageContentRowsInnerBlockFragment } from "../components/blocks/CgbImageContentRowsInnerBlock" // eslint-disable-line
import { CgbImageGalleryGridBlockFragment } from "../components/blocks/CgbImageGalleryGridBlock" // eslint-disable-line
import { CgbImageGalleryGridInnerLargeBlockFragment } from "../components/blocks/CgbImageGalleryGridInnerLargeBlock" // eslint-disable-line
import { CgbImageGalleryGridInnerSmallBlockFragment } from "../components/blocks/CgbImageGalleryGridInnerSmallBlock" // eslint-disable-line
import { CgbImageLinkBlockFragment } from "../components/blocks/CgbImageLinkBlock" // eslint-disable-line
import { CgbImageLinkRowBlockFragment } from "../components/blocks/CgbImageLinkRowBlock" // eslint-disable-line
import { CgbImageLinkSliderBlockFragment } from "../components/blocks/CgbImageLinkSliderBlock" // eslint-disable-line
import { CgbImageSliderBlockFragment } from "../components/blocks/CgbImageSliderBlock" // eslint-disable-line
import { CgbIntroGalleryBlockFragment } from "../components/blocks/CgbIntroGalleryBlock" // eslint-disable-line
import { CgbIntroGallerySplitBlockFragment } from "../components/blocks/CgbIntroGallerySplitBlock" // eslint-disable-line
import { CgbIntroImageSliderBlockFragment } from "../components/blocks/CgbIntroImageSliderBlock" // eslint-disable-line
import { CgbLatestPostsBlockFragment } from "../components/blocks/CgbLatestPostsBlock" // eslint-disable-line
import { CgbNextPreviousPostsBlockFragment } from "../components/blocks/CgbNextPreviousPostsBlock" // eslint-disable-line
import { CgbOfferGridBlockFragment } from "../components/blocks/CgbOfferGridBlock" // eslint-disable-line
import { CgbOfferGridInnerBlockFragment } from "../components/blocks/CgbOfferGridInnerBlock" // eslint-disable-line
import { CgbPopularProductsBlockFragment } from "../components/blocks/CgbPopularProductsBlock" // eslint-disable-line
import { CgbProductCategortyListBlockFragment } from "../components/blocks/CgbProductCategoryListBlock" // eslint-disable-line
import { CgbPullquoteBlockFragment } from "../components/blocks/CgbPullquoteBlock" // eslint-disable-line
import { CgbSeparatorBlockFragment } from "../components/blocks/CgbSeparatorBlock" // eslint-disable-line
import { CgbSignupFormBlockFragment } from "../components/blocks/CgbSignupFormBlock" // eslint-disable-line
import { CgbSplitContentBannerBlockFragment } from "../components/blocks/CgbSplitContentBannerBlock" // eslint-disable-line
import { CgbSocialSharingBlockFragment } from "../components/blocks/CgbSocialSharingBlock" // eslint-disable-line
import { CgbTabContentBlockFragment } from "../components/blocks/CgbTabContentBlock" // eslint-disable-line
import { CgbTabContentInnerBlockFragment } from "../components/blocks/CgbTabContentInnerBlock" // eslint-disable-line
import { CgbTabSeparatorBlockFragment } from "../components/blocks/CgbTabSeparatorBlock" // eslint-disable-line
import { CgbTestimonialBlockFragment } from "../components/blocks/CgbTestimonialBlock" // eslint-disable-line
import { CgbThickIntroBlockFragment } from "../components/blocks/CgbThickIntroBlock" // eslint-disable-line
import { CgbThinIntroBlockFragment } from "../components/blocks/CgbThinIntroBlock" // eslint-disable-line
import { CgbTopBrandsBlockFragment } from "../components/blocks/CgbTopBrandsBlock" // eslint-disable-line
import { CgbTwoCardIntroBlockFragment } from "../components/blocks/CgbTwoCardIntroBlock" // eslint-disable-line
import { CgbTwoColInnerBlockFragment } from "../components/blocks/CgbTwoColInnerBlock" // eslint-disable-line
import { CgbTwoColWrapperBlockFragment } from "../components/blocks/CgbTwoColWrapperBlock" // eslint-disable-line
import { CgbVideoBannerMessageBlockFragment } from "../components/blocks/CgbVideoBannerMessageBlock" // eslint-disable-line
import { CgbVideoTestimonialBlockFragment } from "../components/blocks/CgbVideoTestimonialBlock" // eslint-disable-line

import { CoreParagraphBlockPreview } from "../components/blocks/CoreParagraphBlock/" // eslint-disable-line
import { CoreHeadingBlockPreview } from "../components/blocks/CoreHeadingBlock/" // eslint-disable-line
import { CoreColumnBlockPreview } from "../components/blocks/CoreColumnBlock/" // eslint-disable-line
import { CoreColumnsBlockPreview } from "../components/blocks/CoreColumnsBlock/" // eslint-disable-line
import { CoreImageBlockPreview } from "../components/blocks/CoreImageBlock/" // eslint-disable-line
import { CoreButtonsBlockPreview } from "../components/blocks/CoreButtonsBlock/" // eslint-disable-line
import { CoreButtonBlockPreview } from "../components/blocks/CoreButtonBlock/" // eslint-disable-line
import { CoreGalleryBlockPreview } from "../components/blocks/CoreGalleryBlock/" // eslint-disable-line
import { CoreQuoteBlockPreview } from "../components/blocks/CoreQuoteBlock/" // eslint-disable-line
import { CoreListBlockPreview } from "../components/blocks/CoreListBlock/" // eslint-disable-lined
import { CoreTableBlockPreview } from "../components/blocks/CoreTableBlock/" // eslint-disable-lined
import { CoreFileBlockPreview } from "../components/blocks/CoreFileBlock/" // eslint-disable-lined
import { CoreSpacerBlockPreview } from "../components/blocks/CoreSpacerBlock/" // eslint-disable-lined
import { GravityformsFormBlockPreview } from "../components/blocks/GravityformsFormBlock" // eslint-disable-line
import { CgbAccordionBlockPreview } from "../components/blocks/CgbAccordionBlock" // eslint-disable-line
import { CgbAccordionInnerBlockPreview } from "../components/blocks/CgbAccordionInnerBlock" // eslint-disable-line
import { CgbBackgroundWrapBlockPreview } from "../components/blocks/CgbBackgroundWrapBlock" // eslint-disable-line
import { CgbBannerCalloutCtaBlockPreview } from "../components/blocks/CgbBannerCalloutCtaBlock" // eslint-disable-line
import { CgbBannerMessageBlockPreview } from "../components/blocks/CgbBannerMessageBlock" // eslint-disable-line
import { CgbBannerMessageSliderBlockPreview } from "../components/blocks/CgbBannerMessageSliderBlock" // eslint-disable-line
import { CgbBlogBannerBlockPreview } from "../components/blocks/CgbBlogBannerBlock" // eslint-disable-line
import { CgbBlogPagePostsBlockPreview } from "../components/blocks/CgbBlogPagePostsBlock" // eslint-disable-line
import { CgbBlogPostWrapBlockPreview } from "../components/blocks/CgbBlogPostWrapBlock" // eslint-disable-line
import { CgbBreakoutGalleryIntroBlockPreview } from "../components/blocks/CgbBreakoutGalleryIntroBlock" // eslint-disable-line
import { CgbCalloutCtaBlockPreview } from "../components/blocks/CgbCalloutCtaBlock" // eslint-disable-line
import { CgbCourseCalendarBlockPreview } from "../components/blocks/CgbCourseCalendarBlock" // eslint-disable-line
import { CgbCourseSearchBlockPreview } from "../components/blocks/CgbCourseSearchBlock" // eslint-disable-line
import { CgbCourseListBlockPreview } from "../components/blocks/CgbCourseListBlock" // eslint-disable-line
import { CgbCategoryListingBlockPreview } from "../components/blocks/CgbCategoryListingBlock" // eslint-disable-line
import { CgbContactRowBlockPreview } from "../components/blocks/CgbContactRowBlock" // eslint-disable-line
import { CgbCardBlockPreview } from "../components/blocks/CgbCardBlock" // eslint-disable-line
import { CgbCardRowBlockPreview } from "../components/blocks/CgbCardRowBlock" // eslint-disable-line
import { CgbContactCalloutBlockPreview } from "../components/blocks/CgbContactCalloutBlock" // eslint-disable-line
import { CgbFeaturedIconsBlockPreview } from "../components/blocks/CgbFeaturedIconsBlock" // eslint-disable-line
import { CgbFeaturedIconsInnerBlockPreview } from "../components/blocks/CgbFeaturedIconsInnerBlock" // eslint-disable-line
import { CgbFileListBlockPreview } from "../components/blocks/CgbFileListBlock" // eslint-disable-line
import { CgbFileListInnerBlockPreview } from "../components/blocks/CgbFileListInnerBlock" // eslint-disable-line
import { CgbFileListInnerSmallBlockPreview } from "../components/blocks/CgbFileListInnerSmallBlock" // eslint-disable-line
import { CgbFinanceCalculatorBlockPreview } from "../components/blocks/CgbFinanceCalculatorBlock"
import { CgbGoodToGoBlockPreview } from "../components/blocks/CgbGoodToGoBlock" // eslint-disable-line
import { CgbIconListIntroBlockPreview } from "../components/blocks/CgbIconListIntroBlock" // eslint-disable-line
import { CgbIconListInnerBlockPreview } from "../components/blocks/CgbIconListInnerBlock" // eslint-disable-line
import { CgbIconListBlockPreview } from "../components/blocks/CgbIconListBlock" // eslint-disable-line
import { CgbIconRowBlockPreview } from "../components/blocks/CgbIconRowBlock" // eslint-disable-line
import { CgbIconRowInnerBlockPreview } from "../components/blocks/CgbIconRowInnerBlock" // eslint-disable-line
import { CgbImageBreakdownBlockPreview } from "../components/blocks/CgbImageBreakdownBlock" // eslint-disable-line
import { CgbImageBreakdownInnerBlockPreview } from "../components/blocks/CgbImageBreakdownInnerBlock" // eslint-disable-line
import { CgbImageContentRowsBlockPreview } from "../components/blocks/CgbImageContentRowsBlock" // eslint-disable-line
import { CgbImageContentRowsInnerBlockPreview } from "../components/blocks/CgbImageContentRowsInnerBlock" // eslint-disable-line
import { CgbImageGalleryGridBlockPreview } from "../components/blocks/CgbImageGalleryGridBlock" // eslint-disable-line
import { CgbImageGalleryGridInnerLargeBlockPreview } from "../components/blocks/CgbImageGalleryGridInnerLargeBlock" // eslint-disable-line
import { CgbImageGalleryGridInnerSmallBlockPreview } from "../components/blocks/CgbImageGalleryGridInnerSmallBlock" // eslint-disable-
import { CgbImageLinkBlockPreview } from "../components/blocks/CgbImageLinkBlock" // eslint-disable-
import { CgbImageLinkRowBlockPreview } from "../components/blocks/CgbImageLinkRowBlock" // eslint-disable-line
import { CgbImageLinkSliderBlockPreview } from "../components/blocks/CgbImageLinkSliderBlock" // eslint-disable-line
import { CgbImageSliderBlockPreview } from "../components/blocks/CgbImageSliderBlock" // eslint-disable-line
import { CgbIntroGalleryBlockPreview } from "../components/blocks/CgbIntroGalleryBlock" // eslint-disable-line
import { CgbIntroGallerySplitBlockPreview } from "../components/blocks/CgbIntroGallerySplitBlock" // eslint-disable-line
import { CgbIntroImageSliderBlockPreview } from "../components/blocks/CgbIntroImageSliderBlock" // eslint-disable-line
import { CgbLatestPostsBlockPreview } from "../components/blocks/CgbLatestPostsBlock" // eslint-disable-line
import { CgbNextPreviousPostsBlockPreview } from "../components/blocks/CgbNextPreviousPostsBlock" // eslint-disable-line
import { CgbOfferGridBlockPreview } from "../components/blocks/CgbOfferGridBlock" // eslint-disable-line
import { CgbOfferGridInnerBlockPreview } from "../components/blocks/CgbOfferGridInnerBlock" // eslint-disable-line
import { CgbPopularProductsBlockPreview } from "../components/blocks/CgbPopularProductsBlock" // eslint-disable-line
import { CgbProductCategoryListBlockPreview } from "../components/blocks/CgbProductCategoryListBlock" // eslint-disable-line
import { CgbPullquoteBlockPreview } from "../components/blocks/CgbPullquoteBlock" // eslint-disable-line
import { CgbSeparatorBlockPreview } from "../components/blocks/CgbSeparatorBlock" // eslint-disable-line
import { CgbSignupFormBlockPreview } from "../components/blocks/CgbSignupFormBlock" // eslint-disable-line
import { CgbSocialSharingBlockPreview } from "../components/blocks/CgbSocialSharingBlock" // eslint-disable-line
import { CgbSplitContentBannerBlockPreview } from "../components/blocks/CgbSplitContentBannerBlock" // eslint-disable-line
import { CgbTabContentBlockPreview } from "../components/blocks/CgbTabContentBlock" // eslint-disable-line
import { CgbTabContentInnerBlockPreview } from "../components/blocks/CgbTabContentInnerBlock" // eslint-disable-line
import { CgbTabSeparatorBlockPreview } from "../components/blocks/CgbTabSeparatorBlock" // eslint-disable-line
import { CgbTestimonialBlockPreview } from "../components/blocks/CgbTestimonialBlock" // eslint-disable-line
import { CgbThickIntroBlockPreview } from "../components/blocks/CgbThickIntroBlock" // eslint-disable-line
import { CgbThinIntroBlockPreview } from "../components/blocks/CgbThinIntroBlock" // eslint-disable-line
import { CgbTopBrandsBlockPreview } from "../components/blocks/CgbTopBrandsBlock" // eslint-disable-line
import { CgbTwoCardIntroBlockPreview } from "../components/blocks/CgbTwoCardIntroBlock" // eslint-disable-line
import { CgbTwoColInnerBlockPreview } from "../components/blocks/CgbTwoColInnerBlock" // eslint-disable-line
import { CgbTwoColWrapperBlockPreview } from "../components/blocks/CgbTwoColWrapperBlock" // eslint-disable-line
import { CgbVideoBannerMessageBlockPreview } from "../components/blocks/CgbVideoBannerMessageBlock" // eslint-disable-line
import { CgbVideoTestimonialBlockPreview } from "../components/blocks/CgbVideoTestimonialBlock" // eslint-disable-line

export const CoreFields = graphql`
  fragment CoreFields on WpBlock {
    name
    ...CoreParagraphBlock
    ...CoreHeadingBlock
    ...CoreImageBlock
    ...CoreColumnBlock
    ...CoreColumnsBlock
    ...CoreButtonsBlock
    ...CoreButtonBlock
    ...CoreGalleryBlock
    ...CoreQuoteBlock
    ...CoreListBlock
    ...CoreTableBlock
    ...CoreFileBlock
    ...CoreSpacerBlock
    ...GravityformsFormBlock
    ...CgbAccordionBlock
    ...CgbAccordionInnerBlock
    ...CgbBackgroundWrapBlock
    ...CgbBannerCalloutCtaBlock
    ...CgbBannerMessageBlock
    ...CgbBannerMessageSliderBlock
    ...CgbBreakoutGalleryIntroBlock
    ...CgbBlogBannerBlock
    ...CgbBlogPagePostsBlock
    ...CgbBlogPostWrapBlock
    ...CgbCalloutCtaBlock
    ...CgbCourseCalendarBlock
    ...CgbCourseSearchBlock
    ...CgbCourseListBlock
    ...CgbCardBlock
    ...CgbCardRowBlock
    ...CgbCategoryListingBlock
    ...CgbContactCalloutBlock
    ...CgbContactRowBlock
    ...CgbFeaturedIconsBlock
    ...CgbFeaturedIconsInnerBlock
    ...CgbFileListBlock
    ...CgbFileListInnerBlock
    ...CgbFileListInnerSmallBlock
    ...CgbFinanceCalculatorBlock
    ...CgbGoodToGoBlock
    ...CgbIconListIntroBlock
    ...CgbIconListInnerBlock
    ...CgbIconListBlock
    ...CgbIconRowBlock
    ...CgbIconRowInnerBlock
    ...CgbImageBreakdownBlock
    ...CgbImageBreakdownInnerBlock
    ...CgbImageContentRowsBlock
    ...CgbImageContentRowsInnerBlock
    ...CgbImageGalleryGridBlock
    ...CgbImageGalleryGridInnerLargeBlock
    ...CgbImageGalleryGridInnerSmallBlock
    ...CgbImageLinkBlock
    ...CgbImageLinkRowBlock
    ...CgbImageLinkSliderBlock
    ...CgbImageSliderBlock
    ...CgbIntroGalleryBlock
    ...CgbIntroGallerySplitBlock
    ...CgbIntroImageSliderBlock
    ...CgbLatestPostsBlock
    ...CgbNextPreviousPostsBlock
    ...CgbOfferGridBlock
    ...CgbOfferGridInnerBlock
    ...CgbPopularProductsBlock
    ...CgbProductCategoryListBlock
    ...CgbPullquoteBlock
    ...CgbSeparatorBlock
    ...CgbSignupFormBlock
    ...CgbSocialSharingBlock
    ...CgbSplitContentBannerBlock
    ...CgbTabContentBlock
    ...CgbTabContentInnerBlock
    ...CgbTabSeparatorBlock
    ...CgbTestimonialBlock
    ...CgbThickIntroBlock
    ...CgbThinIntroBlock
    ...CgbTopBrandsBlock
    ...CgbTwoCardIntroBlock
    ...CgbTwoColInnerBlock
    ...CgbTwoColWrapperBlock
    ...CgbVideoBannerMessageBlock
    ...CgbVideoTestimonialBlock
    innerBlocks {
      name
      ...CoreParagraphBlock
      ...CoreHeadingBlock
      ...CoreImageBlock
      ...CoreColumnBlock
      ...CoreColumnsBlock
      ...CoreButtonsBlock
      ...CoreButtonBlock
      ...CoreGalleryBlock
      ...CoreQuoteBlock
      ...CoreListBlock
      ...CoreTableBlock
      ...CoreFileBlock
      ...CoreSpacerBlock
      ...GravityformsFormBlock
      ...CgbAccordionBlock
      ...CgbAccordionInnerBlock
      ...CgbBackgroundWrapBlock
      ...CgbBannerCalloutCtaBlock
      ...CgbBannerMessageBlock
      ...CgbBannerMessageSliderBlock
      ...CgbBlogBannerBlock
      ...CgbBlogPagePostsBlock
      ...CgbBlogPostWrapBlock
      ...CgbBreakoutGalleryIntroBlock
      ...CgbCalloutCtaBlock
      ...CgbCourseSearchBlock
      ...CgbCardBlock
      ...CgbCardRowBlock
      ...CgbCategoryListingBlock
      ...CgbContactCalloutBlock
      ...CgbContactRowBlock
      ...CgbFeaturedIconsBlock
      ...CgbFeaturedIconsInnerBlock
      ...CgbFileListBlock
      ...CgbFileListInnerBlock
      ...CgbFileListInnerSmallBlock
      ...CgbGoodToGoBlock
      ...CgbIconListIntroBlock
      ...CgbIconListInnerBlock
      ...CgbIconListBlock
      ...CgbIconRowBlock
      ...CgbIconRowInnerBlock
      ...CgbImageBreakdownBlock
      ...CgbImageBreakdownInnerBlock
      ...CgbImageContentRowsBlock
      ...CgbImageContentRowsInnerBlock
      ...CgbImageGalleryGridBlock
      ...CgbImageGalleryGridInnerLargeBlock
      ...CgbImageGalleryGridInnerSmallBlock
      ...CgbImageLinkBlock
      ...CgbImageLinkRowBlock
      ...CgbImageLinkSliderBlock
      ...CgbImageSliderBlock
      ...CgbIntroGalleryBlock
      ...CgbIntroGallerySplitBlock
      ...CgbIntroImageSliderBlock
      ...CgbLatestPostsBlock
      ...CgbNextPreviousPostsBlock
      ...CgbOfferGridBlock
      ...CgbOfferGridInnerBlock
      ...CgbPopularProductsBlock
      ...CgbProductCategoryListBlock
      ...CgbPullquoteBlock
      ...CgbSeparatorBlock
      ...CgbSocialSharingBlock
      ...CgbSplitContentBannerBlock
      ...CgbTabContentBlock
      ...CgbTabContentInnerBlock
      ...CgbTabSeparatorBlock
      ...CgbTestimonialBlock
      ...CgbThickIntroBlock
      ...CgbThinIntroBlock
      ...CgbTopBrandsBlock
      ...CgbTwoCardIntroBlock
      ...CgbTwoColInnerBlock
      ...CgbTwoColWrapperBlock
      ...CgbVideoBannerMessageBlock
      ...CgbVideoTestimonialBlock
      innerBlocks {
        name
        ...CoreParagraphBlock
        ...CoreHeadingBlock
        ...CoreImageBlock
        ...CoreColumnBlock
        ...CoreColumnsBlock
        ...CoreButtonsBlock
        ...CoreButtonBlock
        ...CoreGalleryBlock
        ...CoreQuoteBlock
        ...CoreListBlock
        ...CoreTableBlock
        ...CoreFileBlock
        ...CoreSpacerBlock
        ...GravityformsFormBlock
        ...CgbAccordionBlock
        ...CgbAccordionInnerBlock
        ...CgbBackgroundWrapBlock
        ...CgbBannerCalloutCtaBlock
        ...CgbBannerMessageBlock
        ...CgbBannerMessageSliderBlock
        ...CgbBlogBannerBlock
        ...CgbBlogPagePostsBlock
        ...CgbBlogPostWrapBlock
        ...CgbBreakoutGalleryIntroBlock
        ...CgbCalloutCtaBlock
        ...CgbContactRowBlock
        ...CgbCardBlock
        ...CgbCardRowBlock
        ...CgbCategoryListingBlock
        ...CgbContactCalloutBlock
        ...CgbFeaturedIconsBlock
        ...CgbFeaturedIconsInnerBlock
        ...CgbFileListBlock
        ...CgbFileListInnerBlock
        ...CgbFileListInnerSmallBlock
        ...CgbGoodToGoBlock
        ...CgbIconListIntroBlock
        ...CgbIconListInnerBlock
        ...CgbIconListBlock
        ...CgbIconRowBlock
        ...CgbIconRowInnerBlock
        ...CgbImageBreakdownBlock
        ...CgbImageBreakdownInnerBlock
        ...CgbImageContentRowsBlock
        ...CgbImageContentRowsInnerBlock
        ...CgbImageGalleryGridBlock
        ...CgbImageGalleryGridInnerLargeBlock
        ...CgbImageGalleryGridInnerSmallBlock
        ...CgbImageLinkBlock
        ...CgbImageLinkRowBlock
        ...CgbImageLinkSliderBlock
        ...CgbImageSliderBlock
        ...CgbIntroGalleryBlock
        ...CgbIntroGallerySplitBlock
        ...CgbIntroImageSliderBlock
        ...CgbLatestPostsBlock
        ...CgbNextPreviousPostsBlock
        ...CgbOfferGridBlock
        ...CgbOfferGridInnerBlock
        ...CgbPopularProductsBlock
        ...CgbProductCategoryListBlock
        ...CgbPullquoteBlock
        ...CgbSeparatorBlock
        ...CgbSocialSharingBlock
        ...CgbSplitContentBannerBlock
        ...CgbTabContentBlock
        ...CgbTabContentInnerBlock
        ...CgbTabSeparatorBlock
        ...CgbTestimonialBlock
        ...CgbThickIntroBlock
        ...CgbThinIntroBlock
        ...CgbTopBrandsBlock
        ...CgbTwoCardIntroBlock
        ...CgbTwoColInnerBlock
        ...CgbTwoColWrapperBlock
        ...CgbVideoBannerMessageBlock
        ...CgbVideoTestimonialBlock
        innerBlocks {
          name
          ...CoreParagraphBlock
          ...CoreHeadingBlock
          ...CoreImageBlock
          ...CoreColumnBlock
          ...CoreColumnsBlock
          ...CoreButtonsBlock
          ...CoreButtonBlock
          ...CoreGalleryBlock
          ...CoreQuoteBlock
          ...CoreListBlock
          ...CoreTableBlock
          ...CoreFileBlock
          ...CoreSpacerBlock
          ...GravityformsFormBlock
          ...CgbAccordionBlock
          ...CgbAccordionInnerBlock
          ...CgbBackgroundWrapBlock
          ...CgbBannerCalloutCtaBlock
          ...CgbBannerMessageBlock
          ...CgbBannerMessageSliderBlock
          ...CgbBlogBannerBlock
          ...CgbBlogPagePostsBlock
          ...CgbBlogPostWrapBlock
          ...CgbBreakoutGalleryIntroBlock
          ...CgbCalloutCtaBlock
          ...CgbContactRowBlock
          ...CgbCardBlock
          ...CgbCardRowBlock
          ...CgbCategoryListingBlock
          ...CgbContactCalloutBlock
          ...CgbFeaturedIconsBlock
          ...CgbFeaturedIconsInnerBlock
          ...CgbFileListBlock
          ...CgbFileListInnerBlock
          ...CgbFileListInnerSmallBlock
          ...CgbGoodToGoBlock
          ...CgbIconListIntroBlock
          ...CgbIconListInnerBlock
          ...CgbIconListBlock
          ...CgbIconRowBlock
          ...CgbIconRowInnerBlock
          ...CgbImageBreakdownBlock
          ...CgbImageBreakdownInnerBlock
          ...CgbImageContentRowsBlock
          ...CgbImageContentRowsInnerBlock
          ...CgbImageGalleryGridBlock
          ...CgbImageGalleryGridInnerLargeBlock
          ...CgbImageGalleryGridInnerSmallBlock
          ...CgbImageLinkBlock
          ...CgbImageLinkRowBlock
          ...CgbImageLinkSliderBlock
          ...CgbImageSliderBlock
          ...CgbIntroGalleryBlock
          ...CgbIntroGallerySplitBlock
          ...CgbIntroImageSliderBlock
          ...CgbLatestPostsBlock
          ...CgbNextPreviousPostsBlock
          ...CgbOfferGridBlock
          ...CgbOfferGridInnerBlock
          ...CgbPopularProductsBlock
          ...CgbProductCategoryListBlock
          ...CgbPullquoteBlock
          ...CgbSeparatorBlock
          ...CgbSocialSharingBlock
          ...CgbSplitContentBannerBlock
          ...CgbTabContentBlock
          ...CgbTabContentInnerBlock
          ...CgbTabSeparatorBlock
          ...CgbTestimonialBlock
          ...CgbThickIntroBlock
          ...CgbThinIntroBlock
          ...CgbTopBrandsBlock
          ...CgbTwoCardIntroBlock
          ...CgbTwoColInnerBlock
          ...CgbTwoColWrapperBlock
          ...CgbVideoBannerMessageBlock
          ...CgbVideoTestimonialBlock
        }
      }
    }
  }
`

export const PreviewBlocks = `
  ${CoreParagraphBlockPreview}
  ${CoreHeadingBlockPreview}
  ${CoreColumnBlockPreview}
  ${CoreColumnsBlockPreview}
  ${CoreImageBlockPreview}
  ${CoreButtonsBlockPreview}
  ${CoreButtonBlockPreview}
  ${CoreGalleryBlockPreview}
  ${CoreGalleryBlockPreview}
  ${CoreQuoteBlockPreview}
  ${CoreListBlockPreview}
  ${CoreTableBlockPreview}
  ${CoreFileBlockPreview}
  ${CoreSpacerBlockPreview}
  ${GravityformsFormBlockPreview}

  ${CoreParagraphBlockPreview}
  ${CoreHeadingBlockPreview}
  ${CoreImageBlockPreview}
  ${CoreColumnBlockPreview}
  ${CoreColumnsBlockPreview}
  ${CoreButtonsBlockPreview}
  ${CoreButtonBlockPreview}
  ${CoreGalleryBlockPreview}
  ${CoreQuoteBlockPreview}
  ${CoreListBlockPreview}
  ${CoreTableBlockPreview}
  ${CoreFileBlockPreview}
  ${CoreSpacerBlockPreview}
  ${GravityformsFormBlockPreview}
  ${CgbAccordionBlockPreview}
  ${CgbAccordionInnerBlockPreview}
  ${CgbBackgroundWrapBlockPreview}
  ${CgbBannerCalloutCtaBlockPreview}
  ${CgbBannerMessageBlockPreview}
  ${CgbBannerMessageSliderBlockPreview}
  ${CgbBlogBannerBlockPreview}
  ${CgbBlogPagePostsBlockPreview}
  ${CgbBlogPostWrapBlockPreview}
  ${CgbBreakoutGalleryIntroBlockPreview}
  ${CgbCalloutCtaBlockPreview}
  ${CgbCourseCalendarBlockPreview}
  ${CgbCourseSearchBlockPreview}
  ${CgbCourseListBlockPreview}
  ${CgbCardBlockPreview}
  ${CgbCardRowBlockPreview}
  ${CgbCategoryListingBlockPreview}
  ${CgbContactCalloutBlockPreview}
  ${CgbContactRowBlockPreview}
  ${CgbFeaturedIconsBlockPreview}
  ${CgbFeaturedIconsInnerBlockPreview}
  ${CgbFileListBlockPreview}
  ${CgbFileListInnerBlockPreview}
  ${CgbFileListInnerSmallBlockPreview}
  ${CgbFinanceCalculatorBlockPreview}
  ${CgbGoodToGoBlockPreview}
  ${CgbIconListIntroBlockPreview}
  ${CgbIconListInnerBlockPreview}
  ${CgbIconListBlockPreview}
  ${CgbIconRowBlockPreview}
  ${CgbIconRowInnerBlockPreview}
  ${CgbImageBreakdownBlockPreview}
  ${CgbImageBreakdownInnerBlockPreview}
  ${CgbImageContentRowsBlockPreview}
  ${CgbImageContentRowsInnerBlockPreview}
  ${CgbImageGalleryGridBlockPreview}
  ${CgbImageGalleryGridInnerLargeBlockPreview}
  ${CgbImageGalleryGridInnerSmallBlockPreview}
  ${CgbImageLinkBlockPreview}
  ${CgbImageLinkRowBlockPreview}
  ${CgbImageLinkSliderBlockPreview}
  ${CgbImageSliderBlockPreview}
  ${CgbIntroGalleryBlockPreview}
  ${CgbIntroGallerySplitBlockPreview}
  ${CgbIntroImageSliderBlockPreview}
  ${CgbLatestPostsBlockPreview}
  ${CgbNextPreviousPostsBlockPreview}
  ${CgbOfferGridBlockPreview}
  ${CgbOfferGridInnerBlockPreview}
  ${CgbPopularProductsBlockPreview}
  ${CgbProductCategoryListBlockPreview}
  ${CgbPullquoteBlockPreview}
  ${CgbSeparatorBlockPreview}
  ${CgbSignupFormBlockPreview}
  ${CgbSocialSharingBlockPreview}
  ${CgbSplitContentBannerBlockPreview}
  ${CgbTabContentBlockPreview}
  ${CgbTabContentInnerBlockPreview}
  ${CgbTabSeparatorBlockPreview}
  ${CgbTestimonialBlockPreview}
  ${CgbThickIntroBlockPreview}
  ${CgbThinIntroBlockPreview}
  ${CgbTopBrandsBlockPreview}
  ${CgbTwoCardIntroBlockPreview}
  ${CgbTwoColInnerBlockPreview}
  ${CgbTwoColWrapperBlockPreview}
  ${CgbVideoBannerMessageBlockPreview}
  ${CgbVideoTestimonialBlockPreview}
  `
