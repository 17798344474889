import slickDot from "../../../images/slick-dot.png";
import slickActive from "../../../images/slick-active.png";

export default (theme) => ({
  bannerSlider: {
    position: "relative",
    marginBottom: 36,
    [theme.breakpoints.up("md")]: {
      width: "100vw",
      marginLeft: "50%",
      transform: "translateX(-50%)",
    },
    "& .slick-slider": {
      position: "relative",
      "& .slick-list": {
        "& .slick-slide": {
          width: "100vw",
          display: "block",
        },
      },
      "& button.slick-arrow": {
        display: "block !important",
        fontSize: 0,
        width: 44,
        height: 44,
        position: "absolute",
        border: "1px solid " + theme.palette.secondary.main,
        borderRadius: "50%",
        bottom: 40,
        top: "auto",
        left: "calc(50% + 6px)",
        zIndex: 10,
        "&::before": {
          content: '""',
          borderRight: "2px solid " + theme.palette.secondary.main,
          borderBottom: "2px solid " + theme.palette.secondary.main,
          display: "inline-block",
          width: 12,
          height: 12,
          position: "absolute",
          left: "calc(50% - 2px)",
          top: "50%",
          transform: "translate(-50%, -50%) rotate(-45deg)",
          transition: "bottom .3s ease-in-out",
        },
        "&.slick-prev": {
          left: "auto",
          right: "calc(50% + 6px)",
          "&::before": {
            left: "calc(50% + 2px)",
            transform: "translate(-50%, -50%) rotate(135deg)",
          },
        },
        "&.slick-next": {
          right: "calc(129.5% - 125px)",
        },
      },
      "& .slick-dots": {
        position: "absolute",
        zIndex: 3,
        bottom: 24,
        left: "50%",
        width: "auto",
        transform: "translateX(-50%)",
        "& li": {
          "& button": {
            padding: 0,
            width: 24,
            height: 22,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            fontSize: 0,
            backgroundImage: "url(" + slickDot + ")",
            "&::before": {
              display: "none",
            },
          },
          "&.slick-active": {
            "& button": {
              backgroundImage: "url(" + slickActive + ")",
            },
          },
        },
      },
    },
    "& .banner-message": {
      marginBottom: "0 !important",
    },
  },
  bannerSliderTheme: {
    ...theme.bannerSliderTheme,
  },
});
