import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from "@material-ui/styles";
// import { buildBlocks } from "../../../services/builder";
import styles from "./styles";
import Link from "../../Link";
import ReactHtmlParser from "react-html-parser";
import Img from "gatsby-image";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbProductCategoryListBlock = (props) => {
  // console.log(props);
  // const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query ProductCategoryList {
      allWpProductCategory(sort: { fields: name }) {
        nodes {
          id
          name
          slug
          uri
          wpParent {
            node {
              name
              slug
            }
          }
          productCategoryExtras {
            categoryImage {
              localFile {
                publicURL
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            categoryIcon {
              localFile {
                publicURL
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      wp {
        themeOptions {
          ThemeOptions {
            defaultImages {
              categoryImage {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  let outputList = [];

  data.allWpProductCategory.nodes.forEach((category) => {
    // console.log(category);
    if (!category.wpParent || !category.wpParent.node) return null;
    if (category.wpParent !== null) {
      if (category.wpParent.node.slug === props.pageContext.slug) {
        // console.log(category);
        outputList.push(category);
      }
    }
  });

  return (
    <div
      className={`prod-cat-llist ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.prodCatList}`}
    >
      {outputList.map((category) => (
        <Link to={category.uri} className="cat-link">
          {category.productCategoryExtras.categoryImage !== null && (
            <Img
              fluid={
                category.productCategoryExtras?.categoryImage?.localFile
                  ?.childImageSharp?.fluid
              }
            />
          )}
          {category.productCategoryExtras.categoryImage === null && (
            <Img
              fluid={
                data.wp.themeOptions.ThemeOptions.defaultImages.categoryImage
                  .localFile.childImageSharp.fluid
              }
            />
          )}
          <span>{ReactHtmlParser(category.name)}</span>
          {category.productCategoryExtras.categoryIcon !== null && (
            <img
              src={
                category.productCategoryExtras?.categoryIcon?.localFile
                  ?.publicURL
              }
              alt={category.name}
            />
          )}
        </Link>
      ))}
    </div>
  );
};

export const CgbProductCategoryListBlockFragment = graphql`
  fragment CgbProductCategoryListBlock on WpBlock {
    ... on WpCgbProductCategoryListBlock {
      name
      attributes {
        __typename
        ... on WpCgbProductCategoryListBlockAttributes {
          className
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbProductCategoryListBlockPreview = `
    ... on CgbProductCategoryListBlock {
        name
        attributes {
            __typename
            ... on CgbProductCategoryListBlockAttributes {
                className
            }
        }
    }
`;
