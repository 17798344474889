import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles } from "@material-ui/styles";
// import { buildBlocks } from "../../../services/builder";
import styles from "./styles";
import Img from "gatsby-image";
import Link from "../../Link";

import { CoreHeadingBlock } from "../CoreHeadingBlock";
import { CoreButtonBlock } from "../CoreButtonBlock";

const useStyles = makeStyles((theme) => styles(theme));

export const CgbCategoryListingBlock = (props) => {
  // console.log(props);
  // const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
  const classes = useStyles();

  const [open1, setOpen1] = React.useState(true);
  const [open2, setOpen2] = React.useState(false);
  const [linkUrl, setLinkUrl] = React.useState(
    "/category/car-workshop-equipment/"
  );

  const handleOpen1 = () => {
    setOpen1(true);
    setOpen2(false);
    setLinkUrl("/category/car-workshop-equipment/");
  };

  const handleOpen2 = () => {
    setOpen1(false);
    setOpen2(true);
    setLinkUrl("/category/commercial-workshop-equipment/");
  };

  const data = useStaticQuery(graphql`
    query CategoryListing {
      allWpProductCategory(sort: { fields: name, order: ASC }) {
        nodes {
          slug
          name
          uri
          productCategoryExtras {
            categoryImage {
              localFile {
                publicURL
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            categoryIcon {
              localFile {
                publicURL
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          wpParent {
            node {
              slug
              name
            }
          }
        }
      }
      wp {
        themeOptions {
          ThemeOptions {
            defaultImages {
              categoryImage {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  let catArray = [];

  data.allWpProductCategory.nodes.map((cat) => {
    if (cat.wpParent === null) {
      catArray.push({
        key: cat.slug,
        category: cat,
        children: [],
      });
    }
    return null;
  });

  data.allWpProductCategory.nodes.map((cat) => {
    if (!cat.wpParent || !cat.wpParent.node || !cat.wpParent.node.slug)
      return null;
    if (cat.wpParent !== null) {
      catArray.map((obj) => {
        if (obj.key === cat.wpParent.node.slug) {
          obj.children.push(cat);
        }
        return null;
      });
      // catArray[cat.wpParent.node.slug].children.push(cat);
    }
    return null;
  });

  let count1 = 0;
  let count2 = 0;

  function filterMainCategorySlug(name) {
    if (name.includes("LV")) {
      return name.replace("LV", "").trim();
    }

    if (name.includes("CV")) {
      return name.replace("CV", "").trim();
    }

    return name;
  }

  //   console.log(catArray);

  return (
    <div
      className={`category-listing ${
        props.attributes.className ? props.attributes.className : ""
      } ${classes.categoryListing}`}
    >
      <div className="title">
        <CoreHeadingBlock
          attributes={{
            align: "",
            anchor: "",
            className: "",
            content: "Our Products",
            level: 2,
            textColor: "primary",
            backgroundColor: "",
            __typename: "WpCoreHeadingBlockAttributes",
          }}
          innerBlocks={[]}
        />
        <div className="button-wrap">
          <CoreButtonBlock
            attributes={{
              align: "",
              backgroundColor: "foreground_secondary",
              borderRadius: 0,
              className: "",
              gradient: "",
              linkTarget: "",
              placeholder: "",
              rel: "",
              text: "View All Products",
              textColor: "secondary",
              title: "",
              url: linkUrl,
              __typename: "WpCoreButtonBlockAttributes",
            }}
          />
        </div>
      </div>
      <div className="category-listing">
        <div className="top-level">
          {catArray.map((cat) => {
            count1++;
            return (
              <button
                onClick={count1 === 1 ? handleOpen1 : handleOpen2}
                onKeyDown={count1 === 1 ? handleOpen1 : handleOpen2}
                open={count1 === 1 ? open1 : open2}
              >
                <span>{cat.category.name}</span>
                {cat.category.productCategoryExtras.categoryImage !== null && (
                  <Img
                    fluid={
                      cat.category.productCategoryExtras?.categoryImage
                        ?.localFile?.childImageSharp?.fluid
                    }
                  />
                )}
                {cat.category.productCategoryExtras.categoryImage === null && (
                  <Img
                    fluid={
                      data.wp.themeOptions.ThemeOptions.defaultImages
                        .categoryImage.localFile.childImageSharp.fluid
                    }
                  />
                )}
              </button>
            );
          })}
        </div>
        <div className="children">
          {catArray.map((cat) => {
            count2++;
            return (
              <div className="inner-list" open={count2 === 1 ? open1 : open2}>
                {cat.children.map((child) => {
                  return (
                    <Link to={child.uri}>
                      <span>{filterMainCategorySlug(child.name)}</span>
                      {child.productCategoryExtras.categoryIcon !== null && (
                        <img
                          src={
                            child.productCategoryExtras?.categoryIcon?.localFile
                              ?.publicURL
                          }
                          alt=""
                        />
                      )}
                    </Link>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const CgbCategoryListingBlockFragment = graphql`
  fragment CgbCategoryListingBlock on WpBlock {
    ... on WpCgbCategoryListingBlock {
      name
      attributes {
        __typename
        ... on WpCgbCategoryListingBlockAttributes {
          className
        }
      }
    }
  }
`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbCategoryListingBlockPreview = `
    ... on CgbCategoryListingBlock {
        name
        attributes {
            __typename
            ... on CgbCategoryListingBlockAttributes {
                className
            }
        }
    }
`;
