import React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Link from "../../Link";
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbSocialSharingBlock = (props) => {
    const classes = useStyles();

    const data = useStaticQuery(graphql`
      query urlGrab {
        wp {
          locationData {
            LocationData {
              url
            }
          }
        }
      }
    `);

    return <div className={`social-sharing ${props.attributes.className ? props.attributes.className : ''} ${classes.socialSharing} ${classes.socialSharingTheme}`}>
        <p className="title">Share</p>
        <p className="link-list">
            <Link to={"https://twitter.com/intent/tweet?text=" + props.pageContext.pageTitle + "&url=" + data.wp.locationData.LocationData.url + props.pageContext.pagePath}><TwitterIcon/></Link>
            <Link to={"https://www.facebook.com/sharer/sharer.php?u=" + data.wp.locationData.LocationData.url + props.pageContext.pagePath}><FacebookIcon/></Link>
            <Link to={"mailto:?subject=" + props.pageContext.pageTitle + "&amp;body=" + data.wp.locationData.LocationData.url + props.pageContext.pagePath}><EmailIcon/></Link>
        </p>
    </div>
};

export const CgbSocialSharingBlockFragment = graphql`
  fragment CgbSocialSharingBlock on WpBlock {
    ... on WpCgbSocialSharingBlock {
        name
        attributes {
            className
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbSocialSharingBlockPreview = `
    ... on CgbSocialSharingBlock {
        name
        attributes {
            className
        }
    }
`;