
import ReactHtmlParser from 'react-html-parser';
import { graphql } from "gatsby";

export const CoreImageBlock = (props) => {

  return ReactHtmlParser(props.originalContent.replace(/1024px/g, "1660px"));

  // if( ! props.attributes.url) return null;
  // let image = null;

  // Use the gatsby image if available
  // if(props.attributes.urlSharp){
  //     if(props.attributes.urlSharp.childImageSharp){
  //       image = <Img 
  //         fluid={props.attributes.urlSharp.childImageSharp.fluid}
  //         alt={props.attributes.alt ? props.attributes.alt : ''}
  //         className={`img-align-${props.attributes.align} ${props.attributes.className ? props.attributes.className : ''}`}
  //         width={props.attributes.width ? props.attributes.width : ''}
  //         height={props.attributes.height ? props.attributes.height : ''}
  //       />
  //     } else {
  //       // Gifs do not have a childImageSharp
  //       image = <img 
  //         src={props.attributes.urlSharp.publicURL}
  //         alt={props.attributes.alt ? props.attributes.alt : ''}
  //         className={`img-align-${props.attributes.align} ${props.attributes.className ? props.attributes.className : ''}`}
  //         width={props.attributes.width ? props.attributes.width : ''}
  //         height={props.attributes.height ? props.attributes.height : ''}
  //       />
  //     }
  // } else {
      // console.log(props.attributes.height);
    // This is a preview image
  //   image = <div className="gatsby-image-wrapper">
  //       <div style={{ paddingBottom: '100%' }} />
  //       <img
  //         src={props.attributes.url}
  //         alt={props.attributes.alt ? props.attributes.alt : ''}
  //         className={`img-align-${props.attributes.align} ${props.attributes.className ? props.attributes.className : ''}`}
  //         style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}
  //       />
  //   </div>
  // // }
  

  // if(props.attributes.caption){
  //   image = <div className="image-with-caption">
  //     {image}
  //     <Typography
  //         component="p"
  //         variant="body1"
  //         className={`caption ${classes.imageCaption} ${classes.imageCaptionTheme}`}
  //         paragraph={true}
  //     >
  //         {ReactHtmlParser(props.attributes.caption, {transform : transformLink})}
  //     </Typography>
  //   </span>
  // }

  // if(props.attributes.href){
  //   return <Link 
  //       to={props.attributes.href} 
  //       title={props.attributes.title ? props.attributes.title : ''} 
  //       className={props.attributes.linkClass ? props.attributes.linkClass : ''}
  //     >
  //       {image}
  //     </Link>
  // } else {
  //   return image;
  // }
};

// CoreImageBlock.propTypes = {
//     attributes: PropTypes.object.isRequired,
// };

export const CoreImageBlockFragment = graphql`
  fragment CoreImageBlock on WpBlock {
    ... on WpCoreImageBlock {
      name
      originalContent
      attributes {
        __typename
        ... on WpCoreImageBlockAttributes {
          align
          alt
          caption
          className
          href
          id
          linkClass
          linkDestination
          linkTarget
          rel
          sizeSlug
          title
          url     
        }
      }
    }
  }`;

  
export const CoreImageBlockPreview = `
  ... on CoreImageBlock {
    name
    originalContent
    attributes {
      __typename
      ... on CoreImageBlockAttributes {
        align
        alt
        caption
        className
        href
        id
        linkClass
        linkDestination
        linkTarget
        rel
        sizeSlug
        title
        url 
      }
    }
  }`;