export const queryCourseDates = (next = "", startDate = 0, endDate = 20300101, courseDatabaseId = false) => {
  const courseIdCondition = (courseDatabaseId) ? `, courseDatabaseId: ${courseDatabaseId}` : '';
  return {
    query: `
    {
      courseDates(first: 1000, after: "${next}", where: {startDate: ${startDate}, endDate: ${endDate}${courseIdCondition}}) {
        edges {
          node {
            id
            title
            uri
            courseData {
              course {
                ... on Course {
                  id
                  databaseId
                  uri
                  title
                  courses {
                    length
                    type
                    provider {
                      name
                      slug
                      courseProviderInfo {
                        logo {
                          sourceUrl
                        }
                      }
                    }
                  }
                  courseCategories {
                    edges {
                      node {
                        name
                        slug
                      }
                    }
                  }
                }
              }
              startDate
              endDate
              seatsAvailable
              seatsBooked
              place {
                ... on Place {
                  title
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `,
  };
};

export const queryCourseCategories = (next = "") => {
  return {
    query: `
    {
      courseCategories(first: 1000, after: "${next}") {
        edges {
          node {
            id
            name
            slug
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `,
  };
};

export const queryCourseLocations = () => {
  return {
    query: `
    {
      courseLocations {
        title
      }
    }
    `,
  };
}

export const queryCourseProviders = (next = "") => {
  return {
    query: `
    {
      courseProviders(first: 1000, after: "${next}") {
        edges {
          node {
            id
            name
            slug
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `,
  };
};

export const queryCourseTypes = (next = "") => {
  return {
    query: `
    {
      course(first: 1000, after: "${next}") {
        edges {
          node {
            id
            courses {
              type
            }
          }
        }
      }
    }
  `,
  };
};
