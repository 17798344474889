import React from "react";
import Link from "../../Link";
import ReactHtmlParser from "react-html-parser";

function Logo(props) {
  if (!props.provider.courseProviderInfo.logo) return null;

  return (
    <img
      src={props.provider.courseProviderInfo.logo.sourceUrl}
      className="brand-logo"
      alt={``}
    />
  );
}

export default function Results({ courses, courseDates, classes }) {
  if (!courses.length) return null;
  const now = new Date();
  return (
    <div className={classes.courseResults}>
      <div className="course-titles">
        <div className="provider">Provider</div>
        <div className="title">Course Title</div>
        <div className="places">Location</div>
        <div className="category">Course Category</div>
        <div className="length">Length</div>
        <div className="dates">Date</div>
      </div>
      <div className="course-listing">
        {courses.map((course, index) => {
          let hasOnline = false;
          let places = '';
          if (course.courses.places) {
            hasOnline = course.courses.places.filter(place => place === 'eLearning').length > 0;
            if (course.courses.places.length > 4) {
              places = <span>Multiple Course Locations Available</span>
            } else {
              places = course.courses.places.map(place => <span key={course.id + place}>{place}</span>)
            }
          }

          const hasDates = courseDates
            .filter(
              date => date.courseData.course?.databaseId === course.databaseId
            )
            .filter(date => {
              const { startDate } = date.courseData;
              if (!startDate) return false
              const startDateEpoch = new Date(
                startDate.substring(0, 4) + '-' + startDate.substring(4, 6) + '-' + startDate.substring(6, 8)
              ).getTime();
              const nowEpoch = now.getTime();

              if (nowEpoch > startDateEpoch) return false;

              return date;
            })
            .length;

          return (
            <div key={course.id + index} className="course-item">
              <div className="provider">
                <span className="title">Provider </span>
                <Logo provider={course.courses.provider} />
              </div>
              <div className="title">
                <span className="title">Course Title</span>
                <Link to={course.uri}>{ReactHtmlParser(course.title)}</Link>
              </div>
              <div className="places">
                <span className="title">Location</span>
                { places }
              </div>
              <div className="category">
                <span className="title">Course Category</span>
                {course.courseCategories !== null && (
                  <span>{course.courseCategories?.nodes[0]?.name ?? ""}</span>
                )}
              </div>
              <div className="length">
                <span className="title">Length</span>
                {course.courses.length !== null && (
                  <span>{course.courses.length}</span>
                )}
              </div>
              <div className="dates">
                <span className="title">Date</span>
                {hasOnline && <span>Accessible 24/7</span>}
                {!hasOnline && (
                    <span>
                      {hasDates ? "Multiple dates available" : "Contact for Availability"}
                    </span>
                )}
              </div>
              <div className="link">
                <Link to={course.uri}>Course Detail</Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
