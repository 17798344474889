export default theme => ({
    introGallerySplit: {
        position: 'relative',
        marginBottom: 36,
        // margin: '80px 0 70px',
        [theme.breakpoints.up('md')]: {
            // margin: '180px 0 105px',
        },
        '& > div': {
            flexDirection: 'row-reverse',
        },
        '& h1, & h2, & h3, & h4, & h5, & h6': {
            lineHeight: 1.72,
            marginBottom: 30,
        },
        '& p' : {
            marginBottom: 75,
            lineHeight: 1.75,
            [theme.breakpoints.up('md')]: {
                marginBottom: 40,
            },
        },
        '& button': {
            marginBottom: 55,
            [theme.breakpoints.up('md')]: {
                marginBottom: 0,
            },
        },
        '& .image-wrap': {
            padding: '12px 0',
            maxWidth: 'calc(100% + 10px)',
            flexBasis: 'calc(100% + 10px)',
            marginLeft: -5,
            marginRight: -5,
            [theme.breakpoints.up('md')]: {
                padding: 12,
                maxWidth: '66.666666%',
                flexBasis: '66.666666%',
                marginLeft: 0,
                marginRight: 0
            },
            [theme.breakpoints.up('lg')]: {
                padding: 12,
                maxWidth: '75%',
                flexWidth: '75%',
            },
            '& ul.MuiGridList-root': {
                display: 'grid',
                gridGap: 20,
                gridTemplateColumns: 'calc(50% - 10px) calc(50% - 10px)',
                margin: '0 !important',
                gridTemplateRows: 'auto auto',
                overflow: 'hidden',
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    gridTemplateColumns: 'calc(21% - 13px) calc(36% - 13px) calc(43% - 13px)',
                    gridTemplateRows: 'auto auto auto auto',
                },
                '& li': {
                    width: '100% !important',
                    padding: '0 !important',
                    display: 'none',
                    '&:nth-of-type(1)': {
                        gridColumnStart: 1,
                        gridColumnEnd: 3,
                        display: 'block',
                        [theme.breakpoints.up('md')]: {
                            gridColumnStart: 1,
                            gridColumnEnd: 3,
                            gridRowStart: 2,
                            gridRowEnd: 3,
                        },
                        '& img, & .gatsby-image-wrapper': {
                            '& > div': {
                                paddingBottom: '60% !important',
                            }
                        },
                    },
                    '&:nth-of-type(2)': {
                        display: 'block',
                        '& img, & .gatsby-image-wrapper': {
                            '& > div': {
                                paddingBottom: '190% !important',
                            }
                        },
                        [theme.breakpoints.up('md')]: {
                            gridColumnStart: 3,
                            gridColumnEnd: 4,
                            gridRowStart: 1,
                            gridRowEnd: 4,
                        },
                    },
                    '&:nth-of-type(3)': {
                        display: 'block',
                        '& img, & .gatsby-image-wrapper': {
                            '& > div': {
                                paddingBottom: '166.666% !important',
                            }
                        },
                        [theme.breakpoints.up('md')]: {
                            gridColumnStart: 2,
                            gridColumnEnd: 3,
                            gridRowStart: 3,
                            gridRowEnd: 5,
                        },
                    },
                }
            }
        }
    },
    introGallerySplitTheme: {
        ...theme.introGallerySplitTheme
    },
})