import React from 'react';
import { graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import {buildBlocks} from "../../../services/builder";
import ReactHtmlParser from 'react-html-parser';
import styles from './styles';

const useStyles = makeStyles(theme => styles(theme));

export const CgbTestimonialBlock = (props) => {
    const blocks = buildBlocks(props.innerBlocks, props.parentAttributes, props.pageContext);
    const classes = useStyles();

    return <div className={`testimonial ${props.attributes.className ? props.attributes.className : ''} ${classes.testimonial} ${classes.testimonialTheme}`}>
            <p className="testimonial-quote">{ReactHtmlParser(props.attributes.testimonialQuote)}</p>
            {blocks.map((block) => {
                return block;
            })}
            <p className="testimonial-name">{ReactHtmlParser(props.attributes.testimonialName)}</p>
            <p className="testimonial-role">{ReactHtmlParser(props.attributes.testimonialRole)}</p>
    </div>
};

export const CgbTestimonialBlockFragment = graphql`
  fragment CgbTestimonialBlock on WpBlock {
    ... on WpCgbTestimonialBlock {
        name
        attributes {
            className
            testimonialQuote
            testimonialName
            testimonialRole
        }
      }
  }`;

// This is the string version of this that is used within previews and without WP prefixes
export const CgbTestimonialBlockPreview = `
    ... on CgbTestimonialBlock {
        name
        attributes {
            className
            testimonialQuote
            testimonialName
            testimonialRole
        }
    }
`;