import chevron from "../../../images/chevron.png";
import search from "../../../images/search-solid.png";
import searchAnimation from "../../../images/search-animation.gif";

export default (theme) => ({
  courseForm: {
    position: "relative",
    padding: "90px 0 ",
    marginBottom: 36,
    [theme.breakpoints.up("md")]: {
      marginBottom: 80,
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      width: "100vw",
      marginLeft: "50%",
      transform: "translateX(-50%)",
      display: "block",
      backgroundColor: theme.palette.primary.main,
      zIndex: -1,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      top: "calc(100% - 25px)",
      height: 74,
      transform: "rotate(180deg)",
      backgroundColor: theme.palette.primary.main,
      clipPath:
        "polygon(0% 50px, calc(50% - 82px) 50px, calc(50% - 40px) 0, calc(50% + 40px) 0, calc(50% + 82px) 50px, 100% 50px, 100% 100%, 0% 100%)",
    },
    "& form": {
      marginTop: 30,
      [theme.breakpoints.up("md")]: {
        display: "flex",
        margin: "0 -12px",
        width: "calc(100% + 24px)",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "flex-end",
        "& .name": {
          flex: "0 0 40%",
          padding: "0 12px",
        },
        "& .category, & .provider, & .places": {
          flex: "0 0 20%",
          padding: "0 12px",
        },
        "& .actions": {
          flex: "0 100%",
          padding: "0 12px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "24px",
          "& input, & button": {
            flex: "0 0 calc(50% - 12px)",
          },
        },
      },
      [theme.breakpoints.up("lg")]: {
        "& .name": {
          flex: "0 0 29.1%",
        },
        "& .places": {
          flex: "0 0 30.9%",
        },
        "& .category": {
          flex: "0 0 20%",
        },
        "& .provider": {
          flex: "0 0 20%",
        },
        "& .actions": {
          flex: "0 0 100%",
          // flexDirection: 'row-reverse',
          "& input": {
            flex: "0 0 calc(20% - 12px)",
          },
          "& button": {
            flex: "0 0 calc(20% - 12px)",
          },
        },
      },
      "& > div": {
        "& label": {
          color: theme.palette.primary.contrastText,
          fontSize: 12,
          lineHeight: 1.6,
          letterSpacing: "0.0152em",
          textTransform: "uppercase",
          margin: 0,
          fontFamily: theme.bodyFont,
        },
        "& input, & textarea, & select, & button": {
          fontFamily: theme.bodyFont + ", sans-serif",
          borderRadius: 20,
          fontSize: 12,
          lineHeight: 2,
          padding: "7px 20px",
          border: "1px solid " + theme.palette.primary.contrastText,
          backgroundColor: "white",
          marginBottom: 24,
          outline: "none",
          display: "block",
          width: "100%",
          boxShadow: "0 8px 16px rgba(0,0,0,0.16)",
          opcaity: "1 !important",
          "-moz-appearance": "none",
          "-webkit-appearance": "none",
          "&::placeholder": {
            color: "#949494",
            opacity: 1,
          },
        },
        "& select": {
          backgroundImage: `url(${chevron})`,
          backgroundPosition: "center right 18px",
          backgroundRepeat: "no-repeat",
        },
        '& input[type="submit"]': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          fontFamily: theme.headerFont,
          fontSize: 16,
          textAlign: "center",
          border: "none",
          padding: "4px 20px",
          backgroundImage: "url(" + search + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center left calc(50% - 26px)",
          textIndent: 12,
        },
        "& button": {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.contrastText,
          fontFamily: theme.headerFont,
          fontSize: 16,
          textAlign: "center",
          border: "none",
          padding: "4px 20px",
        },
      },
      "& .places": {
        "& .placesAutocomplete": {
          width: "100%",
          border: "1px solid #FFF",
          display: "block",
          position: "relative",
          outline: "none",
          padding: "0px 12px",
          fontSize: 12,
          boxShadow: "0 8px 16px rgba(0,0,0,0.16)",
          fontFamily: "Overpass, sans-serif",
          lineHeight: 2,
          borderRadius: 20,
          marginBottom: 24,
          backgroundColor: "white",
          "& .MuiInputBase-root::before, & .MuiInputBase-root::after": {
            display: "none !important",
          },
          "& input": {
            margin: "0 !important",
            padding: "0 !important",
            boxShadow: "none !important",
            height: 37,
            lineHeight: "38px",
            padding: "0 8px !important",
          },
          "& .MuiChip-label": {
            paddingTop: 4,
          },
          "& .MuiAutocomplete-endAdornment": {
            position: "absolute",
            top: 4,
            right: -4,
            "& .MuiTouchRipple-root": { display: "none !important" },
            "& button": {
              boxShadow: "none",
              position: "absolute",
              top: 0,
              right: 0,
              "&:hover, &:focus": { backgroundColor: "transparent !important" },
              "&.MuiAutocomplete-clearIndicator": {
                right: 40,
                top: 2,
              },
              "&:not(.MuiAutocomplete-clearIndicator)": {
                backgroundImage: `url(${chevron})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                transform: "none !important",
                "& svg": {
                  opacity: 0,
                },
              },
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {},
        },
      },
    },
  },
  courseResults: {
    position: "relative",
    paddingTop: 50,
    "& h2": {
      marginBottom: 50,
    },
    "& .course-titles": {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        fontFamily: "Roboto Condensed",
        letterSpacing: "0.152em",
        lineHeight: 1.2,
        fontSize: 12,
        textTransform: "uppercase",
        "& .provider": {
          flex: "0 0 10%",
          minWidth: "10%",
          padding: "0 12px 30px 0",
        },
        "& .title": {
          flex: "0 0 30%",
          minWidth: "30%",
          padding: "0 12px 30px",
        },
        "& .places": {
          flex: "0 0 20%",
          minWidth: "20%",
          padding: "0 12px 30px",
        },
        "& .category": {
          flex: "0 0 15%",
          minWidth: "15%",
          padding: "0 12px 30px",
        },
        "& .length": {
          flex: "0 0 10%",
          minWidth: "10%",
          padding: "0 12px 30px",
        },
        "& .dates": {
          flex: "0 0 15%",
          minWidth: "15%",
          padding: "0 12px 30px",
        },
      },
      [theme.breakpoints.up("lg")]: {
        "& .provider": {
          flex: "0 0 12.5%",
          minWidth: "12.5%",
        },
        "& .title": {
          flex: "0 0 31.25%",
          minWidth: "31.25%",
        },
        "& .places": {
          flex: "0 0 12.5%",
          minWidth: "12.5%",
        },
        "& .category": {
          flex: "0 0 12.5%",
          minWidth: "12.5%",
        },
        "& .length": {
          flex: "0 0 6.25%",
          minWidth: "6.25%",
        },
        "& .dates": {
          flex: "0 0 25%",
          minWidth: "25%",
        },
      },
    },
    "& .course-listing": {
      marginBottom: 32,
      [theme.breakpoints.up("md")]: {
        marginBottom: 80,
      },
      "& .course-item": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        borderTop: "1px solid rgba(0,0,0,0.3)",
        backgroundColor: "transparent",
        "&:nth-of-type(odd)": {
          backgroundColor: "white",
        },
        "& span.title": {
          fontFamily: "Roboto Condensed",
          letterSpacing: "0.152em",
          lineHeight: 1.2,
          fontSize: 12,
          opacity: 0.3,
          display: "block",
          marginBottom: 5,
          textTransform: "uppercase",
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        },
        "& .provider": {
          flex: "0 0 100%",
          minWidth: "100%",
          padding: 12,
          "& .gatsby-image-wrapper": {
            width: 80,
          },
        },
        "& div.title": {
          flex: "0 0 100%",
          minWidth: "100%",
          padding: 12,
          "& a": {
            fontFamily: "Overpass",
            letterSpacing: "0.016em",
            lineHeight: 1.5,
            fontSize: 16,
            fontWeight: "bold",
            color: theme.palette.primary.main,
          },
        },
        "& .places": {
          flex: "0 0 50%",
          minWidth: "50%",
          padding: 12,
          fontFamily: "Overpass",
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          fontSize: 16,
          opacity: 0.5,
          "& span": {
            display: "block",
          },
        },
        "& .category": {
          flex: "0 0 50%",
          minWidth: "50%",
          padding: 12,
          fontFamily: "Overpass",
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          fontSize: 16,
          opacity: 0.5,
        },
        "& .length": {
          flex: "0 0 50%",
          minWidth: "50%",
          padding: 12,
          fontFamily: "Overpass",
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          fontSize: 16,
          opacity: 0.5,
        },
        "& .dates": {
          flex: "0 0 50%",
          minWidth: "50%",
          padding: 12,
          fontFamily: "Overpass",
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          fontSize: 16,
          opacity: 0.5,
        },
        "& .link": {
          flex: "0 0 100%",
          minWidth: "100%",
          padding: 12,
          paddingBottom: 32,
          "& a": {
            display: "table",
            margin: "0 auto",
            backgroundColor: "white",
            color: theme.palette.primary.main,
            textTransform: "uppercase",
            fontFamily: theme.headerFont,
            fontSize: 16,
            lineHeight: 1.5,
            borderRadius: 15,
            boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
            textDecoration: "none",
            padding: "5px 20px",
            transition:
              "background-color .3s ease-in-out, color .3s ease-in-out",
            "&:hover, &:focus": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
          },
        },
        [theme.breakpoints.up("md")]: {
          "& .provider": {
            flex: "0 0 10%",
            minWidth: "10%",
            padding: "24px 12px 32px",
          },
          "& div.title": {
            flex: "0 0 30%",
            minWidth: "30%",
            padding: "24px 12px 32px",
          },
          "& .places": {
            flex: "0 0 20%",
            minWidth: "20%",
            padding: "24px 12px 32px",
          },
          "& .category": {
            flex: "0 0 15%",
            minWidth: "15%",
            padding: "24px 12px 32px",
          },
          "& .length": {
            flex: "0 0 10%",
            minWidth: "10%",
            padding: "24px 12px 32px",
          },
          "& .dates": {
            flex: "0 0 15%",
            minWidth: "15%",
            padding: "24px 12px 32px",
          },
        },
        [theme.breakpoints.up("lg")]: {
          "& .provider": {
            flex: "0 0 12.5%",
            minWidth: "12.5%",
          },
          "& div.title": {
            flex: "0 0 31.25%",
            minWidth: "31.25%",
          },
          "& .places": {
            flex: "0 0 12.5%",
            minWidth: "12.5%",
          },
          "& .category": {
            flex: "0 0 12.5%",
            minWidth: "12.5%",
          },
          "& .length": {
            flex: "0 0 6.25%",
            minWidth: "6.25%",
          },
          "& .dates": {
            flex: "0 0 12.5%",
            minWidth: "12.5%",
          },
          "& .link": {
            flex: "0 0 12.5%",
            minWidth: "12.5%",
            padding: "24px 12px 32px",
          },
        },
      },
    },
  },
  searching: {
    opacity: "1 !important",
    fontSize: 18,
    textAlign: "center",
    padding: "40px 0 40px",
    "&::before": {
      content: '""',
      backgroundImage: "url(" + searchAnimation + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "contain",
      width: 44,
      height: 44,
      backgroundSize: "cover",
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: 10,
    },
  },
  loadMore: {
    width: "100%",
    background:
      "linear-gradient(180deg, rgba(237,237,237,0) 0%, rgba(237,237,237,1) 85%, rgba(237,237,237,1) 100%)",
    position: "relative",
    zIndex: 2,
    height: 175,
    marginTop: -208,
    marginBottom: 32,
    padding: 32,
    paddingBottom: 0,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: -255,
      marginBottom: 80,
    },
    "& button": {
      border: "none",
      backgroundColor: "transparent",
      padding: 0,
      fontSize: 24,
      fontFamily: theme.headerFont,
      color: theme.palette.secondary.main,
      letterSpacing: "0.016em",
      textAlign: "center",
      "&::after": {
        content: '""',
        borderRight: "3px solid " + theme.palette.secondary.main,
        borderBottom: "3px solid " + theme.palette.secondary.main,
        display: "block",
        width: 12,
        height: 12,
        margin: "0 auto",
        transformOrigin: "50%",
        transform: "rotate(45deg)",
      },
    },
  },
  cta: {
    display: "block",
    borderRadius: 25,
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
    padding: "10px 40px",
    textAlign: "center",
    letterSpacing: "0.016em",
    fontSize: 18,
    fontFamily: theme.headerFont,
    margin: "24px 24px 10px",
    textDecoration: "none",
    transition: "background-color .3s ease-in-out",
    [theme.breakpoints.up("md")]: {
      margin: "0 0 10px 0",
      width: 195,
    },
  },
});
