import { navigate } from 'gatsby';
import React from 'react';
import { isBrowser } from '../../helpers';

export default class GoBack extends React.Component {

    back(){
        if (isBrowser) {
            if (!window.history.state) {
                navigate(this.props.fallback);
            } else {
                window.history.back();
            }
        }
    }

    render() {
        return (
            <button className="go-back" onClick={() => this.back()}>Back</button>
        );
    }
}